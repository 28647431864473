import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";

import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";
import LabelValueContainer from "../LabelValue";

const dateColumn = {
  title: "Date",
  dataIndex: "created_at",
  key: "created_at",
  align: "center",
  sorter: true,
  render: (text) => DateFormat(text),
};

export default function PageContainer({
  columns = [],
  type = "Category",
  session_Type = "",
  getApiName = "getCategory",
  sidebarData = [],
  SidebarComponent = Sidebar,
  PopupComponent = Popup,
  saveHandler = () => {},
  reeerender = false,
  tableProps,
  sidebarProps,
  popupProps,
}) {
  const [loading, setLoading] = useState(true);
  const [sessionType, setSessionType] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(6);
  const [selected, setSelected] = useState({});
  const [sidebar, setSidebar] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");

  useEffect(() => getList(), [reeerender]);

  const getList = async (payload = {}) => {
    setLoading(true);
    if (session_Type) payload = { ...payload, session_type: session_Type };
    else payload = { ...payload };
    const res = await getApi(
      payload,
      getApiName.split("/")[1],
      getApiName.split("/")[0] + "/"
    );
    if (res.data) {
      setList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  return (
    <>
      {popup && (
        <PopupComponent
          visible
          title={popupTitle}
          cancelHandler={() => setPopup(false)}
          saveHandler={(values) => {
            setPopup(false);
            setSidebar(false);
            setLoading(true);
            saveHandler(values);
          }}
          {...popupProps}
        ></PopupComponent>
      )}
      {sidebar && !popup && (
        <SidebarComponent
          visible
          title={type + " details"}
          onClose={() => setSidebar(false)}
          footer={
            false && (
              <div style={{ textAlign: "right", paddingRight: 20 }}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setPopupTitle(`Edit ${type} Details`);
                    setPopup(true);
                  }}
                  type="primary"
                >
                  Edit {type} Details
                </Button>
              </div>
            )
          }
          {...sidebarProps}
        >
          <LabelValueContainer
            data={[
              ...sidebarData?.map((data, i) => ({
                ...data,

                value: data.value2
                  ? `${selected[data.value] || "NA"} ${
                      selected[data.value2] || ""
                    }`
                  : selected[data.value],
              })),

              sidebarData.some((sd) => sd.value === "date")
                ? {}
                : { label: "Date", value: DateFormat(selected?.created_at) },

              sessionType === "In-person" && {
                label: "Address",
                value:
                  selected?.name +
                  ", " +
                  selected?.address1 +
                  ", " +
                  selected?.address2 +
                  "(Nearby " +
                  selected?.landmark +
                  "), " +
                  selected?.city +
                  ", " +
                  selected?.state +
                  ", PIN-" +
                  selected?.pincode +
                  ".",
              },
              sessionType === "In-person" && {
                label: "Session Price",
                value: selected?.offline_session?.price,
              },
              sessionType === "In-person" && {
                label: "Session Starting Date",
                value: DateFormat(selected?.offline_session?.start_date),
              },

              sessionType === "Live" && {
                label: "Live Slot Availability",
                value: selected?.work_schedule.map((ele, i) => (
                  <li>
                    {ele.is_active &&
                      `${ele?.day ? ele?.day : "-"}  :  ${
                        ele?.slots[0]?.start_time
                          ? ele?.slots[0]?.start_time
                          : "-"
                      } to ${
                        ele?.slots[0]?.end_time ? ele?.slots[0]?.end_time : "-"
                      }`}
                  </li>
                )),
              },
            ]}
          />
        </SidebarComponent>
      )}
      <CardContainer bordered={false} hoverable={false}>
        <BreadCrumb title={type + " Management"} />
        <TableContainer
          loading={loading}
          total={total}
          columns={[...columns, dateColumn]}
          data={list}
          searchFor={type + " Name"}
          addHandler={() => {
            setSelected({});
            setPopupTitle("Add " + type);
            setPopup(true);
          }}
          rowClickHandler={(record) => {
            setSelected(record);
            setSidebar(true);
            setSessionType(session_Type);
          }}
          functionsHandler={getList}
          {...tableProps}
        />
      </CardContainer>
    </>
  );
}
