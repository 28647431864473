import React, { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import dayjs from "dayjs";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import TabContainer from "../../components/Tab";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi, saveApi } from "../../helpers/ApiFunctions";

export default function AccountDeletion({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [totalDeleted, setTotalDeleted] = useState(8);
  const [accountDeletionList, setAccountDeletionList] = useState([]);
  const [deletedList, setDeletedList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",
      render: (text, object) => (
        <div style={{}}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px",
            alignItems: "center",
          }}
        >
          {text === "requested" ? (
            <div
              className="profile-step-container"
              style={{ background: "#FFE2E2" }}
            >
              <div className="profile-step-pill" style={{ color: "#FF0000" }}>
                {text}
              </div>
            </div>
          ) : (
            <div
              className="profile-step-container"
              style={{ background: "#DAFFDA" }}
            >
              <div className="profile-step-pill" style={{ color: "#08A908" }}>
                {text}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      align: "center",

      render: (t) =>
        t ? dayjs(t, "D M YYYY h:mm a").format("DD MMM, YYYY h:mm A") : "-",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "is_delete",
      key: "is_delete",
      render: (t, object) =>
        t === 0 ? (
          <Button
            onClick={() => setPopup(true)}
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "#FF0000", color: "#FFFFFF" }}
          >
            Delete
          </Button>
        ) : (
          "-"
        ),
    },
  ];

  const deletedColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",

      render: (text, object) => (
        <Text>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </Text>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      align: "center",

      render: (t) =>
        t ? <div>{dayjs(t).format("DD MMM, YYYY h:mm A")}</div> : "-",
    },
  ];

  useEffect(() => getDeleteRequestAccounts(), []);

  const getDeleteRequestAccounts = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getAccountDeleteList",
      "dashboard/"
    );
    if (res.data) {
      setAccountDeletionList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const getDeletedAccounts = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, deleted: "yes" },
      "getAccountDeleteList",
      "dashboard/"
    );
    if (res.data) {
      setDeletedList(res.data);
      setTotalDeleted(res.meta?.total);
    }
    setLoading(false);
  };

  const deletionHandler = async (user_id) => {
    setLoading(true);
    let msg = "";
    const res = await saveApi(
      { user_id: user_id },
      msg,
      "deleteUserAccountDash",
      "dashboard/"
    );

    if (res) {
      getDeleteRequestAccounts();
    }
    setSelected({});
    setLoading(false);
    setPopup(false);
  };

  const requests = (
    <>
      <CardContainer hoverable={false}>
        <BreadCrumb title="Requests" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
          }}
          data={accountDeletionList}
          total={total}
          searchFor="Name, Mobile"
          functionsHandler={getDeleteRequestAccounts}
        />
      </CardContainer>
    </>
  );

  const deleted = (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Deleted" />

      <TableContainer
        loading={loading}
        columns={write === 0 ? deletedColumns : [...deletedColumns]}
        rowClickHandler={(row) => {
          setSelected(row);
        }}
        data={deletedList}
        total={totalDeleted}
        searchFor="Name, Mobile"
        functionsHandler={getDeletedAccounts}
      />
    </CardContainer>
  );

  return (
    <>
      {popup && (
        <Popup
          saveText="Delete"
          title="User deletion confirmation"
          saveHandler={() => deletionHandler(selected.user_id)}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure to delete the profile <b>{selected.first_name}</b> ?
          </Text>
        </Popup>
      )}

      <TabContainer getDeletedAccounts={getDeletedAccounts}>
        <Tabs.TabPane tab="Requests" key="">
          {requests}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Deleted" key="deleted">
          {deleted}
        </Tabs.TabPane>
      </TabContainer>
    </>
  );
}
