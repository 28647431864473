import { Rate, Tag } from "antd";
import React from "react";
import { statusBadgeHandler } from "../../helpers/CommonFunctions";

export default function Badge({
  productStatus,
  orderStatus,
  paymentStatus,
  rating,
  quantity,
  active,
  sessionType = "",
}) {
  let color, label;

  if (rating) {
    label = rating;
  } else if (productStatus) {
    const statusObj = statusBadgeHandler(productStatus, "product");
    label = statusObj.label;
    color = statusObj.color;
  } else if (orderStatus) {
    const statusObj = statusBadgeHandler(orderStatus, "order");
    label = statusObj.label;
    color = statusObj.color;
  } else if (paymentStatus) {
    const statusObj = statusBadgeHandler(paymentStatus, "payment");
    label = statusObj.label;
    color = statusObj.color;
  } else if (sessionType) {
    label = sessionType.toUpperCase();
    color = "#4A2173";
  } else if (quantity) {
    label = quantity;
  } else if (active === 1) {
    color = "green";
    label = "Active";
  } else if (active === 0) {
    color = "red";
    label = "Inactive";
  } else {
    label = "NA";
  }

  return rating ? (
    <Rate
      allowHalf
      disabled
      value={parseFloat(label)}
      className="badge-rating"
      style={{
        color: rating > 3 ? "#C533DA" : rating > 2 ? "#E9E908" : "#FF4545",
      }}
    />
  ) : (
    <Tag style={{ textTransform: "capitalize" }} color={color}>
      {label}
    </Tag>
  );
}
