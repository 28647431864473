import { FullscreenExitOutlined } from "@ant-design/icons";
import DeckGL, { GeoJsonLayer } from "deck.gl";
import { useState } from "react";
import { Map } from "react-map-gl";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiY2hhaXRhbnlhcGFyYW5qcGUiLCJhIjoiY2xzMzE1Ymp3MHB4bTJqcGlzYTVvamkzNCJ9.vGKMfUusclwi-q9iYOWtzw";
const MAP_STYLE = "mapbox://styles/mapbox/dark-v9";

const INITIAL_VIEW_STATE = {
  latitude: 20.5937,
  longitude: 78.9629,
  zoom: 4.5,
  bearing: 0,
  pitch: 0,
};
function Globe({ data, setDetailGlobe }) {
  const [hoveredCity, setHoveredCity] = useState({});

  let regionData = data
    ?.map(({ cities }, id) => {
      return cities?.map(
        ({
          avg_age,
          city_name,
          count_female_users,
          count_male_users,
          lat,
          lng,
        }) => {
          return {
            type: "Feature",
            id: id,
            properties: {
              city_name,
              avg_age,
              count_female_users,
              count_male_users,
            },
            geometry: {
              type: "Point",
              coordinates: [lng, lat],
            },
          };
        }
      );
    })
    .flat();

  const PopUp = ({ cityInfo, positionInfo }) => {
    return (
      <div
        className="popup-cont"
        style={{
          position: "absolute",
          top: positionInfo.y,
          left: positionInfo.x,
          padding: 10,
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          borderRadius: 6,
        }}
      >
        <p style={{ fontStyle: "italic", marginBottom: 10, fontSize: 16 }}>
          <b>{cityInfo?.city_name}</b>
        </p>
        <p style={{ color: "#BCBCBC" }}>
          Average Age: {cityInfo?.avg_age?.toFixed(2)} years
        </p>
        <p style={{ color: "#BCBCBC" }}>
          Male Users: {cityInfo?.count_male_users}
        </p>
        <p style={{ color: "#BCBCBC" }}>
          Female Users: {cityInfo?.count_female_users}
        </p>
      </div>
    );
  };

  const layers = [
    new GeoJsonLayer({
      id: "Users",
      data: { type: "FeatureCollection", features: regionData },
      //styles
      projection: "globe",
      filled: true,
      pointRadiusMinPixels: 10,
      pointRadiusSacle: 2000,
      getPointRadius: (f) => 5,
      getFillColor: [255, 223, 0],
      pickable: true,
      autoHighlight: true,
      onHover: (info) => {
        if (info.object) {
          setHoveredCity({
            cityInfo: info.object.properties,
            positionInfo: {
              x: info.x,
              y: info.y,
            },
          });
        } else {
          setHoveredCity(null);
        }
      },
    }),
  ];

  return (
    <>
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
      >
        <Map mapStyle={MAP_STYLE} mapboxAccessToken={MAPBOX_ACCESS_TOKEN}></Map>
      </DeckGL>
      {hoveredCity?.cityInfo && (
        <PopUp
          cityInfo={hoveredCity.cityInfo}
          positionInfo={hoveredCity.positionInfo}
        />
      )}

      <FullscreenExitOutlined
        className="exit"
        onClick={() => setDetailGlobe(false)}
        style={{
          padding: 10,
          borderRadius: 10,
          backgroundColor: "white",
          position: "absolute",
          zIndex: 1000,
          top: "90%",
          right: 30,
          fontSize: 30,
          color: "black",
        }}
      />
    </>
  );
}

export default Globe;
