import { Layout } from "antd";
import React, { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Outlet } from "react-router-dom";

import Footers from "../Footer";
import Sidenav from "../Sidenav";
import "./index.css";

export default function MainContent() {
  const [collapsed, setCollapsed] = useState(false);
  const { status } = useThemeSwitcher();

  if (status === "loading") return null;

  return (
    <Layout>
      <Layout className="lyt">
        <Sidenav onSideNavCollapse={(value) => setCollapsed(value)} />
        <Layout.Content
          style={{ marginLeft: collapsed ? 55 : 270 }}
          className="cnt"
        >
          <Outlet />
        </Layout.Content>
        <Footers />
      </Layout>
    </Layout>
  );
}
