import { EyeOutlined } from "@ant-design/icons";
import { Image, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import pencilIcon from "../../assets/PencilSimple.svg";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi, saveApi } from "../../helpers/ApiFunctions";

import "./index.css";

export default function Verification({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [certificateList, setCertificateList] = useState([]);
  const [selected, setSelected] = useState({});
  const [url, setUrl] = useState("");
  const [popup, setPopup] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certApproval, setCertApproval] = useState([]);
  const [certId, setCertId] = useState();
  const [rejection, setRejection] = useState(false);
  const [rejectionPopup, setRejectionPopup] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",

      render: (text, object) => (
        <Text>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </Text>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Documents",
      dataIndex: "",
      key: "certificates",
      render: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px",
            alignItems: "center",
          }}
          onClick={() => setPopup(true)}
        >
          <EyeOutlined style={{ color: "#FF4A01", fontSize: "21px" }} />
          <Text
            style={{ color: "#FF4A01", marginLeft: "11px", lineHieght: "21px" }}
          >
            View documents
          </Text>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "certificates",
      key: "certificates",
      render: (text) => (
        <>
          {text.every((ele) => ele.status === "verified") ? (
            <div className="status-container" style={{ background: "#DAFFDA" }}>
              <div className="status-holder" style={{ color: "#08A908" }}>
                Approved
              </div>
            </div>
          ) : text.every((ele) => ele.status === "rejected") ? (
            <div className="status-container" style={{ background: "#FFE2E2" }}>
              <div className="status-holder" style={{ color: "#FF0000" }}>
                Rejected
              </div>
            </div>
          ) : (
            <div className="status-container" style={{ background: "#FFE2E2" }}>
              <div className="status-holder" style={{ color: "#FF0000" }}>
                Requested
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      align: "center",
      key: "created_at",
      sorter: true,
      render: (t) => (t ? dayjs(t).format("DD MMM, YYYY h:mm A") : "-"),
    },
  ];

  const certificateColumns = [
    {
      title: "Category",
      dataIndex: "certificates",
      key: "certificates",
      align: "center",
      render: (text, object) =>
        object ? (
          <div className="category-container" style={{ alignItems: "center" }}>
            <div className="category-data">{object.category}</div>
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Documents",
      dataIndex: "certificates",
      key: "certificates",
      align: "center",
      render: (text, object) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          onClick={() => setUrl(object.url)}
        >
          <EyeOutlined
            style={{
              color: "#FF4A01",
              fontSize: "21px",
              alignContent: "center",
            }}
          />
          <Text
            style={{ color: "#FF4A01", marginLeft: "11px", lineHieght: "21px" }}
          >
            View
          </Text>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "certificates",
      key: "certificates",
      align: "center",
      render: (text, object) => (
        <>
          <Select
            key={"text"}
            required
            onChange={(value) =>
              setCertApproval((prev) => {
                if (prev?.length) {
                  if (
                    prev.findIndex((e) => e?.cert_id === object.certId) !== -1
                  ) {
                    return prev
                      .map((e) => {
                        if (e?.cert_id === object.certId) {
                          return {
                            ...e,
                            status: value,
                          };
                        } else {
                          return e;
                        }
                      })
                      .filter((f) => f);
                  } else {
                    return [...prev, { cert_id: object.certId, status: value }];
                  }
                } else {
                  return [{ cert_id: object.certId, status: value }];
                }
              }, value === "rejected" && setRejection(true))
            }
            defaultValue={[
              {
                value: object.value,
                label: object.status,
              },
            ]}
            options={[
              { value: "verified", label: "verified" },
              { value: "rejected", label: "rejected" },
              { value: "processing", label: "processing" },
            ]}
          />
        </>
      ),
    },
    {
      title: "Reason for rejection",
      dataIndex: "certificates",
      key: "certificates",
      align: "center",
      render: (text, object) => (
        <>
          {rejection && certId === object.certId && (
            <div onClick={() => setRejectionPopup(true)}>
              <img src={pencilIcon} alt="pencil-icon" />
              <Text
                style={{
                  color: "#FF4A01",
                  marginLeft: "8px",
                  lineHieght: "21px",
                }}
              >
                Write reason
              </Text>
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => getCertificatesList(), []);

  const getCertificatesList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getAllCertificates",
      "dashboard/"
    );
    if (res.data) {
      setCertificateList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const aproveCertificates = async (certData) => {
    setCertificates({ ...certificates, loading: true });
    certData.map((ele) =>
      ele.status === "verified" ? (ele.is_verified = 1) : (ele.is_verified = 0)
    );
    let msg = "Verification status updated";
    const res = await saveApi(
      {
        user_id: selected.id,
        certData: certData,
      },
      msg,
      "verifyUserDocs",
      "dashboard/"
    );
    if (res) {
      getCertificatesList(selected);
    }
    setCertificates([]);
    setCertApproval([]);
    setPopup(false);
  };

  return (
    <>
      {popup && (
        <Popup
          title="View documents"
          style={{ marginTop: "0px !important" }}
          cancelHandler={() => {
            setPopup(false);
            setCertApproval([]);
          }}
          saveHandler={() => {
            certApproval.length
              ? aproveCertificates(certApproval)
              : message.error("please select an option");
          }}
          saveText="Submit"
          cancelText="Cancel"
          loading={selected.loading}
          visible
        >
          <TableContainer
            loading={loading}
            columns={write === 0 ? certificateColumns : [...certificateColumns]}
            data={selected.certificates}
            searchFor=""
            rowClickHandler={(row) => {
              setCertId(row.certId);
            }}
            dateRangePicker={false}
            exportButton={false}
            functionsHandler={{}}
          />
        </Popup>
      )}

      {url && (
        <Popup
          title="Document preview"
          cancelHandler={() => {
            setUrl("");
          }}
          saveHandler={() => {
            setUrl("");
          }}
          saveText="Okay"
          cancelText="Close"
          footer={false}
          loading={url.loading}
          visible
        >
          <>
            {url.slice(-3) === "pdf" ? (
              <iframe
                title="pdf"
                style={{
                  width: "100%",
                  height: "80vh",
                  marginTop: "10px",
                  borderRadius: "2px",
                }}
                src={url}
                frameBorder="0"
              ></iframe>
            ) : (
              <Image src={url} preview={false} />
            )}
          </>
        </Popup>
      )}

      {rejectionPopup && (
        <Popup
          title="Reason for rejected"
          cancelHandler={() => {
            setRejection("");
            setRejectionPopup(false);
          }}
          saveText="Done"
          cancelText="Cancel"
          loading={rejection.loading}
          width="471px"
          visible
        >
          <div>
            <Text style={{ color: " #6F7379" }}>
              Users will get notified for the same
            </Text>
            <TextArea
              rows={4}
              style={{
                width: "100%",
                border: " 1px solid #EEEEEE",
                borderRadius: "8px",
                marginTop: "22px",
              }}
              placeholder={"Type here.."}
            />
          </div>
        </Popup>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="Verification" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
            setCertificates(row.certificates);
          }}
          data={certificateList}
          total={total}
          searchFor="Name, Mobile No."
          functionsHandler={getCertificatesList}
        />
      </CardContainer>
    </>
  );
}
