import React, { useState } from "react";
import AMAZON from "../../assets/Amazon.svg";
import FLIPKART from "../../assets/Flipkart.svg";
import TATACLIQ from "../../assets/Tata.svg";
import Badge from "../../components/Badge";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";

import "./index.css";

export default function MarketPlaceReviews({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [chipId, setChipId] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [expandedKey, setExpandedKey] = useState("");
  const [reviewDetail, setReviewDetail] = useState("");

  const handleExpand = async (expanded, record, e) => {
    expanded && setExpandedKey([record.author]);
    setReviewDetail(record?.body);
  };

  const marketPlaces = [
    {
      id: 1,
      name: "Amazon",
      logo: AMAZON,
      isActive: 1,
    },
    { id: 2, name: "Flipkart", logo: FLIPKART, isActive: 0 },
    { id: 3, name: "Tata Cliq", logo: TATACLIQ, isActive: 0 },
  ];

  const columns = [
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      align: "center",
      render: (t) => t || "-",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      align: "center",
      render: (t) => <Badge rating={Number(t.slice(0, 1))} />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (t) => (t ? t.slice(18) : "-"),
    },
    {
      title: "Review",
      dataIndex: "body",
      key: "body",

      render: (t) =>
        t ? (
          <>
            <span>{t.slice(0, 30) + "... "} </span>
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Verified Purchase",
      dataIndex: "is_verified_purchase",
      key: "is_verified_purchase",
      align: "center",
      render: (t) => <span>{t ? "Yes" : "No"}</span>,
    },
  ];

  const getMpReviews = async (
    payload = { api_name: "scrapeReviews" },
    page
  ) => {
    console.log("page", page);
    setLoading(true);
    const res = await getApi({ ...payload }, "scrapeReviews", "tools/");
    if (res) {
      console.log(res);

      // setTotal(res?.pages);
      setReviews(res?.reviews);
    }
    setLoading(false);
  };

  return (
    <>
      <CardContainer hoverable={false}>
        <BreadCrumb title="Market Place Reviews" />
        <TableContainer
          rowKey="body"
          chipId={chipId}
          setChipId={setChipId}
          marketPlaces={marketPlaces}
          title="marketPlaceReviews"
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          data={reviews}
          total={total}
          functionsHandler={getMpReviews}
          expandable={{
            expandedKey,
            onExpand: handleExpand,
            expandedRowRender: (e, object) => {
              console.log("object", e);
              let splitStrings = e?.style?.split(/(?=Colour:|Style Name:)/);
              splitStrings = splitStrings?.map((str) => str.trim());

              return (
                <div style={{ padding: "2%" }}>
                  <h1 style={{ fontStyle: "italic" }}>Full review :</h1>

                  {e?.style ? (
                    <h3>
                      {splitStrings[0]} <br /> {splitStrings[1]}
                    </h3>
                  ) : null}
                  <p>{e?.body}</p>
                </div>
              );
            },
          }}
        />
      </CardContainer>
    </>
  );
}
