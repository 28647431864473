import { ConfigProvider } from "antd";
import React from "react";

import { StoreContextProvider } from "./helpers/StoreContext";
import AuthRoutes from "./routes";

function App() {
  return (
    <ConfigProvider
      getPopupContainer={(node) => {
        if (node) return node.parentNode;
        return document.body;
      }}
    >
      <StoreContextProvider>
        <AuthRoutes />
      </StoreContextProvider>
    </ConfigProvider>
  );
}

export default App;
