import React from "react";
import { Button } from "antd";

import "./index.css";

export default function NetworkError() {
  return (
    <div className="wrapr">
      <div className="err">
        <div className="msgc">
          <h3 style={{ fontWeight: "bold" }}>Poor or No Internet Connection</h3>
          <h5 style={{ letterSpacing: 0.5 }}>
            Please check your internet connection and try again.
          </h5>
        </div>
        <Button
          size="large"
          className="lbtn"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    </div>
  );
}
