import { Modal } from "antd";
import React from "react";

import Text from "../Text";
import "./index.css";

const getPopupWidth = () => {
  if (window.innerWidth < 460) return true;
  return false;
};

export default function Popup({
  title = "",
  visible = true,
  width = "45%",
  footer,
  saveText = "",
  cancelText = "",
  loading = false,
  children,
  cancelHandler = () => {},
  saveHandler = () => {},
  formData,
  name = "",
  description = "",
  setEdit,
  isDisabled,
}) {
  const length =
    formData?.category?.length > 0 &&
    formData?.sub_category?.length > 0 &&
    formData?.product_name?.length > 0 &&
    formData?.SKU_code?.length > 0;
  return (
    <Modal
      footer={footer === false ? null : footer}
      centered
      confirmLoading={loading}
      bodyStyle={{ maxHeight: "68vh", overflowY: "auto" }}
      maskClosable={false}
      title={<Text type="title">{title}</Text>}
      width={getPopupWidth() ? "85%" : width}
      visible={visible}
      okText={saveText}
      okButtonProps={{
        size: "large",
        disabled: (formData && !length) || isDisabled,
      }}
      cancelText={cancelText}
      cancelButtonProps={{ size: "large" }}
      onOk={() => saveHandler()}
      onCancel={() => {
        cancelHandler();
        setEdit({});
      }}
    >
      {children}
    </Modal>
  );
}
