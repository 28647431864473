import React, { useEffect, useState } from "react";

import { DatePicker, TimePicker } from "antd";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi } from "../../helpers/ApiFunctions";
import "./Challenge.css";

import { EditOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { ImageUpload } from "../../components/ImageUpload";
import Sidebar from "../../components/Sidebar";
import LabelValueContainer from "../../containers/LabelValue";

import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Select,
  Tag,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import BreadCrumb from "../../components/Breadcrumb";
import configData from "../../config.json";
import { DateFormat } from "../../helpers/CommonFunctions";
const { CheckableTag } = Tag;

export default function Challenge({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [challengeList, setChallengeList] = useState([]);
  const [challengeCategory, setChallengeCategory] = useState([]);
  const [challengeDetail, setChallengeDetail] = useState([]);
  const [selected, setSelected] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeImage, setActiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");

  const [showFields, setShowFields] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [streakData, setStreakData] = useState([
    { days: "3", coins: "10" },
    { days: "5", coins: "20" },
    { days: "7", coins: "30" },
  ]);
  const [trackerListing, setTrackerListing] = useState([]);
  const [streakRewards, setStreakRewards] = useState([]);
  const [duration, setDuration] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [timeStampDetail, setTimeStampDetail] = useState(null);
  const [totalCoins, setTotalCoins] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);

  console.log("streakData", streakData);

  const [influencerList, setInfluencerList] = useState([]);
  const tagsData = [
    { label: "S", value: 1 },
    { label: "M", value: 2 },
    { label: "T", value: 3 },
    { label: "W", value: 4 },
    { label: "Th", value: 5 },
    { label: "F", value: 6 },
    { label: "Sa", value: 7 },
  ];

  // console.log("formData", formData);

  console.log("totalCoins--------", totalCoins);

  const maxDate = moment().add(31, "days");

  const defaultValues =
    selected && selected?.start_date && selected?.end_date
      ? [moment(selected?.start_date), moment(selected?.end_date)]
      : undefined;

  const { RangePicker } = DatePicker;

  const columnsInventory = [
    {
      title: "Challenge Image",
      dataIndex: "challenge_profile",
      key: "challenge_profile",
      width: 150,
      render: (text) =>
        text ? (
          <Image
            onClick={(e) => e.stopPropagation()}
            src={text}
            style={{
              display: "block",
              objectFit: "cover",
              width: 100,
              height: 100,

              marginLeft: "auto",
              borderRadius: "0.5rem",
            }}
            placeholder={
              <Avatar
                icon={<UserOutlined />}
                shape="square"
                style={{ height: "24px", width: "24px" }}
              />
            }
            preview={text ? true : false}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Host",
      dataIndex: "created_by",
      key: "created_by",
      width: 200,

      render: (t) =>
        t ? <div style={{ textAlign: "center" }}>{t.name}</div> : "-",
    },
    {
      title: "Challenge Name",
      dataIndex: "challenge_name",
      key: "challenge_name",
      width: 200,

      render: (t) => (t ? <div style={{ textAlign: "center" }}>{t}</div> : "-"),
    },

    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{DateFormat(t)}</div> : "-",
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{DateFormat(t)}</div> : "-",
    },
  ];

  useEffect(() => {
    getChallengeList();
    trackerList();
    getActivityList();
    getUsersList();
  }, []);

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 10, admin_type_id: 2 },
      "getUsersList",
      "user/"
    );
    if (res.data) {
      setInfluencerList(res.data);
    }
    setLoading(false);
  };

  const trackerList = async (payload = {}) => {
    setLoading(true);
    setActiveIcon("");
    setSelected({});
    setisEdit(false);
    setIsAdd(false);
    setActiveImage(null);
    setFormData({});

    const res = await getApi(
      { ...payload, configs: 1, user_id: 1 },
      "createChallenge",
      "squad/"
    );
    if (res.success) {
      setTrackerListing(res.data);
      // setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const reset = () => {
    return (
      setStreakData([]),
      setShowFields(false),
      setSelected({}),
      setisEdit(false),
      setIsAdd(false),
      setActiveImage(null),
      setActiveIcon(""),
      setFormData({}),
      setDuration(null),
      setTimeStamp(null),
      setTimeStampDetail(null),
      setSelectedTags([])
    );
  };

  const createChallenge = async () => {
    setLoading(true);
    reset();
    totalCoinsEarnable();

    const payload =
      formData?.config_id === 1
        ? {
            ...formData,
            max_earn_value: totalCoinsEarnable(),
            coins_on_streak: streakData,
            time_stamp: timeStamp,
            start_time_stamp: timeStampDetail.start_time_stamp,
            end_time_stamp: timeStampDetail?.end_time_stamp,
            workout_days: selectedTags,
            is_private: 0,
          }
        : formData?.config_id === 2
        ? {
            ...formData,
            max_earn_value: totalCoinsEarnable(),
            coins_on_streak: streakData,
            duration,
            workout_days: selectedTags,
            is_private: 0,
          }
        : {
            ...formData,
            max_earn_value: totalCoinsEarnable(),
            coins_on_streak: streakData,
            workout_days: selectedTags,
            is_private: 0,
          };

    const res = await getApi(
      { ...payload, user_id: 1 },
      "createChallenge",
      "squad/"
    );
    if (res.success) {
      reset();
      getChallengeList();

      // setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const editChallenge = async (payload = {}) => {
    setLoading(true);
    reset();
    totalCoinsEarnable();

    const fixedPayload = {
      challenge_profile: formData?.challenge_profile,
      challenge_name: formData?.challenge_name,
      coins_on_completion: formData?.coins_on_completion,
      config_id: formData?.config_id,
      description: formData?.description,

      start_date: formData?.start_date,
      end_date: formData?.end_date,
      category: formData?.category,
      challenge_id: selected?.id,
      workout_days: selectedTags,
      is_private: 0,
    };

    // console.log("fixedPayload", fixedPayload);

    const payloadReq =
      formData?.config_id === 1
        ? {
            ...fixedPayload,
            max_earn_value: totalCoinsEarnable(),

            time_stamp: timeStamp,
            start_time_stamp: timeStampDetail?.start_time_stamp,
            end_time_stamp: timeStampDetail?.end_time_stamp,
          }
        : formData?.config_id === 2
        ? {
            ...fixedPayload,
            max_earn_value: totalCoinsEarnable(),

            duration: duration,
          }
        : {
            ...fixedPayload,
            max_earn_value: totalCoinsEarnable(),
          };

    const res = await getApi(
      { ...payloadReq, user_id: 1 },
      "editChallenge",
      "squad/"
    );
    if (res.success) {
      reset();
      setTotalCoins(0);
      getChallengeList();
      // setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  //This function is just for listing the inventory list
  const getChallengeList = async (payload = {}) => {
    setLoading(true);
    setActiveIcon("");
    setSelected({});
    setisEdit(false);
    setIsAdd(false);
    setActiveImage(null);
    setFormData({});

    const res = await getApi(
      { ...payload, global: 1 },
      "getChallengeList",
      "squad/"
    );
    if (res.success) {
      setChallengeList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const getActivityList = async (payload = {}) => {
    setLoading(true);
    setActiveIcon("");
    setSelected({});
    setisEdit(false);
    setIsAdd(false);
    setActiveImage(null);
    setFormData({});

    const res = await getApi(
      { ...payload, challenge_cat: 1 },
      "getActivityList",
      "circle/"
    );
    if (res.success) {
      setChallengeCategory(res.data);
      // setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  //This function is for editing and adding products

  useEffect(() => {
    uploadChallengeImage();
  }, [activeImage]);

  const uploadChallengeImage = async () => {
    if (activeImage !== null) {
      var formdata = new FormData();

      formdata.append("category", "challenge");
      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadChallengeProfileImage");
      formdata.append("user_id", "1");
      isEdit && formdata.append("challenge_id", selected?.id);

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadChallengeProfileImage`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          setFormData(() => ({
            ...formData,
            challenge_id: data?.data?.createdChallengeId,
            challenge_profile: data?.data?.url,
          }));
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getChallengeList();
    }
  };

  const Products = (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Challenges" />
      <TableContainer
        rowClickHandler={(row) => {
          setSelected(row);
        }}
        loading={loading}
        columns={write === 0 ? columnsInventory : [...columnsInventory]}
        data={challengeList}
        total={total}
        searchFor="Challenges"
        filter="challenge"
        functionsHandler={getChallengeList}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        isEdit={isEdit}
        setIsAdd={setIsAdd}
      />
    </CardContainer>
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    setSelected({});
  }, [isModalVisible]);

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);

  const fixedDisabled =
    formData?.category === undefined ||
    formData?.category === "" ||
    formData?.challenge_name === undefined ||
    formData?.challenge_name === "" ||
    formData?.challenge_profile === undefined ||
    formData?.challenge_profile === "" ||
    formData?.coins_on_completion === undefined ||
    formData?.coins_on_completion === "" ||
    formData?.config_id === undefined ||
    formData?.config_id === "" ||
    formData?.description === undefined ||
    formData?.description === "" ||
    formData?.duration === undefined ||
    formData?.duration === "" ||
    formData?.start_date === undefined ||
    formData?.start_date === "" ||
    formData?.end_date === undefined ||
    formData?.end_date === "" ||
    formData?.challenge_id === undefined ||
    formData?.challenge_id === "" ||
    formData?.created_by === undefined ||
    formData?.created_by === "" ||
    activeImage === null ||
    activeIcon === null ||
    activeIcon === "";

  const isSaveButtonDisabled =
    formData?.config_id === 1
      ? fixedDisabled || streakData || timeStamp || timeStampDetail
      : formData?.config_id === 1
      ? fixedDisabled || streakData || duration
      : fixedDisabled;

  // console.log("isSaveButtonDisabled", isSaveButtonDisabled);

  function durationToMinutes(duration) {
    const [hours, minutes] = duration.split(":");
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    return totalMinutes;
  }

  // var minimunCoinsEarnable =
  //   ((formData?.end_date - formData?.start_date) / (1000 * 60 * 60 * 24)) *
  //   Number(formData?.coins_on_completion);
  // var highestDay = 0;

  // console.log("highestDay", highestDay);
  console.log("formData", formData);
  const totalCoinsEarnable = () => {
    var minimunCoinsEarnable =
      ((formData?.end_date - formData?.start_date) / (1000 * 60 * 60 * 24)) *
      Number(formData?.coins_on_completion);

    console.log("minimunCoinsEarnable", minimunCoinsEarnable);

    var highestDay = 0;
    streakData?.forEach(
      ({ days }, i) =>
        (highestDay = Number(days) > highestDay ? Number(days) : highestDay)
    );
    console.log("highestDay", highestDay);

    var totalStreakCoins = 0;
    streakData?.forEach(
      ({ coins }) =>
        (totalStreakCoins +=
          Number(
            (formData?.end_date - formData?.start_date) /
              (1000 * 60 * 60 * 24) /
              highestDay
          ) * Number(coins))
    );

    console.log("totalStreakCoins", totalStreakCoins);

    setTotalCoins(minimunCoinsEarnable + totalStreakCoins);
    return minimunCoinsEarnable + totalStreakCoins;
  };

  const durationToMoment = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return moment().startOf("day").add(hours, "hours").add(minutes, "minutes");
  };

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <>
      {(selected?.challenge_name || isAdd || isEdit) && (
        <Sidebar
          title="Challenge details"
          key={"challenge"}
          onClose={() => {
            setTotalCoins(0);
            setStreakData([]);
            setShowFields(false);
            setSelected({});
            setisEdit(false);
            setIsAdd(false);
            setActiveImage(null);
            setActiveIcon("");
            setFormData({});
            setDuration(null);
            setTimeStamp(null);
            setTimeStampDetail(null);
            setSelectedTags([]);
          }}
          visible
          footer={
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              {!isAdd && (
                <Col span={8} flex={8}>
                  <div>
                    <Popover
                      placement="topLeft"
                      title={""}
                      content={
                        <div
                          onClick={() => {
                            setActiveIcon(selected?.challenge_profile);
                            // setStreakData(selected?.coins_on_streak);
                            setDuration(selected?.duration);
                            setTimeStamp(selected?.time_stamp);
                            setTimeStampDetail({
                              start_time_stamp: selected.start_time_stamp,
                              end_time_stamp: selected.end_time_stamp,
                            });
                            setSelectedTags(selected?.workout_days);
                            setTotalCoins(selected?.max_earn_value);
                            setisEdit(!isEdit);
                            setFormData(() => ({
                              category: selected.category,
                              challenge_name: selected.challenge_name,
                              challenge_profile: selected.challenge_profile,
                              challenge_id: selected?.id,
                              coins_on_completion: selected.coins_on_completion,
                              config_id: selected.config_id,
                              description: selected.description,
                              duration: selected.duration,
                              end_date: moment(selected.end_date),
                              start_date: moment(selected.start_date),
                              end_time_stamp: selected.end_time_stamp,
                              start_time_stamp: selected.start_time_stamp,
                              time_stamp: selected.time_stamp,
                              coins_on_streak: selected.coins_on_streak,
                              created_by: selected.created_by,
                            }));
                          }}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {isEdit ? <EyeOutlined /> : <EditOutlined />}
                          </span>
                          <p style={{ margin: "0px 0px 0px 5px", padding: 0 }}>
                            {isEdit ? "View" : "Edit"}
                          </p>
                        </div>
                      }
                      trigger="click"
                    >
                      <Button
                        style={{
                          margin: 0,
                          padding: "5px 4px",
                          backgroundColor: "lightgray",
                          color: "gray",
                        }}
                      >
                        •••
                      </Button>
                    </Popover>
                    <h4 style={{ marginLeft: 10, display: "inline-block" }}>
                      Action
                    </h4>
                  </div>
                </Col>
              )}
              {(isEdit || isAdd) && (
                <Col span={10} flex={8}>
                  <div style={{ marginLeft: !isAdd && "32%" }}>
                    <Button
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        setSelected({});
                        setFormData({});
                        setisEdit(false);
                        setIsAdd(false);
                        setActiveImage(null);
                        setActiveIcon("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading}
                      // disabled={
                      //   isAdd
                      //     ? isSaveButtonDisabled
                      //     : formData?.Category === "" ||
                      //       formData?.sub_category === "" ||
                      //       formData?.product_name === "" ||
                      //       formData?.SKU_code === "" ||
                      //       formData?.warranty_tenure === "" ||
                      //       formData?.QR_code_name === "" ||
                      //       formData?.ean_code === "" ||
                      //       formData?.QR_code_link === "" ||
                      //       formData?.amazon_review_link === "" ||
                      //       activeIcon === "" ||
                      //       activeIcon === null
                      // }
                      type="primary"
                      onClick={() =>
                        isEdit ? editChallenge() : createChallenge()
                      }
                    >
                      Create
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          }
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row gutter={[10, 20]}>
                    <Col span={24}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Challenge Image
                      </Text>

                      {!(isEdit || isAdd) ? (
                        selected.challenge_profile ? (
                          <Image
                            onClick={(e) => e.stopPropagation()}
                            src={selected?.challenge_profile}
                            style={{
                              objectFit: "cover",
                              borderRadius: "3px",
                              height: "150px",
                              width: "150px",
                            }}
                            preview={selected.challenge_profile ? true : false}
                          />
                        ) : (
                          "-"
                        )
                      ) : (
                        <ImageUpload
                          imageFile={activeImage}
                          setImageFile={setActiveImage}
                          selected={selected}
                          setSelected={setSelected}
                          selectedIcon={activeIcon}
                          setSelectedIcon={setActiveIcon}
                        />
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.challenge_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              challenge_name: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the challenge name"
                        />
                      ) : (
                        <Text>{selected.challenge_name || "-"}</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Description
                      </Text>
                      {isEdit || isAdd ? (
                        <Input
                          style={{ fontSize: 14 }}
                          defaultValue={selected.description}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                          bordered={true}
                          size="large"
                          placeholder="Enter the challenge description"
                        />
                      ) : (
                        <Text>{selected.description || "-"}</Text>
                      )}
                    </Col>

                    <Col span={24}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Host Influencer
                      </Text>
                      {isEdit || isAdd ? (
                        <Select
                          disabled={isEdit}
                          className="host-dropdown"
                          showSearch
                          allowClear
                          style={{
                            fontSize: 14,
                            width: "100%",
                            // height: "100%",
                          }}
                          defaultValue={selected?.created_by?.id}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              created_by: e,
                            });
                          }}
                          optionFilterProp="searchable"
                          bordered={true}
                          size="large"
                        >
                          {influencerList?.map(
                            ({ profile, first_name, mobile, id }) => (
                              <Option
                                style={{ height: "100%" }}
                                searchable={[first_name, mobile]}
                                key={id}
                                value={id}
                              >
                                <div
                                  className="option-block"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: "100%",
                                  }}
                                >
                                  <Image
                                    preview={{ mask: " " }}
                                    style={{
                                      borderRadius: 100,
                                      width: 70,
                                      height: 70,
                                      objectFit: "cover",
                                    }}
                                    src={profile}
                                  />
                                  <span>{first_name}</span>
                                  <span> {mobile}</span>
                                </div>
                              </Option>
                            )
                          )}
                        </Select>
                      ) : (
                        <Text>{selected?.created_by?.name || "-"}</Text>
                      )}
                    </Col>

                    <Col span={24}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Select Days
                      </Text>

                      {isEdit || isAdd
                        ? tagsData.map(({ label, value }) => (
                            <CheckableTag
                              key={value}
                              style={{
                                background: selectedTags.includes(value)
                                  ? "#233E51"
                                  : "#233E511A",
                                borderRadius: "100px",
                                minWidth: 32,
                                color: selectedTags.includes(value)
                                  ? "#FFFFFF"
                                  : "#233E51",
                                // minHeight: 32,

                                padding: "1%",

                                textAlign: "center",
                              }}
                              checked={selectedTags.includes(value)}
                              onChange={(checked) =>
                                handleChange(value, checked)
                              }
                            >
                              {label}
                            </CheckableTag>
                          ))
                        : tagsData.map(({ label, value }) => (
                            <CheckableTag
                              key={value}
                              defaultValues={selected?.workout_days}
                              style={{
                                background: selected?.workout_days?.includes(
                                  value
                                )
                                  ? "#233E51"
                                  : "#233E511A",
                                borderRadius: "100px",
                                minWidth: 32,
                                color: selected?.workout_days?.includes(value)
                                  ? "#FFFFFF"
                                  : "#233E51",

                                padding: "1%",

                                textAlign: "center",
                              }}
                            >
                              {label}
                            </CheckableTag>
                          ))}
                    </Col>

                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Duration
                      </Text>
                      <Text>
                        {(isEdit || isAdd ? (
                          <RangePicker
                            defaultValue={
                              selected?.start_date && selected?.end_date
                                ? [
                                    moment(selected?.start_date),
                                    moment(selected?.end_date),
                                  ]
                                : null
                            }
                            onChange={(e) =>
                              e.length > 0 &&
                              setFormData((prev) => ({
                                ...prev,
                                start_date: moment(e[0]),
                                end_date: moment(e[1]),
                              }))
                            }
                            disabledDate={(current) => {
                              const customDate = moment().format("YYYY-MM-DD");
                              return (
                                current &&
                                (current < moment(customDate, "YYYY-MM-DD") ||
                                  current > maxDate)
                              );
                            }}
                            fullWidth
                          />
                        ) : (
                          <Text>
                            {selected?.total_challenge_days + " days" || "-"}
                          </Text>
                        )) || "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Challenge Category
                      </Text>
                      {isEdit || isAdd ? (
                        <Select
                          options={challengeCategory?.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          style={{ fontSize: 14, width: "100%" }}
                          defaultValue={selected.category}
                          onChange={(e, val) => {
                            setFormData({
                              ...formData,
                              category: val.value,
                            });
                          }}
                          bordered={true}
                          size="large"
                          placeholder="Select a Category"
                        />
                      ) : (
                        <Text>
                          {challengeCategory.filter(
                            ({ id, name }) => id === selected.category
                          )[0].name || "-"}
                        </Text>
                      )}
                    </Col>

                    <Col span={12}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Tracker
                      </Text>
                      {isEdit || isAdd ? (
                        <Select
                          options={trackerListing?.map(({ type, id }) => ({
                            value: id,
                            label: type,
                          }))}
                          style={{ fontSize: 14, width: "100%" }}
                          defaultValue={selected.config_id}
                          onChange={(e, val) => {
                            setFormData({
                              ...formData,
                              config_id: val.value,
                            });
                          }}
                          bordered={true}
                          size="large"
                          placeholder="Select tracker"
                        />
                      ) : (
                        <Text>
                          {trackerListing.filter(
                            ({ id }) => id === selected?.config_id
                          )[0]?.type ||
                            "-" ||
                            "-"}
                        </Text>
                      )}
                    </Col>

                    {formData?.config_id &&
                      ((formData?.config_id || selected?.config_id) === 1 ? (
                        <>
                          <Col span={12}>
                            <Text type="label" style={{ fontSize: 11 }}>
                              Main Time
                            </Text>
                            {isEdit || isAdd ? (
                              <TimePicker
                                style={{ width: "100%" }}
                                defaultValue={
                                  selected?.time_stamp
                                    ? moment(selected?.time_stamp, "HH:mm:ss")
                                    : null
                                }
                                onChange={(e, val) => {
                                  setTimeStamp(val);
                                }}
                                size="large"
                              />
                            ) : (
                              <Text>{selected.time_stamp || "-"}</Text>
                            )}
                          </Col>
                          <Col span={12}>
                            <Text
                              type="label"
                              style={{ fontSize: 11, width: "100%" }}
                            >
                              Time range
                            </Text>
                            {isEdit || isAdd ? (
                              <TimePicker.RangePicker
                                style={{ width: "100%" }}
                                defaultValue={
                                  selected?.start_time_stamp &&
                                  selected?.end_time_stamp
                                    ? [
                                        moment(
                                          selected?.start_time_stamp,
                                          "HH:mm:ss"
                                        ),
                                        moment(
                                          selected?.end_time_stamp,
                                          "HH:mm:ss"
                                        ),
                                      ]
                                    : null
                                }
                                onChange={(e, val) => {
                                  setTimeStampDetail({
                                    start_time_stamp: val[0],
                                    end_time_stamp: val[1],
                                  });
                                }}
                                size="large"
                              />
                            ) : (
                              <Text>
                                {selected.start_time_stamp[0] +
                                  " to " +
                                  selected.end_time_stamp[1] || "-"}
                              </Text>
                            )}
                          </Col>
                        </>
                      ) : (formData?.config_id || selected?.config_id) === 2 ? (
                        <Col span={12}>
                          <Text type="label" style={{ fontSize: 11 }}>
                            Timer
                          </Text>
                          {isEdit || isAdd ? (
                            <TimePicker
                              style={{ width: "100%" }}
                              defaultValue={
                                selected?.duration
                                  ? durationToMoment(selected.duration)
                                  : null
                              }
                              onChange={(time, timestring) => {
                                setDuration(durationToMinutes(timestring));
                              }}
                              format="HH:mm"
                            />
                          ) : (
                            <Text>{selected.duration || "-"}</Text>
                          )}
                        </Col>
                      ) : null)}

                    <Col span={24} style={{ marginTop: 15 }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          margin: "5px 0px 10px 0px",
                        }}
                      >
                        <div style={{ marginBottom: 20 }}>
                          <Text
                            type="label"
                            style={{ fontSize: 15, color: "black" }}
                          >
                            Dopecoins per dose
                          </Text>
                          {isEdit || isAdd ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ marginRight: 10 }}>
                                <Input
                                  name="reward"
                                  type="number"
                                  style={{ fontSize: 14 }}
                                  defaultValue={selected.coins_on_completion}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      coins_on_completion: e.target.value,
                                    })
                                  }
                                  bordered={true}
                                  size="large"
                                  placeholder="Eg. 10"
                                  min={1}
                                />
                              </div>
                              coins
                            </div>
                          ) : (
                            <Text>{selected.coins_on_completion || "-"}</Text>
                          )}
                        </div>

                        {/* <div>
                          <Text
                            type="label"
                            style={{ fontSize: 15, color: "black" }}
                          >
                            Dopecoins for streaks{" "}
                            {(isEdit || isAdd) && (
                              <Button
                                style={{ marginLeft: 15 }}
                                type="dashed"
                                onClick={() => setShowFields(true)}
                              >
                                Add
                                <PlusSquareFilled />
                              </Button>
                            )}
                          </Text>

                          {
                            //
                            <>
                              {isEdit || isAdd ? (
                                <>
                                  {showFields && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 12,
                                        marginTop: 15,
                                      }}
                                    >
                                      <CheckSquareOutlined
                                        onClick={() => {
                                          return (
                                            streakRewards?.streak_day &&
                                            streakRewards?.streak_dopecoins &&
                                            (setStreakData((prev) => [
                                              ...prev,
                                              {
                                                days: streakRewards?.streak_day,
                                                coins:
                                                  streakRewards?.streak_dopecoins,
                                              },
                                            ]),
                                            setShowFields(false))
                                          );
                                        }}
                                        style={{
                                          fontSize: 25,
                                          color: "lightgreen",

                                          marginRight: 10,
                                          cursor: "pointer",
                                        }}
                                      />
                                      <div style={{ marginRight: 10 }}>
                                        <Input
                                          name="reward"
                                          type="number"
                                          style={{ fontSize: 14 }}
                                          defaultValue={selected.QR_code_name}
                                          onChange={(e) =>
                                            setStreakRewards({
                                              ...streakRewards,
                                              streak_dopecoins: e.target.value,
                                            })
                                          }
                                          bordered={true}
                                          size="large"
                                          placeholder="e.g 10"
                                          min={1}
                                        />
                                      </div>
                                      dopecoins for
                                      <div
                                        style={{
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        <Input
                                          name="day"
                                          type="number"
                                          style={{ fontSize: 14 }}
                                          defaultValue={selected.QR_code_name}
                                          onChange={(e) =>
                                            setStreakRewards({
                                              ...streakRewards,
                                              streak_day: e.target.value,
                                            })
                                          }
                                          bordered={true}
                                          size="large"
                                          placeholder="e.g 3"
                                          min={1}
                                        />
                                      </div>
                                      days
                                    </div>
                                  )}

                                  <div style={{ margin: "15px " }}>
                                    {streakData?.length > 0 &&
                                      streakData?.map(({ days, coins }, id) => (
                                        <div
                                          id={id}
                                          key={id}
                                          style={{
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: 10,
                                            margin: "5px 2px",
                                            padding: 15,
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            {`${coins} dopecoins
                                for a streak of ${days} days`}

                                            <DeleteOutlined
                                              onClick={() =>
                                                setStreakData((data) =>
                                                  data.filter(
                                                    (_, index) => index !== id
                                                  )
                                                )
                                              }
                                              style={{
                                                marginLeft: "auto",
                                                cursor: "pointer",
                                                fontSize: 15,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </>
                              ) : (
                                <div style={{ margin: "15px " }}>
                                  {selected?.coins_on_streak?.length > 0 &&
                                    selected?.coins_on_streak?.map(
                                      ({ days, coins }, id) => (
                                        <div
                                          id={id}
                                          key={id}
                                          style={{
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: 10,
                                            margin: "5px 2px",
                                            padding: 15,
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            {`${coins} dopecoins
                                for a streak of ${days} days`}

                                            {isEdit && isAdd && (
                                              <DeleteOutlined
                                                onClick={() =>
                                                  setStreakData((data) =>
                                                    data.filter(
                                                      (_, index) => index !== id
                                                    )
                                                  )
                                                }
                                                style={{
                                                  marginLeft: "auto",
                                                  cursor: "pointer",
                                                  fontSize: 15,
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </>
                          }
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
            ]}
          />
        </Sidebar>
      )}

      {Products}
    </>
  );
}
