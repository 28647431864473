import { message } from "antd";
import React, { useEffect, useState } from "react";

import { CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";
import BookingSidebar from "./BookingSidebar";

const allBookingColumns = [
  {
    title: "Booking ID",
    dataIndex: "booking_id",
    key: "booking_id",
    align: "center",
    sorter: true,
    render: (text) => (
      <>
        {"#" + text}
        <CopyOutlined
          style={{ marginLeft: "2vh" }}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(text).then(
              () =>
                message.success({
                  content: "Copied to clipboard!",
                  style: { marginTop: "8vh" },
                }),
              (err) => {
                message.error({
                  content: "Could not copy text!",
                  err,
                  style: { marginTop: "8vh" },
                });
              }
            );
          }}
        ></CopyOutlined>
      </>
    ),
  },
  {
    title: "Service name",
    dataIndex: "title",
    key: "title",
    align: "center",
    render: (text) =>
      text.length > 12 ? (
        <span>{text.slice(0, 12) + "..."}</span>
      ) : (
        <span>{text}</span>
      ),
  },
  {
    title: "Expert name",
    dataIndex: "expert_name",
    key: "expert_name",
    align: "center",

    render: (t) => (t ? t : "-"),
  },
  {
    title: "Category",
    align: "center",
    dataIndex: "category_name",
    key: "category_name",
    render: (t) =>
      t ? (
        <div className="category-container" style={{ alignItems: "center" }}>
          <div className="category-data">{t}</div>
        </div>
      ) : (
        "-"
      ),
    sorter: true,
  },

  {
    title: "Session type",
    dataIndex: "session_type",
    key: "session_type",
    sorter: true,
  },
  {
    title: "Booked by",
    dataIndex: "first_name",
    key: "first_name",
    render: (t) => (t ? t : "-"),
  },
  {
    title: "Amount",
    dataIndex: "rate",
    align: "center",
    key: "rate",
    sorter: true,
    render: (t) => (t ? "₹ " + t : "-"),
  },
];

export default function Booking({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [list, setList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [selected, setSelected] = useState(0);
  const [popup, setPopup] = useState(false);

  useEffect(() => getBookingList(), []);

  const getBookingList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getAllBookingsList",
      "dashboard/"
    );

    if (res.data) {
      setList(res.data);
      setTotal(res.meta?.total);
      setLoading(false);
    } else setLoading(false);
  };

  return (
    <>
      {sidebar && !popup && (
        <BookingSidebar data={selected} onClose={() => setSidebar(false)} />
      )}
      <CardContainer bordered={false} hoverable={false}>
        <BreadCrumb title="Bookings Received" />

        <TableContainer
          loading={loading}
          total={total}
          columns={[
            ...allBookingColumns,

            {
              title: "Booked on",
              dataIndex: "created_at",
              key: "created_at",
              align: "center",
              sorter: true,
              render: (text) => dayjs(text).format("DD MMM, YYYY h:mm A"),
            },
          ]}
          data={list}
          dateRangePicker
          addHandler={() => setPopup(true)}
          parseButton="export"
          filter="bookingList"
          searchFor="Name, ID, service name"
          functionsHandler={getBookingList}
          rowClickHandler={(record) => {
            setSidebar(true);
            setSelected(record);
          }}
          rowKeyName="booking_id"
        />
      </CardContainer>
    </>
  );
}
