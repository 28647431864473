import React, { useEffect, useState } from "react";

import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";
import "./index.css";

export default function RentalHGS({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [rentalHgsList, setRentalHgsList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      key: "name",
      render: (text, object) => <Text>{text || "-"}</Text>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",

      render: (t) => (t ? t : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text, object) => text || "-",
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",

      render: (text, object) => text || "-",
    },
    {
      title: "Newsletter subscription",
      dataIndex: "is_subscribed",
      key: "is_subscribed",
      width: 200,
      align: "center",
      render: (text, object) => (text ? "Yes" : "No") || "-",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      align: "center",
      render: (text, object) => DateFormat(text) || "-",
    },
  ];

  const inviteColumns = [
    {
      title: "Invitee name",
      dataIndex: "invite_status",
      key: "invite_status",
      align: "center",
      render: (text, object) =>
        object?.invitee_name ? object?.invitee_name : "-",
    },
    {
      title: "Mobile",
      dataIndex: "invite_status",
      key: "invite_status",
      align: "center",
      render: (text, object) =>
        object?.invitee_mobile ? object?.invitee_mobile : "-",
    },
    {
      title: "Status",
      dataIndex: "invite_status",
      key: "invite_status",
      render: (text, object) =>
        object?.is_locked === 1 ? (
          <div className="status-container" style={{ background: "#EEEEEE" }}>
            <div className="status-pill" style={{ color: "#6F7379" }}>
              Locked
            </div>
          </div>
        ) : object?.is_reward === 1 ? (
          <div className="status-container" style={{ background: " #FF4A01" }}>
            <div className="status-pill" style={{ color: "#FFFFFF" }}>
              {"Reward claimed"}
            </div>
          </div>
        ) : (
          <div className="status-container" style={{ background: " #FF4A01" }}>
            <div className="status-pill" style={{ color: "#FFFFFF" }}>
              {"Invite sent"}
            </div>
          </div>
        ),
    },
  ];

  useEffect(() => {
    getRentalHgsList();
  }, []);

  const getRentalHgsList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "getShopifyLeads", "dashboard/");
    if (res.data) {
      console.log("res", res.total);
      setRentalHgsList(res.data);
      setTotal(res?.meta?.total);
    }
    setLoading(false);
  };

  return (
    <>
      {popup && (
        <Popup
          title="Invites status"
          cancelHandler={() => {
            setPopup(false);
          }}
          saveHandler={() => {}}
          saveText="Submit"
          cancelText="Cancel"
          width="726px"
          footer={false}
          loading={selected.loading}
          visible
        >
          <TableContainer
            loading={loading}
            columns={write === 0 ? inviteColumns : [...inviteColumns]}
            data={selected?.invite_status}
            searchFor=""
            rowClickHandler={(row) => {}}
            dateRangePicker={false}
            exportButton={false}
            functionsHandler={{}}
          />
        </Popup>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="Rental Home Gym Setup" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
          }}
          data={rentalHgsList}
          total={total}
          filter="rentalHgs"
          parseButton="export"
          dateRangePicker="true"
          searchFor="Name, Mobile, Email, City"
          functionsHandler={getRentalHgsList}
        />
      </CardContainer>
    </>
  );
}
