import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";

import BreadCrumb from "../../components/Breadcrumb";

export default function CubeClubWarranty({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [warrantyDetails, setwarrantyDetails] = useState([]);

  const columns = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Sub Category",
      dataIndex: "sub_category",
      key: "sub_category",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Ordered From",
      dataIndex: "ordered_from",
      key: "ordered_from",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (t) =>
        t ? <div style={{ width: "max-content" }}>{t}</div> : "-",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Warranty Activated",
      dataIndex: "warranty_activated_on",
      key: "warranty_activated_on",
      render: (t) =>
        t ? (
          <div style={{ width: "max-content" }}>
            {dayjs(t).format("DD-MM-YYYY")}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Warranty activated Up to",
      dataIndex: "warranty_extended_to",
      key: "warranty_extended_to",
      render: (t) =>
        t ? (
          <div style={{ width: "max-content" }}>
            {dayjs(t).format("DD-MM-YYYY")}
          </div>
        ) : (
          "-"
        ),
    },
  ];

  useEffect(() => {
    getWarrantyDetails();
  }, []);

  const getWarrantyDetails = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getWarrantyDetails",
      "dashboard/"
    );
    if (res.data) {
      setwarrantyDetails(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const warrantyActivation = (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Web Warranty" />

      <TableContainer
        loading={loading}
        columns={write === 0 ? columns : [...columns]}
        dateRangePicker={true}
        data={warrantyDetails}
        total={total}
        searchFor=" "
        parseButton="export"
        filter="warrantyList"
        functionsHandler={getWarrantyDetails}
      />
    </CardContainer>
  );

  return <>{warrantyActivation}</>;
}
