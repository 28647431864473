import { message } from "antd";
import axios from "axios";

import configData from "../config.json";

const apiBaseUrl = `${configData.apiUrl}/api/v1/`;
const apiBaseUrlForReviews = `${configData.apiUrl}/`;
// const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
// const apiBaseUrl = `${REACT_APP_BASE_URL}/api/v1/`;

export async function getApi(
  payload = {},
  api_name = "getOrders",
  apiUrl = "dopamine/",
  apiHeader = {}
) {
  console.log("payload", payload);
  let data = {};
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  if (!Object.keys(apiHeader).length) payload = { ...payload, api_name };
  await axios
    .post(
      (apiUrl === "tools/" ? apiBaseUrlForReviews : apiBaseUrl) +
        apiUrl +
        api_name,
      payload,
      {
        headers: {
          ...apiHeader,
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    .then((res) => {
      if (api_name === "scrapeReviews" && res.status === 200) {
        data = res.data;
      } else if (res.status === 200 && res.data.success) {
        data = res.data;
      } else if (res.status === 200 && res.data.msg === "Invalid token.") {
        invalidTokenHandler();
        errorMessage();
      } else {
        errorMessage();
      }
    })
    .catch((err) => {
      err.response && err.response.status === 406
        ? errorMessage(err.response.data.msg)
        : errorMessage();
      err.response && console.log(err.response);
    });
  return data;
}

export async function saveApi(
  values = {},
  msg = "Order Cancelled",
  api_name = "cancelOrder",
  apiUrl = "dopamine/"
) {
  let success = false;
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  await axios
    .post(
      apiBaseUrl + apiUrl + api_name,
      { ...values, api_name },
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
    .then((res) => {
      if (res.status === 200 && res.data.success) {
        if (msg) successMessage(msg);
        if (values.getId) {
          success = res.data.data;
        } else {
          success = true;
        }
      } else if (res.status === 200 && res.data.msg === "Invalid token.") {
        invalidTokenHandler();
        errorMessage();
      } else {
        errorMessage();
      }
    })
    .catch((err) => {
      errorMessage();
      console.log(err);
    });
  return success;
}

export async function fetchApi(apiName = "getConfig", apiUrl = "ecomm/") {
  let data = {};
  const tokenString = localStorage.getItem("token");
  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    await axios
      .get(apiBaseUrl + apiUrl + apiName, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          data = res.data.data;
        } else if (res.status === 200 && res.data.msg === "Invalid token.") {
          invalidTokenHandler();
          errorMessage();
        } else {
          errorMessage();
        }
      })
      .catch((err) => {
        errorMessage();
        console.log(err);
      });
  }
  return data;
}

export async function formDataApi(
  values = [],
  apiName = "uploadImage",
  apiUrl = "dopamine/"
) {
  let url = "";
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  const fmData = new FormData();
  fmData.append("api_name", "uploadImage");
  values.forEach((fm) => {
    fmData.append(fm.label, fm.value);
  });
  await axios
    .post(apiBaseUrl + apiUrl + apiName, fmData, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status === 200 && res.data.success) url = res.data.data?.url;
      else errorMessage();
    })
    .catch((err) => {
      errorMessage();
      console.log(err);
    });
  return url;
}

function invalidTokenHandler() {
  localStorage.removeItem("theme");
  localStorage.removeItem("token");
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export function successMessage(msg) {
  return message.success({
    content: msg + " Successfully.",
    style: { marginTop: "8vh" },
  });
}

export function errorMessage(msg = "Something went wrong, please try again.") {
  return message.error({
    content: msg,
    style: { marginTop: "8vh" },
  });
}
