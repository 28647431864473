import { CloseCircleOutlined } from "@ant-design/icons";
import { Drawer, Skeleton } from "antd";
import React from "react";

import Text from "../Text";
import "./index.css";

const getDrawerWidth = (reports) => {
  if (window.innerWidth < 460) return "85%";
  if(reports){
     return "50%"}
  return 580;
};

export default function Sidebar({
  reports = false,
  title = "Order Details",
  loading = false,
  visible = false,
  onClose = () => {},
  footer = null,
  backDrop = true,
  children,
}) {
  return (
    <Drawer
      visible={visible}
      className="drwr"
      title={<Text type="title">{title}</Text>}
      footer={loading ? null : footer}
      width={getDrawerWidth(reports)}
      zIndex={99}
      onClose={onClose}
      split={true}
      extra={
        <CloseCircleOutlined
          style={{ cursor: "pointer", fontSize: 20 }}
          onClick={onClose}
        />
      }
      closeIcon={false}
      mask={backDrop}
    >
      {loading
        ? [0, 1, 2, 3].map((sk) => (
            <Skeleton
              key={sk}
              title
              paragraph={{ rows: 2 }}
              loading={loading}
              active
              round
            />
          ))
        : children}
    </Drawer>
  );
}
