import { FlagOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import { Option } from "antd/lib/mentions";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import { ImageUpload } from "../../components/ImageUpload";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import configData from "../../config.json";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

export default function Influencer({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setisCreate] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const isMaxValues = selectedValues?.length === 3;

  useEffect(() => {
    if (activeImage) {
      var reader = new FileReader();

      reader.onload = (event) => {
        const dataUrl = event.target.result;
        activeImage && setActiveIcon(dataUrl);
      };
      reader.readAsDataURL(activeImage);
    }
  }, [activeImage]);

  const columns = [
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      align: "center",
      render: (text) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<FlagOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, object) => (
        <div style={{ width: "max-content" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, object) => text || "-",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "center",
      render: (text) => text || "-",
    },
    {
      title: "Joining Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) => DateFormat(text),
    },
  ];

  useEffect(() => {
    getUsersList();
    getCategoryList();
  }, []);

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 10, admin_type_id: 2 },
      "getUsersList",
      "user/"
    );
    if (res.data) {
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const updateStatusHandler = async () => {
    setSelected({ ...selected, loading: true });
    let is_active = 1;
    let msg = "Influencer Activated";
    if (selected.is_active) {
      is_active = 0;
      msg = "Influencer Deactivated";
    }
    const res = await saveApi(
      { user_id: selected.id, is_active: is_active },
      msg,
      "deactivateUser",
      "user/"
    );
    if (res) {
      setUserList(
        userList.map((user) => {
          if (user.id === selected.id) {
            return (user = { ...user, is_active: is_active });
          }
          return user;
        })
      );
    }
    setSelected({});
    setPopup(false);
  };

  //user creation
  const createUser = async (data = {}) => {
    const payload = {
      ...data,
      api_name: "createUser",
      ...form.getFieldValue(),
      dob: form.getFieldValue().dob.format("MM/DD/YYYY"),
    };
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 10 },
      "createUser",
      "dashboard/"
    );
    if (res.success) {
      // setUserList(res.data);
      resetFunc();

      setTotal(res.meta?.total);
      uploadChallengeImage(res?.data[0]?.user_id);
    }
    setLoading(false);
  };

  //Category types
  const getCategoryList = async () => {
    const payload = {
      api_name: "getCategoryList",
      admin_type_id: 1,
    };
    setLoading(true);
    const res = await getApi({ ...payload }, "getCategoryList", "user/");
    if (res.success) {
      setCategoryList(res.data);
    }
    setLoading(false);
  };

  const uploadChallengeImage = async (user_id) => {
    if (activeImage !== null) {
      var formdata = new FormData();

      formdata.append("file", activeImage, activeImage?.name);
      formdata.append("api_name", "uploadProfileImage");
      formdata.append("user_id", user_id);

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      let requestOptions = {
        method: "POST",
        body: formdata,
        headers: { Authorization: `Bearer ${userToken}` },
      };

      try {
        const response = await fetch(
          `${configData.apiUrl}/api/v1/upload/uploadProfileImage`,
          requestOptions
        );
        const data = await response.json();

        if (data?.success) {
          getUsersList();
        }

        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        setLoading(false);
      }
    } else {
      getUsersList();
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // setFormValues(values);
    isEdit ? createUser({ user_id: selected?.id }) : createUser();
    console.log("Received values of form: ", values);
    console.log("Received values of form: ", form.getFieldValue());
  };

  const verifyusername = async () => {
    const payload = isEdit
      ? {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
          user_id: selected?.id,
        }
      : {
          username: form.getFieldValue().username,
          api_name: "verifyusername",
        };
    const res = await getApi(
      { ...payload, role_id: 10, admin_type_id: 2 },
      "verifyusername",
      "user/"
    );
    if (res.data) {
      return true;
    }
  };

  const resetFunc = () => {
    setSelected({});
    setIsSidebarOpen(false);
    setisCreate(false);
    setIsEdit(false);
    form.resetFields();
    setActiveImage(null);
    setActiveIcon("");
  };

  return (
    <>
      <Sidebar
        loading={selected.loading}
        title="Influencer details"
        onClose={resetFunc}
        visible={isSidebarOpen}
      >
        <LabelValueContainer
          data={
            isCreate
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Influencer"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Create
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : isEdit
              ? [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Form
                          {...formItemLayout}
                          form={form}
                          name="Create an Influencer"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 600,
                          }}
                          scrollToFirstError
                        >
                          <Form.Item name="profile" label="Profile Image">
                            <ImageUpload
                              imageFile={activeImage}
                              setImageFile={setActiveImage}
                              selected={selected}
                              setSelected={setSelected}
                              selectedIcon={activeIcon}
                              setSelectedIcon={setActiveIcon}
                            />
                          </Form.Item>
                          <Form.Item
                            name="first_name"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your First Name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Username!",
                              },
                              // Asynchronous validation rule
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.resolve();
                                  }
                                  return verifyusername(value).then(
                                    (isValid) => {
                                      if (!isValid) {
                                        return Promise.reject(
                                          new Error("Username is already taken")
                                        );
                                      }
                                    }
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input placeholder="Enter your username" />
                          </Form.Item>

                          <Form.Item
                            name="categories"
                            label="Categories"
                            rules={[
                              {
                                required: true,
                                message: "Please select an category!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select categories"
                              allowClear
                              onChange={(e) => setSelectedValues(e)}
                              {...(isMaxValues && {
                                open: false,
                              })}
                            >
                              {categoryList.map(
                                ({ category_name, category_id }) => (
                                  <Option value={category_id}>
                                    {category_name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                message: "Please select an gender!",
                              },
                            ]}
                          >
                            <Select placeholder="Select an gender" allowClear>
                              <Option value="Male">Male</Option>
                              <Option value="Female">Female</Option>
                              <Option value="Others">Others</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item name="weight" label="Weight in kgs*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item name="height" label="Height in cms*">
                            <Input type="number" />
                          </Form.Item>

                          <Form.Item
                            name="dob"
                            label="Date of birth"
                            rules={[
                              {
                                required: true,
                                message: "Please select a date!",
                              },
                            ]}
                          >
                            <DatePicker format={"MM/DD/YYYY"} />
                          </Form.Item>

                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                pattern: /^\d+$/,
                                message:
                                  "Mobile number should contain just number",
                              },
                              {
                                max: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                min: 10,
                                message: "Mobile number should be 10 digits",
                              },
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input disabled type="number" maxLength={10} />
                          </Form.Item>

                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input disabled type="mail" />
                          </Form.Item>

                          <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item {...tailFormItemLayout}>
                            <Button type="secondary" onClick={resetFunc}>
                              Cancel
                            </Button>
                          </Form.Item>
                        </Form>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                ]
              : [
                  {
                    label: " ",
                    value: (
                      <Row>
                        <Col span={8}>
                          <Avatar
                            icon={<UserOutlined />}
                            size={100}
                            alt=""
                            src={selected.profile}
                          />
                        </Col>
                        <Col span={8}>
                          <Text type="label" style={{ fontSize: 11 }}>
                            Name
                          </Text>
                          <Text>{selected.first_name}</Text>
                          <br style={{ marginBottom: "1rem" }} />
                          <Text type="label" style={{ fontSize: 11 }}>
                            Joining Date
                          </Text>
                          <Text>{DateFormat(selected.created_at)}</Text>
                        </Col>

                        <Col span={8} style={{ textAlign: "end" }}>
                          {/* <EditFilled
                            onClick={() => {
                              setIsEdit(true);
                              form.setFieldsValue({
                                dob: moment(selected?.dob, "MM/DD/YYYY"),
                                profile: selected?.profile,
                                first_name: selected?.first_name,
                                username: selected?.username,
                                categories: selected?.categories,
                                gender: selected?.gender,
                                mobile: selected?.mobile,
                                email: selected?.email,
                                weight: selected?.weight,
                                height: selected?.height,
                              });
                            }}
                          /> */}
                        </Col>
                      </Row>
                    ),
                    custom: true,
                    fullWidth: true,
                  },
                  { label: "Username", value: selected.username },
                  { label: "Mobile Number", value: selected.mobile },
                  { label: "Email", value: selected.email },
                  { label: "Type", value: selected.role },
                  { label: "Gender", value: selected.gender },
                  { label: "Date of Birth", value: selected.dob },
                  {
                    label: "App version",
                    value: selected.app_version || "-",

                    custom: true,
                    fullWidth: true,
                  },
                ]
          }
        />
      </Sidebar>

      {popup && (
        <Popup
          saveText={selected.is_active ? "Yes, Deactivate" : "Yes, Active"}
          title="Update Centre Active Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure to{" "}
            <b>{selected.is_active ? "deactivate" : "activate"} </b>
            the brand <b>{selected.first_name}</b>?
          </Text>
        </Popup>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="Influencer" />
        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
            setIsSidebarOpen(true);
            setActiveIcon(row?.profile);
          }}
          filter="Influencer"
          data={userList}
          total={total}
          dateRangePicker="true"
          searchFor="Name, Mobile No."
          functionsHandler={getUsersList}
          // userCreation="Influencer"
          setIsSidebarOpen={setIsSidebarOpen}
          setisCreate={setisCreate}
        />
      </CardContainer>
    </>
  );
}
