import { Tabs } from "antd";
import React, { useState } from "react";

import "./index.css";

export default function TabContainer({
  onChange,
  children,
  getDeletedAccounts,
}) {
  const [deleted, setDeleted] = useState("no");

  return (
    <div className="tbsc">
      <Tabs
        onTabClick={(e) => {
          return (
            setDeleted(e === "deleted" ? "yes" : "no"),
            e === "deleted" && getDeletedAccounts({ deleted })
          );
        }}
        onChange={onChange}
        type="card"
      >
        {children}
      </Tabs>
    </div>
  );
}
