import React, { useState } from "react";

import { CopyOutlined, PlayCircleFilled } from "@ant-design/icons";
import { Button, List, message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Popup from "../../../components/Popup";
import Sidebar from "../../../components/Sidebar";
import LabelValueContainer from "../../../containers/LabelValue";

export default function BookingSidebar({ data = {}, onClose = () => {} }) {
  const [popup, setPopup] = useState(false);
  const [url, setUrl] = useState({});
  dayjs.extend(customParseFormat);

  return (
    <>
      {popup && (
        <Popup
          title="Pre-Recorded Video"
          cancelHandler={() => {
            setPopup(false);
            setUrl({});
          }}
          saveHandler={() => {
            setPopup(false);
            setUrl({});
          }}
          width="fit-content"
          footer={false}
          loading={url.loading}
          visible
        >
          <video width="750" height="500" controls>
            <source src={url} type="video/mp4" />
          </video>
        </Popup>
      )}

      <Sidebar title="Booking Details" visible onClose={onClose}>
        <LabelValueContainer
          data={[
            { label: "Expert Name", value: data?.expert_name },
            {
              label: "Booking Id",
              value: (
                <>
                  {data?.booking_id}
                  <CopyOutlined
                    style={{ marginLeft: "2vh" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(data.booking_id).then(
                        () =>
                          message.success({
                            content: "Copying to clipboard was successful!",
                            style: { marginTop: "8vh" },
                          }),
                        (err) => {
                          message.error({
                            content: "Could not copy text!",
                            err,
                            style: { marginTop: "8vh" },
                          });
                        }
                      );
                    }}
                  ></CopyOutlined>
                </>
              ),
            },
            { label: "Session type", value: data.session_type },

            { label: "Expert Level", value: data.level },
            { label: "Category Name", value: data.category_name || "-" },
            { label: "Session title", value: data.title },
            { label: "Session description", value: data.description },
            data.session_type === "Live" && {
              label: "Session scheduled",
              value: data?.selected_session && (
                <>
                  <List
                    size="small"
                    dataSource={data?.selected_session}
                    renderItem={(item) => (
                      <div>
                        <li style={{ padding: "3px" }}>
                          <div style={{ display: "flex" }}>
                            <div>
                              {dayjs(item.start_time, "HH:mm:ss").format(
                                "hh:mm A"
                              ) +
                                " to " +
                                dayjs(item.end_time, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                            </div>
                            {"-"}
                            <div style={{ width: "210px" }}>
                              {item.day +
                                ", " +
                                dayjs(item.start_date, "D M YYYY").format(
                                  "DD MMMM YYYY"
                                )}
                            </div>
                          </div>
                        </li>
                      </div>
                    )}
                  />
                </>
              ),
              fullWidth: true,
            },

            data?.session_type === "In-person" && {
              label: "Session info",
              value: data?.offline_session && (
                <>
                  <li>
                    {"Start date : " +
                      dayjs(data?.offline_session?.start_date).format(
                        "DD MMM, YYYY h:mm A"
                      )}
                    ,
                  </li>
                  <li>{"Session title : " + data?.offline_session?.title},</li>
                  <li>{" Amount : ₹ " + data?.offline_session?.price}</li>
                </>
              ),
              fullWidth: true,
            },

            data.session_type === "In-person" && {
              label: "Address Info",
              value: data?.address ? (
                <>
                  {data?.address?.address1 +
                    ", " +
                    data?.address?.address2 +
                    ", (Near) " +
                    data?.address?.landmark +
                    ", " +
                    data?.address?.city +
                    ", " +
                    data?.address?.state +
                    "."}
                  <CopyOutlined
                    style={{ marginLeft: "2vh" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard
                        .writeText(
                          data?.address?.address1 +
                            ", " +
                            data?.address?.address2 +
                            ", " +
                            data?.address?.city +
                            ", " +
                            data?.address?.state +
                            "."
                        )
                        .then(
                          () =>
                            message.success({
                              content: "Copying to clipboard was successful!",
                              style: { marginTop: "8vh" },
                            }),
                          (err) => {
                            message.error({
                              content: "Could not copy text!",
                              err,
                              style: { marginTop: "8vh" },
                            });
                          }
                        );
                    }}
                  ></CopyOutlined>
                  <br />
                  {"PIN : " + data?.address?.pincode}
                  <br />
                  {"Address type : " + data?.address?.address_type}
                </>
              ) : (
                "-"
              ),
              fullWidth: true,
            },

            data?.session_type === "Pre-recorded" && {
              label: "Session video info",
              value: data?.session_count && (
                <>
                  {data?.session_count.map(
                    (ele, i) =>
                      ele.media_type === "video" && (
                        <List style={{ marginTop: "4px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {i}
                            <div style={{ width: "200px", paddingLeft: "5px" }}>
                              Title : {ele?.video_title}
                            </div>
                            <Button
                              style={{ marginLeft: "20px" }}
                              icon={<PlayCircleFilled />}
                              onClick={() => {
                                setPopup(true);
                                setUrl(ele.url);
                              }}
                            ></Button>
                          </div>
                        </List>
                      )
                  )}
                </>
              ),
              fullWidth: true,
            },
          ]}
        />
      </Sidebar>
    </>
  );
}
