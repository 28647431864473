import { Tabs } from "antd";
import React from "react";

import TabContainer from "../../components/Tab";
import PageContainer from "../../containers/Page";

const courseColumns = [
  { title: "Name", key: "course_name", dataIndex: "course_name", sorter: true },
];

const subjectColumns = [
  {
    title: "Name",
    key: "subject_name",
    dataIndex: "subject_name",
    sorter: true,
  },
];

const gradeColumns = [
  { title: "Name", key: "grade_name", dataIndex: "grade_name", sorter: true },
];

const classColumns = [
  {
    title: "Name",
    key: "number_of_classes",
    dataIndex: "number_of_classes",
    sorter: true,
  },
];

const formatColumns = [
  { title: "Name", key: "format_name", dataIndex: "format_name", sorter: true },
];

export default function Offerings() {
  return (
    <TabContainer>
      <Tabs.TabPane tab="Courses" key="1">
        <PageContainer
          type="Course"
          getApiName="dashboard/getCourseList"
          columns={courseColumns}
          tableProps={{ rowKeyName: "course_id" }}
          sidebarData={[
            { label: "Name", value: "course_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Subjects" key="2">
        <PageContainer
          type="Subject"
          getApiName="dashboard/getSubjectsCovered"
          columns={subjectColumns}
          tableProps={{ rowKeyName: "subject_id" }}
          sidebarData={[
            { label: "Name", value: "subject_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Grades" key="3">
        <PageContainer
          type="Grade"
          getApiName="dopamine/getgradeList"
          columns={gradeColumns}
          tableProps={{ rowKeyName: "grade_id" }}
          sidebarData={[
            { label: "Name", value: "grade_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="No of Classes" key="4">
        <PageContainer
          type="No of Classes"
          getApiName="dopamine/getclassesList"
          columns={classColumns}
          tableProps={{ rowKeyName: "number_of_class_id" }}
          sidebarData={[
            { label: "Name", value: "number_of_classes", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Teaching Formats" key="5">
        <PageContainer
          type="Teaching Format"
          getApiName="dopamine/getformatList"
          columns={formatColumns}
          tableProps={{ rowKeyName: "format_id" }}
          sidebarData={[
            { label: "Name", value: "format_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
    </TabContainer>
  );
}
