import { Tabs } from "antd";
import React from "react";

import TabContainer from "../../components/Tab";
import ImportPopup from "../../components/importPopup";
import PageContainer from "../../containers/Page";
import { StoreContext } from "../../helpers/StoreContext";

const curriculumColumns = [
  {
    title: "Name",
    key: "curriculum_name",
    dataIndex: "curriculum_name",
    sorter: true,
  },
];

const topicColumns = [
  {
    title: "Name",
    key: "topic_name",
    dataIndex: "topic_name",
    sorter: true,
  },
];

const subTopicColumns = [
  {
    title: "Name",
    key: "sub_topic_name",
    dataIndex: "sub_topic_name",
    sorter: true,
  },
];

const excelHeaders = [
  "class_per_week",
  "curriculum_covered",
  "topic",
  "sub_topic",
  "course_offered",
  "class_session",
  "grades",
  "company",
];

export default function Curriculum() {
  const { storeId: companyId } = React.useContext(StoreContext);
  const [importPopup, setImportPopup] = React.useState(false);
  const [reeerender, setReeerender] = React.useState(false);

  return (
    <TabContainer>
      <Tabs.TabPane tab="Curriculum" key="1">
        {importPopup && (
          <ImportPopup
            type="curriculum"
            onClose={(success) => {
              setImportPopup(false);
              if (success) setReeerender(true);
              setTimeout(() => setReeerender(false), 2000);
            }}
          />
        )}
        <PageContainer
          type="Curriculum"
          getApiName="dashboard/getCurriculumList"
          reeerender={reeerender}
          columns={curriculumColumns}
          tableProps={{
            rowKeyName: "curriculum_id",
            parseButton: companyId > 0 && "Import",
            parseHandler: () => setImportPopup(true),
            excelTemplate: { type: "Curriculum", data: excelHeaders },
          }}
          sidebarData={[
            { label: "Name", value: "curriculum_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Topics" key="2">
        <PageContainer
          type="Topic"
          getApiName="dashboard/getTopicList"
          columns={topicColumns}
          tableProps={{ rowKeyName: "topic_id" }}
          sidebarData={[
            { label: "Name", value: "topic_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Sub Topics" key="3">
        <PageContainer
          type="Sub Topic"
          getApiName="dashboard/getSubTopicList"
          columns={subTopicColumns}
          tableProps={{ rowKeyName: "sub_topic_id" }}
          sidebarData={[
            { label: "Name", value: "sub_topic_name", fullWidth: true },
          ]}
        />
      </Tabs.TabPane>
    </TabContainer>
  );
}
