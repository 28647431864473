import { Image } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";

const HomeGym = ({ write = 0 }) => {
  const total = 8;
  const [loading, setLoading] = useState(false);
  const [homeGymSetupList, setHomeGymSetupList] = useState([]);

  function extractGoogleDriveId(url) {
    if (url.includes("drive.google.com") && url.includes("id=")) {
      const urlParams = new URLSearchParams(url.split("?")[1]);
      const id = urlParams.get("id");
      return id;
    }
    return null; // Return null if it's not a valid Google Drive sharing link
  }
  const columns = [
    {
      title: "User Name",
      dataIndex: "Name",
      key: "Name",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      align: "left",

      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Preferred equipments",
      dataIndex: "Preferred equipments",
      key: "Preferred equipments",
      align: "left",
      width: 200,
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Available Space",
      dataIndex: "Your Available Space",
      key: "Your Available Space",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Delivery Pincode",
      dataIndex: "Delivery Pincode",
      key: "Delivery Pincode",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Space images",
      dataIndex: "Upload your space images to setup home gym.",
      key: "Upload your space images to setup home gym.",
      align: "left",
      width: 100,
      render: (text) =>
        text ? (
          <Image
            onClick={(e) => e.stopPropagation()}
            src={`https://drive.google.com/uc?id=${extractGoogleDriveId(text)}`}
            style={{
              borderRadius: "3px",
              width: 70,
              height: 70,
              objectFit: "contain",
            }}
            preview={true}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Budget Range",
      dataIndex: "Your Budget Range",
      key: "Your Budget Range",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },

    {
      title: "Additional Requirements",
      dataIndex: "Additional Requirements",
      key: "Additional Requirements",
      align: "left",
      width: 100,
      render: (text) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },

    {
      title: "Mobile Number",
      dataIndex: "Contact Number",
      key: "Contact Number",
      align: "left",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
    {
      title: "Date",
      align: "left",
      dataIndex: "Timestamp",
      key: "Timestamp",
      render: (text, object) => (
        <Text onClick={(e) => e.stopPropagation()}>{text || "-"}</Text>
      ),
    },
  ];

  useEffect(() => {
    getHGSList();
  }, []);

  const getHGSList = async () => {
    setLoading(true);
    const res = await fetch(
      "https://api.steinhq.com/v1/storages/651e75d761eb47055da1540d/Form%20responses%201"
    );
    const data = await res.json();
    if (res.status === 200) {
      setLoading(false);
      setHomeGymSetupList(data);
    }
  };

  return (
    <>
      <CardContainer hoverable={false}>
        <BreadCrumb title="Home Gym Setup" />
        <TableContainer
          rowKeyName="id"
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          data={homeGymSetupList}
          total={total}
          functionsHandler={getHGSList}
        />
      </CardContainer>
    </>
  );
};

export default HomeGym;
