import React, { useContext, useEffect, useState } from "react";

import Badge from "../../components/Badge";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import ImportPopup from "../../components/importPopup";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";
import { StoreContext } from "../../helpers/StoreContext";
import CoursePriceSidebar from "./CoursePriceSidebar";

const bookingAllColumns = [
  {
    title: "Company",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Name",
    dataIndex: "course_name",
    key: "course_name",
  },
  {
    title: "Session Type",
    dataIndex: "session_type",
    key: "session_type",
    align: "center",
    render: (text) => <Badge sessionType={text} />,
    sorter: true,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "center",
    sorter: true,
    render: (text) => <div style={{ textAlign: "right" }}>₹{text}</div>,
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    align: "center",
    sorter: true,
    render: (text) => DateFormat(text),
  },
];

const bookingMainColumns = [
  {
    title: "Name",
    dataIndex: "course_name",
    key: "course_name",
  },
  {
    title: "Session Type",
    dataIndex: "session_type",
    key: "session_type",
    align: "center",
    render: (text) => <Badge sessionType={text} />,
    sorter: true,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "center",
    sorter: true,
    render: (text) => <div style={{ textAlign: "right" }}>₹{text}</div>,
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    align: "center",
    sorter: true,
    render: (text) => DateFormat(text),
  },
];

const excelHeaders = [
  "grade",
  "format",
  "no_of_classes",
  "course_name",
  "price",
  "duration",
  "class_per_week",
  "teaching_session",
  "is_recorded_video",
  "usp",
  "refund",
  "finance_option",
  "certification",
  "revision_classes",
  "app_available",
  "coding_platform",
  "teacher_choice",
  "schedule_facility",
  "quizzes",
];

export default function CoursePrice() {
  const { storeId: companyId } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [list, setList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [selected, setSelected] = useState(0);
  const [importPopup, setImportPopup] = useState(false);

  useEffect(() => getList(), []);

  const getList = async (payload = {}) => {
    setLoading(true);
    if (companyId) payload = { ...payload, company_id: companyId, role_id: 4 };
    else payload = { ...payload, role_id: 1 };
    const res = await getApi(payload, "getcoursePriceList", "dashboard/");
    if (res.data) {
      setList(res.data);
      setTotal(res.meta?.total);
      setLoading(false);
    } else setLoading(false);
  };

  return (
    <>
      {sidebar && (
        <CoursePriceSidebar data={selected} onClose={() => setSidebar(false)} />
      )}
      {importPopup && (
        <ImportPopup
          type="price"
          onClose={(success) => {
            setImportPopup(false);
            success && getList();
          }}
        />
      )}
      <CardContainer bordered={false} hoverable={false}>
        <BreadCrumb title="Course Price Management" />
        <TableContainer
          loading={loading}
          total={total}
          columns={companyId ? bookingMainColumns : bookingAllColumns}
          data={list}
          searchFor="Course Name"
          parseButton={companyId > 0 && "Import"}
          parseHandler={() => setImportPopup(true)}
          functionsHandler={getList}
          rowClickHandler={(record) => {
            setSidebar(true);
            setSelected(record);
          }}
          rowKeyName="company_price_id"
          excelTemplate={{ type: "Parameter", data: excelHeaders }}
        />
      </CardContainer>
    </>
  );
}
