import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import Dashboard from "../../lotties/dashboard.json";
import "./index.css";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import Amazon from "../../assets/Amazon.svg";
import Flipkart from "../../assets/Flipkart.svg";
import Rupee from "../../assets/Rupee.svg";
import Tata from "../../assets/Tata.svg";
import LOGO from "../../assets/logo New.svg";
import Website from "../../assets/website.svg";

import { ReloadOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { getApi } from "../../helpers/ApiFunctions";

const Metrics = () => {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [userCount, setUsersCount] = useState({});
  const [movedMetrics, setMovedMetrics] = useState({});
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    getTvMetrics();
    getUsersCount();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      getUsersCount();
    }, 300000);
    return () => clearInterval(timer);
  }, [userCount]);

  const getTvMetrics = async () => {
    setLoading(true);
    const res = await fetch(
      "https://api.steinhq.com/v1/storages/64f097b5d27cdd09f012ea17/Sheet1"
    );
    const data = await res.json();
    if (res.status === 200) {
      setLoading(false);
      Object.keys(data[0])
        .filter(
          (key) => key === "Daily" || key === "Monthly" || key === "Yearly"
        )
        .reduce((result, key) => {
          result[key] = data[0][key];
          return result;
        }, {});

      Object.keys(data[0])
        .filter(
          (key) => key !== "Daily" && key !== "Monthly" && key !== "Yearly"
        )
        .reduce((result, key) => {
          result[key] = data[0][key];
          return result;
        }, {});
      setMetrics(data[0]);
    }
  };
  const getUsersCount = async (payload = {}) => {
    const res = await getApi({ ...payload }, "getUserCounts", "dashboard/");
    if (res.success) {
      let count = Object.keys(res.data[0]).reduce(
        (acc, curr) => res.data[0][curr] + acc,
        0
      );
      setUsersCount(res.data[0]?.total_people_moved);
      setMovedMetrics(res.data[0]);
    }
  };

  let currentdate = new Date();
  console.log("currentdate".currentdate);

  const dopamineMetrics = [
    {
      key: "total_doses_currentmonth",
      title: `Doses in ${dayjs(currentdate).format("MMM YYYY")}`,
    },
    { key: "users_moved_yesterday", title: "Users Moved yesterday" },
    {
      key: "users_moved_currentmonth",
      title: `Users Moved ${dayjs(currentdate).format("MMM YYYY")}`,
    },
  ];

  return loading ? (
    <Lottie
      style={{
        width: "50%",

        marginTop: "10%",
        margin: "auto",
      }}
      animationData={Dashboard}
    />
  ) : (
    <div style={{ backgroundColor: "white", padding: "2% 4% 4% 4%" }}>
      <AutoplaySlider
        animation={["foldOutAnimation"]}
        play={true}
        interval={10000}
        style={{ height: "100vh" }}
        bullets={true}
        organicArrows={false}
        buttons={false}
        infinite={true}
      >
        <div
          id="1"
          style={{
            margin: "auto",
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              alt="main-logo"
              src={LOGO}
              style={{
                marginLeft: "auto",

                width: "30%",
                marginRight: 20,
              }}
            />
            <ReloadOutlined
              onClick={() => {
                getTvMetrics();
                getUsersCount();
              }}
              style={{ fontSize: "60px", paddingTop: 12, color: "#FFDF00" }}
            />
          </div>
          <h2 style={{ textAlign: "right", margin: 0, fontSize: "40px" }}>
            Product Metrics
          </h2>
          <Row gutter={[50, 0]} style={{ marginBottom: 42 }}>
            <Col span={8}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img alt="rupee-logo" src={Rupee} className="sale-logo" />
                      <p className="sale-title">
                        Offline
                        <span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Offline_Daily}</p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img
                        alt="sale-logo"
                        src={Website}
                        className="sale-logo"
                      />
                      <p className="sale-title">
                        Website
                        <span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Website_Daily}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <div className="sales-count" style={{ marginTop: 65 }}>
                <p style={{ margin: 0 }}>Yesterday</p>
                <p style={{ fontWeight: 500, fontSize: 52 }}>
                  Total Sales
                  <span style={{ fontSize: 32 }}>(INR)</span>
                </p>
                <p
                  className="number"
                  style={{
                    fontWeight: 700,
                    fontSize: 100,
                  }}
                >
                  {metrics?.Daily}
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[50, 0]}>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="amazon-logo" src={Amazon} className="sale-logo" />
                  <p className="sale-title">
                    Amazon<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Amazon_Daily}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Flipkart-logo" src={Flipkart} />
                  <p className="sale-title">
                    Flipkart<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Flipkart_Daily}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Tata-logo" src={Tata} className="sale-logo" />
                  <p className="sale-title">
                    Tata Cliq<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Tatacliq_Daily}</p>
              </div>
            </Col>
          </Row>
        </div>

        <div
          id="2"
          style={{
            margin: "auto",
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              alt="main-logo"
              src={LOGO}
              style={{
                marginLeft: "auto",
                width: "30%",
                marginRight: 20,
              }}
            />
            <ReloadOutlined
              onClick={() => {
                getTvMetrics();
                getUsersCount();
              }}
              style={{ fontSize: "60px", paddingTop: 12, color: "#FFDF00" }}
            />
          </div>
          <h2 style={{ textAlign: "right", margin: 0, fontSize: "40px" }}>
            Product Metrics
          </h2>
          <Row gutter={[50, 0]} style={{ marginBottom: 42 }}>
            <Col span={8}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img alt="rupee-logo" src={Rupee} className="sale-logo" />
                      <p className="sale-title">
                        Offline<span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Offline_Monthly}</p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img
                        alt="sale-logo"
                        src={Website}
                        className="sale-logo"
                      />
                      <p className="sale-title">
                        Website<span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Website_Monthly}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <div className="sales-count" style={{ marginTop: 65 }}>
                <p style={{ margin: 0 }}>This Month</p>
                <p style={{ fontWeight: 500, fontSize: 52 }}>
                  Total Sales
                  <span style={{ fontSize: 32 }}>(INR)</span>
                </p>
                <p
                  className="number"
                  style={{
                    fontWeight: 700,
                    fontSize: 100,
                  }}
                >
                  {metrics?.Monthly}
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[50, 0]}>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Amazon-logo" src={Amazon} className="sale-logo" />
                  <p className="sale-title">
                    Amazon<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Amazon_Monthly}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img
                    alt="Flipkart-logo"
                    src={Flipkart}
                    className="sale-logo"
                  />
                  <p className="sale-title">
                    Flipkart<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Flipkart_Monthly}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Tata-logo" src={Tata} className="sale-logo" />
                  <p className="sale-title">
                    Tata Cliq<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Tatacliq_Monthly}</p>
              </div>
            </Col>
          </Row>
        </div>

        <div
          id="3"
          style={{
            margin: "auto",
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              alt="main-logo"
              src={LOGO}
              style={{
                marginLeft: "auto",
                width: "30%",
                marginRight: 20,
              }}
            />
            <ReloadOutlined
              onClick={() => {
                getTvMetrics();
                getUsersCount();
              }}
              style={{ fontSize: "60px", paddingTop: 12, color: "#FFDF00" }}
            />
          </div>
          <h2 style={{ textAlign: "right", margin: 0, fontSize: "40px" }}>
            Product Metrics
          </h2>
          <Row gutter={[50, 0]} style={{ marginBottom: 42 }}>
            <Col span={8}>
              <Row gutter={[0, 50]}>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img alt="rupee-logo" src={Rupee} className="sale-logo" />
                      <p className="sale-title">
                        Offline<span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Offline_Yearly}</p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="sales-container">
                    <div className="sales-header">
                      <img
                        alt="sale-logo"
                        src={Website}
                        className="sale-logo"
                      />
                      <p className="sale-title">
                        Website<span>(INR)</span>
                      </p>
                    </div>
                    <p className="sale-number">{metrics?.Website_Yearly}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              <div className="sales-count" style={{ marginTop: 65 }}>
                <p style={{ margin: 0 }}>This Year</p>
                <p style={{ fontWeight: 500, fontSize: 52 }}>
                  Total Sales
                  <span style={{ fontSize: 32 }}>(INR)</span>
                </p>
                <p
                  className="number"
                  style={{
                    fontWeight: 700,
                    fontSize: 100,
                  }}
                >
                  {metrics?.Yearly}
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[50, 0]}>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Amazon-logo" src={Amazon} className="sale-logo" />
                  <p className="sale-title">
                    Amazon<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Amazon_Yearly}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img
                    alt="Flipkart-logo"
                    src={Flipkart}
                    className="sale-logo"
                  />
                  <p className="sale-title">
                    Flipkart<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Flipkart_Yearly}</p>
              </div>
            </Col>
            <Col span={8}>
              <div className="sales-container">
                <div className="sales-header">
                  <img alt="Tata-logo" src={Tata} className="sale-logo" />
                  <p className="sale-title">
                    Tata Cliq<span>(INR)</span>
                  </p>
                </div>
                <p className="sale-number">{metrics?.Tatacliq_Yearly}</p>
              </div>
            </Col>
          </Row>
        </div>

        <div
          id="4"
          style={{
            margin: "auto",
            backgroundColor: "white",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <img
              alt="main-logo"
              src={LOGO}
              style={{
                marginLeft: "auto",
                marginRight: 20,
                width: "30%",
              }}
            />
            <ReloadOutlined
              onClick={() => {
                getTvMetrics();
                getUsersCount();
              }}
              style={{ fontSize: "60px", paddingTop: 12, color: "#FFDF00" }}
            />
          </div>
          <h2 style={{ textAlign: "right", margin: 0, fontSize: "40px" }}>
            Platform Metrics
          </h2>
          <div style={{ textAlign: "right" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: 120,
              }}
            >
              <p style={{ margin: 0, fontSize: 72, fontWeight: 600 }}>
                Total People Moved
              </p>
            </div>
            <p
              className="number"
              style={{
                fontWeight: 700,
                fontSize: 100,
              }}
            >
              {userCount}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "left",
              }}
            >
              {dopamineMetrics?.map(({ title, key }) => (
                <div
                  key={key}
                  style={{
                    border: "1px solid #D6D6D6",
                    borderRadius: 12,
                    padding: "2% 3%",
                  }}
                >
                  <p style={{ margin: 0, fontSize: 30, fontWeight: 600 }}>
                    {title}
                  </p>
                  <p
                    className="sale-number"
                    style={{
                      fontWeight: 600,
                      fontSize: 52,
                    }}
                  >
                    {movedMetrics[key] || 0}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </AutoplaySlider>
    </div>
  );
};

export default Metrics;
