import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useEffect, useState } from "react";

export const ImageUpload = ({
  imageFile = null,
  setImageFile = () => {},
  selected = {},
  setSelected = () => {},
  selectedIcon = "",
  setSelectedIcon,
}) => {
  const [icon, setIcon] = useState(selectedIcon);

  useEffect(() => {
    if (selectedIcon?.length) {
      setIcon(selectedIcon);
    } else {
      setIcon("");
    }
  }, [selectedIcon]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <Upload
        onPreview={onPreview}
        accept="image/*"
        listType="picture-card"
        className="logo-uploader"
        customRequest={({ file }) => {
          setImageFile(file);
        }}
        fileList={
          imageFile || selectedIcon
            ? [
                {
                  url: selectedIcon,
                  uid: "1",
                  status: "done",
                  name: "image.png",
                },
              ]
            : []
        }
        onRemove={() => {
          setImageFile(null);
          setSelectedIcon("");
          setIcon("");
        }}
        maxCount={1}
        multiple={false}
        style={{ height: "150px", width: "150px" }}
      >
        {!imageFile && !icon && (
          <div>
            <PlusOutlined />
          </div>
        )}
      </Upload>
    </>
  );
};
