import { HomeOutlined } from "@ant-design/icons";
import { Avatar, Col, Image, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

export default function Centre({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);

  const columns = [
    {
      title: "Profile",
      dataIndex: "user_profile_image",
      key: "user_profile_image",
      align: "center",
      render: (text) => (
        <Image
          onClick={(e) => e.stopPropagation()}
          src={text}
          style={{
            borderRadius: "0.5rem",
            height: "24px",
            width: "24px",
          }}
          placeholder={
            <Avatar
              icon={<HomeOutlined />}
              shape="square"
              style={{ height: "24px", width: "24px" }}
            />
          }
          preview={text ? true : false}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
      render: (text, object) => (
        <div style={{ width: "max-content" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (text, object) => (
        <Switch
          checkedChildren={text === 0 ? "NO" : "YES"}
          unCheckedChildren={text === 0 ? "NO" : "YES"}
          size="small"
          checked={text ? true : false}
          onChange={() => {
            setPopup(true);
            setSelected(object);
          }}
        />
      ),
    },
    {
      title: "Joining Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) => DateFormat(text),
      sorter: true,
    },
  ];

  useEffect(() => getUsersList(), []);

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, role_id: 2, admin_type_id: 3 },
      "getUsersList",
      "user/"
    );
    if (res.data) {
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const updateStatusHandler = async () => {
    setSelected({ ...selected, loading: true });
    let is_active = 1;
    let msg = "Centre Activated";
    if (selected.is_active) {
      is_active = 0;
      msg = "Centre Deactivated";
    }
    const res = await saveApi(
      { user_id: selected.id, is_active: is_active },
      msg,
      "deactivateUser",
      "user/"
    );
    if (res) {
      setUserList(
        userList.map((user) => {
          if (user.id === selected.id) {
            return (user = { ...user, is_active: is_active });
          }
          return user;
        })
      );
    }
    setSelected({});
    setPopup(false);
  };

  return (
    <>
      {popup && (
        <Popup
          saveText={selected.is_active ? "Yes, Deactivate" : "Yes, Active"}
          title="Update Centre Active Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure to{" "}
            <b>{selected.is_active ? "Deactivated" : "active"} </b>
            the centre <b>{selected.first_name}</b>?
          </Text>
        </Popup>
      )}

      {selected.centre_id && !popup && (
        <Sidebar
          loading={selected.loading}
          title="Centre Details"
          onClose={() => {
            setSelected({});
          }}
          visible
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row>
                    <Col span={8}>
                      <Avatar
                        icon={<HomeOutlined />}
                        size={100}
                        alt=""
                        src={selected.user_profile_image}
                      />
                    </Col>
                    <Col span={16}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      <Text>
                        {selected?.first_name && selected?.last_name
                          ? selected?.first_name + " " + selected?.last_name
                          : selected?.first_name}
                      </Text>
                      <br style={{ marginBottom: "1rem" }} />
                      <Text type="label" style={{ fontSize: 11 }}>
                        Joining Date
                      </Text>
                      <Text>{DateFormat(selected.created_at)}</Text>
                    </Col>
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
              { label: "Mobile Number", value: selected?.mobile },
              { label: "Email", value: selected?.email },
              { label: "Type", value: selected?.admin_type_name },
              { label: "Invited by", value: selected.invited_by },
              {
                label: "Address",
                value: (
                  <>
                    {selected?.address
                      ? selected?.address.map((ele, i) => (
                          <>
                            <li key={i}>
                              {ele.houseInfo +
                                ", " +
                                ele.streetInfo +
                                ", (Near) " +
                                ele.landmark +
                                ", " +
                                ele.city +
                                ", " +
                                ele.state +
                                ". PIN-" +
                                ele.pin}
                              <br />
                              {"Address type : " + ele.type}
                            </li>
                            <hr></hr>
                          </>
                        ))
                      : "-"}
                  </>
                ),
              },
              {
                label: "Services",
                value: (
                  <ul type="1">
                    {selected?.category.length
                      ? selected?.category.map((ele, i) => (
                          <>
                            <li>{ele.name}</li>
                          </>
                        ))
                      : "-"}
                  </ul>
                ),
              },
            ]}
          />
        </Sidebar>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="Centres" />
        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
          }}
          data={userList}
          total={total}
          dateRangePicker="true"
          searchFor="Name, Mobile No."
          functionsHandler={getUsersList}
        />
      </CardContainer>
    </>
  );
}
