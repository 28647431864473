import {
  CaretDownFilled,
  ExportOutlined,
  ImportOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import EMPTYSTATE from "../../assets/Empty State.svg";
import Popup from "../../components/Popup";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";
import "./index.css";

export default function TableContainer({
  setisCreate,
  setIsSidebarOpen,
  userCreation,
  setPageNumber,
  marketPlaces,
  chipId,
  setChipId,
  usersCount = () => {},
  multiple,
  pageSizeHandler = (page, size) => {},
  scroll,
  sortSize,
  columns = [],
  data = [],
  loading = false,
  total = 8,
  waitlistedTotal,
  nonWaitlistedTotal,
  rowKeyName = "id",
  functionsHandler = () => {},
  rowClickHandler = () => {},
  searchFor = "",
  dateRangePicker = false,
  addButton = false,
  exportButton = true,
  addHandler = () => {},
  parseButton = "",
  parseHandler = () => {},
  parsing = false,
  dropdownFilterFor = "Category",
  tagFilterOptions = [],
  dropdownFilterOptions = [],
  dropdownSearchHandler = () => {},
  children,
  filter = "",
  size,
  role_id,
  batch = "",
  setSelectedRowKeys,
  selectedRowKeys,
  setStatus,
  setWaitListType,
  isModalVisible,
  setIsModalVisible,
  isEdit,
  setisEdit,
  selected,
  setPaginationData = () => {},
  setSelected = () => {},
  TableSize,
  batches,
  setBatch,
  title,
  setIsAdd,
  status,
  sort,

  ...otherProps
}) {
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dates, setDates] = useState([]);
  const [tag, setTag] = useState("");
  const [dropdown, setDropdown] = useState("");
  const [payload, setPayload] = useState({ size: 10, keyword: "" });
  const [psize, setPsize] = useState(size);
  const [popup, setPopup] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [user_type, setUser_type] = useState();

  const [pageNumberScrape, setPageNumberScrape] = useState(1);
  const [url, setUrl] = useState("");
  const { Search } = Input;

  console.log("dates", dates);

  useEffect(() => {
    setPaginationData((prev) => ({ ...prev, page: page, size: psize }));
  }, [page, size]);

  const paginationHandler = (pageNo, pageSize) => {
    setPage(pageNo - 1);
    pageSizeHandler(pageNo - 1, pageSize);
    setPsize(pageSize);
    let tempPayload = {
      ...payload,
      page: pageNo - 1,
      size: pageSize,
      sort: sort === "asc" ? "desc" : sort === "desc" ? "asc" : null,
    };

    functionsHandler(tempPayload);
    setPayload(tempPayload);
  };

  const sorterHandler = (sort, extra) => {
    let sortObj = {};
    if (sort.field && extra.action === "sort") {
      setPage(0);
      pageSizeHandler(0, payload.size);
      if (sort.order === "ascend")
        sortObj = { field: sort.field, order_by: "asc" };
      else if (sort.order === "descend")
        sortObj = { field: sort.field, order_by: "desc" };
      let tempPayload = { ...payload, sort: sortObj, page: 0 };
      functionsHandler(tempPayload);
      setPayload(tempPayload);
    }
  };

  const filterHandler = (filteredArr, pagination) => {
    setUser_type(filteredArr?.user_type);
    setPage(pagination.current - 1);
    pageSizeHandler(pagination.current - 1, pagination.pageSize);
    setPsize(pagination.pageSize);
    if (filteredArr.profile_verified || filteredArr.is_waitlisted) {
      functionsHandler(
        filteredArr?.is_waitlisted !== null &&
          filteredArr?.profile_verified !== null
          ? {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
              is_verified:
                filteredArr?.profile_verified &&
                filteredArr?.profile_verified[0],
              is_waitlisted:
                filteredArr?.is_waitlisted && filteredArr?.is_waitlisted[0],
            }
          : filteredArr?.is_waitlisted === null &&
            filteredArr?.profile_verified !== null
          ? {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
              is_verified:
                filteredArr?.profile_verified &&
                filteredArr?.profile_verified[0],
            }
          : filteredArr?.is_waitlisted !== null &&
            filteredArr?.profile_verified === null
          ? {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
              is_waitlisted:
                filteredArr?.is_waitlisted && filteredArr?.is_waitlisted[0],
            }
          : {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
            }
      );
    } else if (filteredArr.user_type) {
      functionsHandler(
        filteredArr.user_type !== null
          ? {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,

              user_type: filteredArr?.user_type,
            }
          : {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
            }
      );
    } else if (filteredArr.is_active) {
      functionsHandler(
        filteredArr.is_active !== null
          ? filteredArr.is_active.length > 1
            ? {
                ...payload,
                page: pagination.current - 1,
                size: pagination.pageSize,
              }
            : {
                ...payload,
                page: pagination.current - 1,
                size: pagination.pageSize,

                is_active:
                  filteredArr.is_active[filteredArr.is_active.length - 1],
              }
          : {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
            }
      );
    } else if (filteredArr.status_details) {
      functionsHandler(
        filteredArr.status_details === null
          ? {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
            }
          : {
              ...payload,
              page: pagination.current - 1,
              size: pagination.pageSize,
              status: filteredArr.status_details,
            }
      );
    } else {
      paginationHandler(pagination.current, pagination.pageSize);
    }
  };

  const searchFilterHandler = (keyword) => {
    if (keyword !== payload.keyword) {
      setPage(0);
      pageSizeHandler(0, payload.size);
      setTag("");
      setDates([]);
      setKeyword(keyword);
      setDropdown("");
      let tempPayload = { keyword, sort: payload.sort, size: payload.size };
      functionsHandler(tempPayload);
      setPayload(tempPayload);
    }
  };

  // dates, dateStrings, info
  const dateRangeFilterHandler = (dates) => {
    setPage(0);
    setKeyword("");
    setTag("");
    setDropdown("");
    let tempPayload = {};
    if (dates) {
      tempPayload = {
        start_date: DateFormat(dates[0], "YYYY-MM-DD"),
        end_date: DateFormat(dates[1], "YYYY-MM-DD"),
        size: payload.size,
        sort: payload.sort,
      };
      setDates(dates);
    } else setDates([]);

    if (filter === "userList") {
      usersCount(tempPayload);
    }
    functionsHandler(tempPayload);
    setPayload(tempPayload);
  };

  const tagFilterHandler = (event) => {
    let tempPayload = { sort: payload.sort, size: payload.size };
    setPage(0);
    setDates([]);
    setKeyword("");
    setDropdown("");
    let tagSel = tagFilterOptions.find(
      (tag) => tag.label === event.target.textContent
    );
    if (tagSel && tagSel.value !== tag) {
      setTag(tagSel.value);
      tempPayload = { filter: tagSel.value, ...tempPayload };
      functionsHandler(tempPayload);
      setPayload(tempPayload);
    } else {
      setTag("");
      functionsHandler(tempPayload);
      setPayload(tempPayload);
    }
  };

  const dropdownFilterHandler = (selectedDropdown) => {
    let tempPayload = { size: payload.size, sort: payload.sort };
    if (selectedDropdown) {
      setPage(0);
      setKeyword("");
      setDates([]);
      setTag("");
      if (dropdownFilterFor === "Product")
        tempPayload = { ...tempPayload, product_id: selectedDropdown.key };
      else tempPayload = { ...tempPayload, category_id: selectedDropdown.key };
    } else setDropdown("");
    functionsHandler(tempPayload);
    setPayload(tempPayload);
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => dropdownSearchHandler(nextValue), 1200),
    []
  );

  const flattenObj = (ob) => {
    let result = {};

    for (const i in ob) {
      if (Array.isArray(ob[i])) {
        for (let j = 0; j < ob[i].length; j++) {
          const temp = flattenObj(ob[i][j]);
          for (const k in temp) {
            result[i + "-" + j + "-" + k] = temp[k];
          }
        }
      } else if (typeof ob[i] === "object" && ob[i] !== null) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          result[i + "-" + j] = temp[j];
        }
      } else {
        result[i] = ob[i];
      }
    }
    return result;
  };

  parseHandler = async (excelTemplate = {}, payload = {}) => {
    setLoadingExport(true);
    message.success("Exporting your data. Please wait...");
    loading = true;
    let url;
    var route;
    let filename = "dopamine.xlsx";
    let type;
    let is_fetch;
    switch (filter) {
      case "survey":
        url = "exportQuestionnaireData";
        filename = filter + "-list.xlsx";
        break;
      case "dripCampaign":
        url = "dripCampaignList";
        filename = filter + "-list.xlsx";
        break;
      case "userList":
        route = "user/";
        role_id = 3;
        url = "getUsersList";
        filename = filter + ".xlsx";
        break;
      case "serviceList":
        route = "dashboard/";
        url = "getAllServices";
        filename = filter + ".xlsx";
        break;
      case "bookingList":
        route = "dashboard/";
        url = "getAllBookingsList";
        filename = filter + ".xlsx";
        break;
      case "warrantyList":
        route = "dashboard/";
        url = "getWarrantyDetails";
        type = "export";
        filename = filter + ".xlsx";
        break;
      case "invoice":
        route = "dashboard/";
        url = "userProdInvoice";
        type = "export";
        filename = filter + ".xlsx";
        is_fetch = 1;
        break;
      case "rentalHgs":
        route = "dashboard/";
        url = "getShopifyLeads";
        type = "export";
        filename = filter + ".xlsx";
        is_fetch = 1;
        break;
      case "getCubeLeadsData":
        route = "dashboard/";
        url = "getCubeLeadsData";
        type = "export";
        filename = filter + ".xlsx";
        is_fetch = 1;
        break;
      default:
        console.log(`No filter has been specified`);
    }
    const res = await getApi(
      dates.length
        ? {
            ...payload,
            batch,
            role_id: role_id || "2",
            page: page,
            size: psize || 10,
            type: type,
            is_fetch,
            start_date: DateFormat(dates[0], "YYYY-MM-DD"),
            end_date: DateFormat(dates[1], "YYYY-MM-DD"),
          }
        : {
            ...payload,
            batch,
            role_id: role_id || "2",
            page: page,
            size: psize || 10,
            type: type,
            is_fetch,
          },
      url,
      route || "dashboard/"
    );

    if (res.success) {
      setLoadingExport(false);

      const rows = res?.data?.map((row) => flattenObj(row));

      const dataExcel = res?.dataSet?.map((data, i) => {
        const questionsArray = res?.headerData.map(
          (obj) => Object.values(obj)[0]
        );

        const resultObject = {};

        questionsArray.forEach((question, index) => {
          resultObject[question] = data[index];
        });

        return resultObject;
      });

      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_json(ws, filter === "survey" ? dataExcel : rows, {
        origin: "A1",
        skipHeader: false,
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      XLSX.writeFile(wb, filename);
    } else {
      message.error("Exporting failed!");
    }
    setPopup(false);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows, e) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChange = (value) => {
    setStatus({ action: value });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSearch = (url, pg) => {
    functionsHandler({ url: url, page: pg });
  };
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: title ? "space-between" : "flex-end",
          alignItems: "baseline",
          marginBottom: 15,
        }}
        span={24}
      >
        {
          /* User creation */
          userCreation && (
            <>
              <Button
                onClick={() => {
                  setIsSidebarOpen(true);
                  setisCreate(true);
                }}
                type="primary"
              >{`Create an ${userCreation}`}</Button>
            </>
          )
        }

        {title === "marketPlaceReviews" && (
          <>
            <Space
              size={[8, 8]}
              wrap
              style={{
                marginTop: "2rem",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              {marketPlaces?.map(({ name, logo, id, isActive }) => (
                <Tag
                  id={id}
                  key={id}
                  onClick={() => chipId === id && setChipId(id)}
                  className={`space-reviews ${
                    isActive ? chipId === id && `active` : `disabled`
                  }`}
                  icon={<img className="tag-img" src={logo} alt="amazonLogo" />}
                >
                  {name}
                </Tag>
              ))}
            </Space>

            <Search
              loading={loading}
              size="large"
              onChange={(e) => setUrl(e.target.value)}
              style={{ width: "50%" }}
              placeholder="https://www.amazon.in/example/allReviews"
              onSearch={(val) =>
                url?.length > 0 && onSearch(val, pageNumberScrape)
              }
              enterButton
            />
          </>
        )}
        {title && <h2 style={title.style}>{title.title}</h2>}
        {filter === "dripCampaign" && (
          <Dropdown
            trigger={["click", "hover"]}
            placement="bottom"
            overlay={
              <Menu style={{ marginTop: -10 }}>
                {batches.map((ele) => (
                  <Menu.Item onClick={() => setBatch(ele)} key={`batch${ele}`}>
                    {`Batch ${ele}`}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div
              className="dropdwn-mnu"
              style={{
                marginRight: 10,
                marginLeft: 5,
              }}
            >
              Select Batch
              <CaretDownFilled style={{ fontSize: 16, marginLeft: 2 }} />
            </div>
          </Dropdown>
        )}
        {(filter === "productInventory" || filter === "expertise") && (
          <div className="btn-div">
            <Button
              onClick={() => {
                filter === "expertise" ? showModal() : setIsAdd(true);
              }}
              danger
            >
              {filter === "productInventory"
                ? "Add Product"
                : "Add Fitness Category"}
            </Button>
          </div>
        )}
        {filter === "challenge" && (
          <div className="btn-div">
            <Button
              onClick={() => {
                setIsAdd(true);
              }}
              danger
            >
              Add Challenge
            </Button>
          </div>
        )}
        {(filter === "experts" ||
          filter === "customers" ||
          filter === "invoice") && (
          <>
            <Select
              type="primary"
              className={multiple && selectedRowKeys?.length > 0 && "actions"}
              disabled={!(multiple && selectedRowKeys?.length > 0)}
              placeholder="Select an action"
              style={{
                width: 165,
                marginTop: 10,
                marginRight: "auto",
              }}
              value={status?.action}
              onChange={handleChange}
              options={
                filter === "customers"
                  ? [
                      {
                        value: "un-waitlist",
                        label: "Approve Waitlist",
                      },

                      {
                        value: "waitlist",
                        label: "Add to Waitlist",
                      },
                    ]
                  : filter === "invoice"
                  ? [
                      {
                        value: "Not Claimed",
                        label: "Not Claimed",
                      },
                      {
                        value: "Processing",
                        label: "Processing Warranty",
                      },
                      {
                        value: "In Warranty",
                        label: "In Warranty",
                      },
                      {
                        value: "Expired",
                        label: "Expired",
                      },
                      {
                        value: "Upload Invoice",
                        label: "Upload Invoice",
                      },
                    ]
                  : [
                      {
                        value: "un-waitlist",
                        label: "Approve Waitlist",
                      },
                      {
                        value: "badge-verify",
                        label: "Verify Profile",
                      },
                      {
                        value: "waitlist",
                        label: "Add to Waitlist",
                      },
                      {
                        value: "badge-unverify",
                        label: "Unverify Profile",
                      },
                    ]
              }
            />
          </>
        )}

        {popup && (
          <Popup
            title={filter.toUpperCase()}
            style={{ marginTop: "0px !important" }}
            cancelHandler={() => setPopup(false)}
            saveHandler={() => {
              user_type ? parseHandler({}, { user_type }) : parseHandler();
            }}
            saveText="Yes"
            cancelText="No"
            loading={loadingExport}
            visible
          >
            <h3>Confirm your Download ?</h3>
          </Popup>
        )}

        {dropdownFilterOptions.length > 0 && (
          <AutoComplete
            allowClear
            showArrow
            style={{ width: 225, marginTop: 10 }}
            options={dropdownFilterOptions}
            onSelect={(v, vObj) => dropdownFilterHandler(vObj)}
            onSearch={(value) => value.length > 1 && debouncedSearch(value)}
            placeholder={"Select " + dropdownFilterFor}
            value={dropdown}
            onChange={(v) => {
              if (!v && (payload.category_id || payload.product_id)) {
                dropdownFilterHandler(null);
              } else setDropdown(v);
            }}
          />
        )}
        {dateRangePicker && (
          <DatePicker.RangePicker
            allowClear
            style={{ width: 255, marginLeft: 20, marginTop: 10 }}
            format="DD-MM-YYYY"
            value={dates}
            onChange={dateRangeFilterHandler}
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
          />
        )}
        {searchFor && (
          <Input.Search
            style={{ width: 255, marginLeft: 20, marginTop: 10 }}
            placeholder={"Search " + searchFor}
            allowClear
            enterButton
            loading={loading}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={searchFilterHandler}
          />
        )}

        {addButton && (
          <Button
            style={{ marginLeft: 20, marginTop: 10 }}
            onClick={addHandler}
            type="primary"
            loading={loading}
            icon={<PlusSquareOutlined />}
          >
            Add
          </Button>
        )}

        {parseButton && parseButton.length > 1 && (
          <Button
            loading={parsing || loading}
            style={{
              marginLeft: 20,
              textTransform: "capitalize",
              marginTop: 10,
            }}
            onClick={() => setPopup(true)}
            type="primary"
            icon={
              parseButton === "export" ? (
                <ExportOutlined />
              ) : parseButton === "import" ? (
                <ImportOutlined />
              ) : (
                <PlusSquareOutlined />
              )
            }
          >
            {parseButton}
          </Button>
        )}
      </Row>
      {tagFilterOptions.length > 0 && (
        <Col span={24}>
          <Button
            style={{ marginTop: 15, marginRight: 10 }}
            onClick={tagFilterHandler}
            type={!tag && "primary"}
          >
            All
          </Button>
          {tagFilterOptions.map((tg) => (
            <Button
              type={tg.value === tag && "primary"}
              onClick={tagFilterHandler}
              style={{ marginRight: 10, marginTop: 15 }}
              key={tg.value}
            >
              {tg.label}
            </Button>
          ))}
        </Col>
      )}

      {title === "marketPlaceReviews" && data?.length < 1 ? (
        <>
          <img
            src={EMPTYSTATE}
            style={{ display: "block", margin: "auto", width: "40%" }}
          />
          <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
            Want to fetch reviews?
          </h2>
          <p style={{ textAlign: "center", color: "#cccdcc" }}>
            Simply paste the link above
          </p>
        </>
      ) : (
        <Table
          scroll={{ x: scroll || "auto" }}
          size={TableSize || "small"}
          className="tbl"
          rowClassName="rww"
          rowKey={(record) => (loading ? record.key : record[rowKeyName])}
          bordered
          rowSelection={multiple && rowSelection}
          columns={
            loading
              ? [
                  ...columns.map((column) => ({
                    ...column,
                    render: () => (
                      <Skeleton
                        key={column.key}
                        round
                        active
                        paragraph={false}
                      />
                    ),
                  })),
                ]
              : [...columns]
          }
          dataSource={
            loading
              ? [...Array(6)].map((_, index) => ({ key: `key${index}` }))
              : data
          }
          pagination={{
            style: { marginBottom: -5 },
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 1000, 5000],

            current: page + 1,
            hideOnSinglePage: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={(paginate, filter, sort, extra) => {
            title === "marketPlaceReviews"
              ? setPage(paginate)
              : filterHandler(filter, paginate);
            sorterHandler(sort, extra);
          }}
          onRow={(record) => ({
            onClick: () => (loading ? null : rowClickHandler(record)),
          })}
          {...otherProps}
        ></Table>
      )}
      {title === "marketPlaceReviews" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 15,
            marginTop: 10,
          }}
        >
          <Button
            disabled={pageNumberScrape === 1}
            onClick={() => {
              setPageNumberScrape((prev) => prev - 1);
              onSearch(url, pageNumberScrape - 1);
            }}
          >
            Prev
          </Button>
          <Button
            disabled={data?.length < 10}
            onClick={() => {
              setPageNumberScrape((prev) => prev + 1);
              onSearch(url, pageNumberScrape + 1);
            }}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
}
