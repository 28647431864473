import React from "react";
import DatamapsIndia from "react-datamaps-india";

const MapChart = ({ data }) => {
  let regionData = data?.reduce((accumulator, { state, cities }) => {
    accumulator[state] = {
      value: cities?.length,
      cities: cities?.reduce(
        (
          cityAcc,
          { city_name, avg_age, count_female_users, count_male_users }
        ) => {
          cityAcc[city_name] = {
            avg_age,
            count_female_users,
            count_male_users,
          };
          return cityAcc;
        },
        {}
      ),
    };
    return accumulator;
  }, {});

  return (
    <DatamapsIndia
      className="map"
      regionData={regionData}
      hoverComponent={({ value }) => {
        return (
          <div style={{ width: "90%" }}>
            <div>
              {value?.cities ? (
                <>
                  <h1
                    style={{
                      fontSize: 18,
                      fontStyle: "italic",
                      fontWeight: 700,
                    }}
                  >
                    {value?.name}
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      minWidth: "100%",
                      alignItems: "center",

                      justifyContent: "space-between",
                    }}
                  >
                    {Object.entries(value?.cities).map(([city, data]) => (
                      <div style={{ margin: "4%", minWidth: "32%" }}>
                        <span style={{ fontWeight: "bold" }}>{city}:</span>
                        <br />
                        <span style={{ fontSize: 15 }}>
                          Male Users: {data?.count_male_users}
                        </span>
                        <br />
                        <span style={{ fontSize: 15 }}>
                          Female Users: {data?.count_female_users}
                        </span>
                        <br />
                        <span style={{ fontSize: 15 }}>
                          Avg Age: {data?.avg_age?.toFixed(2)} years
                        </span>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div>No users</div>
              )}
            </div>
          </div>
        );
      }}
      mapLayout={{
        startColor: "#efe6a8",
        endColor: "#FFDF00",
        hoverTitle: "Count",
        noDataColor: "white",
        borderColor: "#8D8D8D",
        hoverBorderColor: "#8D8D8D",
        hoverColor: "rgba(0, 0, 0, 0.1)",
      }}
    />
  );
};

export default MapChart;
