import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import Text from "../Text";

export default function BreadCrumb({ title }) {
  return (
    <>
      <Breadcrumb.Separator>
        <HomeOutlined />
      </Breadcrumb.Separator>
      <Breadcrumb.Separator />
      <Text>{title}</Text>
    </>
  );
}
