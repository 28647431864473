import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import MainContent from "../containers/MainContent";
import { StoreContext } from "../helpers/StoreContext";
import Login from "../screens/Login";
import NetworkError from "../screens/NetworkError";
import ResetPassword from "../screens/ResetPassword";
import { routes } from "./AllRoutes";

function getToken() {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
}

export default function AuthRoutes() {
  const { storeId: companyId, user } = useContext(StoreContext);
  const [savedToken, setSavedToken] = useState(getToken());
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  });

  function updateOnlineStatus() {
    setOnline(navigator.onLine);
    if (!navigator.online) window.location.reload();
  }

  function setToken(userToken) {
    localStorage.setItem("token", JSON.stringify(userToken));
    setSavedToken(userToken);
  }

  return (
    <BrowserRouter basename="/">
      <Routes>
        {!online && <Route path="/" element={<NetworkError />} />}
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/"
          element={savedToken ? <MainContent /> : <Login setToken={setToken} />}
        >
          {routes
            .filter((MainRoute, i) =>
              user.role_id === 4 || user.role_id === 5
                ? MainRoute.key === "cubeclubgrp" ||
                  MainRoute.key === "cubeclub" ||
                  MainRoute.key === "cubeclubProducts" ||
                  MainRoute.key === "invoice"
                : user.role_id === 8
                ? MainRoute.key === "tv_dashboard"
                : MainRoute
            )

            .map((MainRoute, i) =>
              MainRoute.key === "users" && companyId ? null : (
                <Route
                  exact
                  index={i === 0}
                  key={i === 0 ? "/" : MainRoute.key}
                  path={i === 0 ? "/" : MainRoute.key}
                  element={<MainRoute.component write={MainRoute.write} />}
                />
              )
            )}
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
