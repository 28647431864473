import React from "react";
import Badge from "../../components/Badge";
import PageContainer from "../../containers/Page";

const columns = [
  { title: "Company", dataIndex: "company_name", key: "company_name" },
  { title: "Name", key: "first_name", dataIndex: "first_name" },
  {
    title: "Rating",
    key: "rating",
    dataIndex: "rating",
    render: (rating) => <Badge rating={rating} />,
  },
];

export default function Reviews() {
  return (
    <PageContainer
      getApiName="dopamine/getReview"
      type="Reviews"
      columns={columns}
      sidebarData={[
        { label: "Company Name", value: "company_name", fullWidth: true },
        { label: "Name", value: "first_name", fullWidth: true },
        { label: "Rating", value: "rating", fullWidth: true, type: "rating" },
        { label: "Description", value: "description", fullWidth: true },
      ]}
    />
  );
}
