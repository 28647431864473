import { Typography } from "antd";
import React from "react";

import "./index.css";

export default function Text({
  type = "",
  bold = false,
  style = {},
  children,
  ...props
}) {
  if (type === "title")
    return (
      <Typography.Title
        style={{ margin: 0, ...style }}
        className="text-title"
        level={5}
        {...props}
      >
        {children}
      </Typography.Title>
    );
  else if (type === "label")
    return (
      <Typography.Text
        style={{ fontSize: 11, color: "#888", ...style }}
        {...props}
      >
        {children} <br />
      </Typography.Text>
    );
  return (
    <Typography.Text type={type} style={style} strong={bold} {...props}>
      {children}
    </Typography.Text>
  );
}
