import moment from "moment";

export function DateFormat(date, formatBy = "DD MMM YY h:mm A") {
  return moment(date, "YYYY-MM-DD hh:mm")
    .add({ hours: 5, minutes: 30 })
    .format(formatBy);
}

export function preloadImage(url = "") {
  const newImage = new Image();
  newImage.src = url;
  return;
}

export const statusBadgeHandler = (status = "", type = "") => {
  if (type === "product") {
    if (status === "plenty" || status === "in stock")
      return { label: status, color: "green" };
    else if (status === "out of stock") return { label: status, color: "red" };
    return { label: status, color: "yellow" };
  } else if (type === "order") {
    if (status === "order_created") return { label: "Created", color: "blue" };
    else if (status === "order_updated")
      return { label: "Updated", color: "blue" };
    else if (status === "order_paid") return { label: "Paid", color: "green" };
    else if (status === "order_unpaid")
      return { label: "Unpaid", color: "orange" };
    else if (status === "order_failed")
      return { label: "Failed", color: "red" };
    else if (status === "order_cancelled" || status === "CANCELLED")
      return { label: "Cancelled", color: "red" };
    else if (status === "partially_packed")
      return { label: "Partially Packed", color: "purple" };
    else if (status === "packed" || status === "ARRIVED")
      return { label: "Packed", color: "purple" };
    else if (status === "DISPATCHED" || status === "dispatched")
      return { label: status, color: "orange" };
    else if (status === "delivered" || status === "DELIVERED")
      return { label: status, color: "cyan" };
  } else if (type === "payment") {
    if (status === "created") return { label: "Initiated", color: "blue" };
    else if (status === "captured")
      return { label: "Received", color: "green" };
    else if (status === "refunded") return { label: status, color: "orange" };
    else if (status === "failed") return { label: status, color: "red" };
    return { label: "NA", color: "" };
  }
  return { label: status, color: "" };
};
