import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Spin, Upload } from "antd";
import debounce from "lodash/debounce";
import React, { useCallback } from "react";

import Text from "../../components/Text";
import { errorMessage, getApi } from "../../helpers/ApiFunctions";

export default function CompanyForm({
  list = [],
  loading = false,
  saveData = () => {},
  selected = {},
  setSelected = () => {},
  setEditEnabled = () => {},
  subjectOptions = [],
}) {
  const [imageFile, setImageFile] = React.useState(null);
  const [verifying, setVerifying] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [form] = Form.useForm();

  const debouncedSearch = useCallback(
    debounce((nextValue) => verifyUsername(nextValue), 1200),
    []
  );

  const verifyUsername = async (username = "") => {
    setVerifying(true);
    const res = await getApi({ username }, "verifyusername", "dopamine/");
    if (res?.data) setVerified(!res.data.alreadyExist);
    setVerifying(false);
  };

  const submitHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (!verified && !selected.company_id)
          return errorMessage("Please verify the username first !");
        if (imageFile) values.imageFile = imageFile;
        if (selected.company_id) values.company_id = selected.company_id;
        else values.type = "company";
        if (values.subjects) {
          if (values.subjects[0]?.value)
            values.subjects = values.subjects?.map?.((s) => ({
              subject_id: s.value,
              subject_name: s.label,
            }));
          else values.subjects = selected.subjects;
        }
        saveData(values);
      })
      .catch((info) => console.log("Validate Failed:", info));
  };

  return (
    <Form requiredMark={false} form={form} layout="vertical" name="company">
      <Row style={{ width: "100%", marginTop: "2rem" }}>
        <Col span={14}>
          <Text level={4} type="title">
            Basic Details
          </Text>
        </Col>
        <Col style={{ textAlign: "right" }} span={10}>
          <Button
            size="small"
            onClick={() => {
              setImageFile(null);
              if (!selected.company_id)
                setSelected({
                  ...list[0],
                  session_types: list[0].session_types?.split?.(", "),
                });
              setEditEnabled(false);
            }}
            type="secondary"
            disabled={loading}
            style={{
              color: "red",
              marginRight: window.innerWidth > 768 ? 15 : 0,
              marginBottom: window.innerWidth > 768 ? 0 : 15,
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={submitHandler}
            type="primary"
            loading={loading}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
        <Col style={{ marginTop: "1rem" }} span={24}>
          <Spin size="large" className="loadr" spinning={loading}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8} lg={4}>
                <Text type="label">Logo</Text>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  className="logo-uploader"
                  customRequest={({ file }) => setImageFile(file)}
                  fileList={
                    imageFile || selected.logo
                      ? [
                          imageFile || {
                            url: selected.logo,
                            uid: "1",
                            status: "done",
                            name: "image.png",
                          },
                        ]
                      : []
                  }
                  onRemove={() => {
                    setSelected({ ...selected, logo: null });
                    setImageFile(null);
                  }}
                  maxCount={1}
                  multiple={false}
                >
                  {!selected.logo && !imageFile && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Col>
              <Col xs={24} md={16} lg={20}>
                <Row gutter={[16, 0]}>
                  <Col xs={24} md={12} lg={8}>
                    <Text type="label">Name</Text>
                    <Form.Item
                      initialValue={selected.name}
                      name="name"
                      key="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the name !",
                        },
                      ]}
                    >
                      <Input placeholder="Company Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Text type="label">Username</Text>
                    <Form.Item
                      initialValue={selected.username}
                      name="username"
                      key="username"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && !selected.username)
                              debouncedSearch(value);
                            return Promise.resolve();
                          },
                        },
                      ]}
                      extra={
                        !!selected.username ? (
                          "Username cannot be updated"
                        ) : verifying ? (
                          <>
                            Verifying <Spin size="small" />
                          </>
                        ) : !verified ? (
                          <span style={{ color: "red" }}>
                            Please enter a unique username
                          </span>
                        ) : (
                          "Username Verified"
                        )
                      }
                      hasFeedback={verified}
                    >
                      <Input
                        readOnly={!!selected.username}
                        placeholder="Company Username"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Text type="label">Email</Text>
                    <Form.Item
                      initialValue={selected.email}
                      name="email"
                      key="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid email !",
                        },
                      ]}
                      extra={!!selected.email && "Email cannot be updated"}
                    >
                      <Input
                        readOnly={!!selected.email}
                        placeholder="Ex. dopamine@email.in"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Text type="label">Mobile</Text>
                    <Form.Item
                      initialValue={selected.mobile}
                      name="mobile"
                      key="mobile"
                    >
                      <Input placeholder="Ex. 9876543210" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Text type="label">Subjects Covered</Text>
                    <Form.Item
                      initialValue={selected.subjects?.map?.(
                        (s) => s.subject_id || s
                      )}
                      name="subjects"
                      key="subjects"
                      rules={[
                        {
                          required: true,
                          message: "Please select subjects covered !",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        options={subjectOptions}
                        placeholder="Ex. Coding"
                        labelInValue
                      />
                    </Form.Item>
                  </Col>
                  {!selected.company_id && (
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Set Password</Text>
                      <Form.Item
                        key="password"
                        name="password"
                        initialValue=""
                        rules={[
                          {
                            required: true,
                            message: "Please enter password !",
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.length < 6) {
                                return Promise.reject(
                                  "Password must be at least 6 characters long"
                                );
                              } else if (
                                value &&
                                !/(?=(.*\d){2})(?=(.*[a-z]){2})(?=(.*[A-Z]){2})/.test(
                                  value
                                )
                              ) {
                                return Promise.reject(
                                  "Password must contain atleast 2 uppercase, 2 lowercase and 2 numbers"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input type="password" placeholder="Set Password" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col style={{ marginTop: "2rem" }} span={24}>
          <Text style={{ marginBottom: "1rem" }} level={4} type="title">
            More Details
          </Text>
          <Spin size="large" className="loadr" spinning={loading}>
            <Row gutter={16}>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Session Types</Text>
                <Form.Item
                  initialValue={selected.session_types || []}
                  name="session_types"
                  key="session_types"
                  rules={[
                    {
                      required: true,
                      message: "Please select session type !",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    options={[{ value: "Live" }, { value: "Recorded" }]}
                    placeholder="Ex. LIVE"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Countries</Text>
                <Form.Item
                  initialValue={selected.countries || []}
                  name="countries"
                  key="countries"
                  rules={[
                    {
                      required: true,
                      message: "Please select countries covered !",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    options={[
                      { value: "India" },
                      { value: "France" },
                      { value: "Italy" },
                    ]}
                    placeholder="Ex. India"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Headquarter</Text>
                <Form.Item
                  initialValue={selected.headquarter}
                  name="headquarter"
                  key="headquarter"
                >
                  <Input placeholder="Ex. Mumbai" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Founder</Text>
                <Form.Item
                  initialValue={selected.founder}
                  name="founder"
                  key="founder"
                >
                  <Input placeholder="Founder Name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Founded In</Text>
                <Form.Item
                  initialValue={selected.founded_in}
                  name="founded_in"
                  key="founded_in"
                  rules={[
                    {
                      required: true,
                      message: "Please enter founding year !",
                    },
                  ]}
                >
                  <Input placeholder="Ex. 2020" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Student Count</Text>
                <Form.Item
                  initialValue={selected.student_count}
                  name="student_count"
                  key="student_count"
                  rules={[
                    {
                      required: true,
                      message: "Please enter student count !",
                    },
                  ]}
                >
                  <Input placeholder="Ex. 2000" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Website Url</Text>
                <Form.Item
                  initialValue={selected.website_url}
                  name="website_url"
                  key="website_url"
                >
                  <Input placeholder="Ex. dopamine.co" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Text type="label">Funding</Text>
                <Form.Item
                  initialValue={selected.funding}
                  name="funding"
                  key="funding"
                >
                  <Input placeholder="Ex. 1 Cr" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Text type="label">USP</Text>
                <Form.Item
                  initialValue={selected.usp}
                  name="usp"
                  key="usp"
                  rules={[{ required: true, message: "Please enter USP !" }]}
                >
                  <Input.TextArea placeholder="Ex. Quality Teaching" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Text type="label">About</Text>
                <Form.Item
                  initialValue={selected.about_us}
                  name="about_us"
                  key="about_us"
                >
                  <Input.TextArea placeholder="Ex. About Company" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Text type="label">Offerings</Text>
                <Form.Item
                  initialValue={selected.offerings}
                  name="offerings"
                  key="offerings"
                >
                  <Input.TextArea placeholder="Ex. Offerings" />
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Form>
  );
}
