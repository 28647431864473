import React, { useEffect, useState } from "react";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import dayjs from "dayjs";
import ChevronRight from "../../assets/menu.svg";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Sidebar from "../../components/Sidebar";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";
import { getApi } from "../../helpers/ApiFunctions";

export default function BusinessCard({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [claimedUsersList, setClaimedUsersList] = useState([]);
  const [selected, setSelected] = useState({});

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, object) => (
        <div style={{ width: "max-content" }}>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </div>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => (t ? t : "-"),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (t) => (t ? t : "-"),
    },
    {
      title: "Profile URL",
      dataIndex: "invite_link",
      key: "invite_link",

      render: (t) =>
        t ? (
          <a
            style={{
              display: "flex",
              alignItems: "center",

              backgroundColor: "#0B0B0B14",
              padding: "3px 12px 3px 16px",
              borderRadius: "12px",
              color: "#000000",
            }}
            href={t}
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            {t}

            <img
              style={{ marginLeft: 5 }}
              src={ChevronRight}
              alt="ChevronRight"
            />
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: "Profile type",
      dataIndex: "user_type",
      align: "center",
      key: "user_type",
      render: (t) => (t ? <div style={{ width: "100px" }}>{t}</div> : "-"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "max-content",
      render: (t) =>
        t ? (
          <div style={{ width: "250px" }}>
            {(t?.name !== null ? t?.name + ", " : "") +
              (t?.address1 !== null ? t?.address1 + ", " : "") +
              (t?.address2 !== null ? t?.address2 : "") +
              (t?.landmark !== null ? "(Nearby " + t?.landmark + "), " : "") +
              (t?.city !== null ? t?.city + ", " : "") +
              (t?.state !== null ? t?.state + ", " : "") +
              (t?.pincode !== null ? "PIN-" + t?.pincode + ", " : "")}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Address Type",
      dataIndex: "address",
      align: "center",
      key: "address",
      render: (t) =>
        t?.address_type ? (
          <div style={{ width: "100px" }}>{t.address_type}</div>
        ) : (
          "-"
        ),
    },
  ];

  useEffect(() => getCardClaimedUsers(), []);

  const getCardClaimedUsers = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getCardClaimedUsersList",
      "dashboard/"
    );
    if (res.data) {
      setClaimedUsersList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  return (
    <>
      {selected.user_id && (
        <Sidebar
          loading={selected.loading}
          title="Payment Details"
          key={"payment details"}
          onClose={() => setSelected({})}
          visible
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                custom: true,
                fullWidth: true,
              },
              { label: "PAYMENT ID", value: selected?.payment_id },
              { label: "ORDER ID", value: selected?.order_id },
              {
                label: "User Name",
                value: selected?.last_name
                  ? selected?.first_name + " " + selected?.last_name
                  : selected?.first_name,
              },
              {
                label: "User Mobile",
                value: selected?.mobile ? selected?.mobile : "-",
              },
              {
                label: "User Email",
                value: selected?.email ? selected?.email : "-",
              },
              { label: "AMOUNT", value: "₹ " + selected?.payment_amount },
              {
                label: "DATE",
                value: dayjs(selected?.date_of_payment).format(
                  "DD MMM YYYY hh:mm A"
                ),
              },
              {
                label: "STATUS",
                value:
                  selected?.payment_status === "order_paid" ? (
                    <span
                      style={{
                        padding: "3px",
                        backgroundColor: "#08A908",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                      }}
                    >
                      Recieved
                    </span>
                  ) : selected?.payment_status === "order_created" ? (
                    <span
                      style={{
                        padding: "3px",
                        backgroundColor: "#FFBF00",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                      }}
                    >
                      Created
                    </span>
                  ) : (
                    <span
                      style={{
                        padding: "3px",
                        backgroundColor: "#FF0000",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                      }}
                    >
                      Failed
                    </span>
                  ),
              },
              { label: "PAYMENT TYPE", value: selected?.provider },
              {
                label: "PAYMENT TRIALS",
                value: selected.order_created && (
                  <Timeline
                    style={{ marginTop: 15, marginLeft: 5, borderColor: "red" }}
                    mode="left"
                  >
                    {selected.payment_status === "order_paid" ? (
                      <>
                        <Timeline.Item
                          style={{ marginLeft: "0px" }}
                          dot={
                            <CheckCircleOutlined
                              style={{
                                fontSize: 14,
                                color: "#096dd9",
                              }}
                            />
                          }
                        >
                          <Text bold style={{ textTransform: "capitalize" }}>
                            "Initiated"
                          </Text>
                          <br />
                          <Text style={{ fontSize: 12 }}>
                            {dayjs(selected?.order_created).format(
                              "DD MMM, YYYY h:mm A"
                            )}
                          </Text>
                          <br />
                        </Timeline.Item>
                        <Timeline.Item
                          style={{ marginLeft: "0px" }}
                          dot={
                            <CheckCircleOutlined
                              style={{
                                fontSize: 14,
                                color: "green",
                              }}
                            />
                          }
                        >
                          <Text bold style={{ textTransform: "capitalize" }}>
                            "Recieved"
                          </Text>
                          <br />
                          <Text style={{ fontSize: 12 }}>
                            {dayjs(selected?.date_of_payment).format(
                              "DD MMM, YYYY h:mm A"
                            )}
                          </Text>
                          <br />
                        </Timeline.Item>
                      </>
                    ) : selected.payment_status === "order_failed" ? (
                      <Timeline.Item
                        style={{ marginLeft: "0px" }}
                        color="red"
                        dot={
                          <CloseCircleOutlined
                            style={{ fontSize: 14, color: "red" }}
                          />
                        }
                      >
                        <Text bold style={{ textTransform: "capitalize" }}>
                          "Failed"
                        </Text>
                        <br />
                        <Text style={{ fontSize: 12 }}>
                          {dayjs(selected?.date_of_payment).format(
                            "DD MMM, YYYY h:mm A"
                          )}
                        </Text>
                        <br />
                      </Timeline.Item>
                    ) : (
                      <Timeline.Item
                        style={{ marginLeft: "0px" }}
                        color="blue"
                        dot={
                          <CheckCircleOutlined
                            style={{
                              fontSize: 14,
                              color: "#096dd9",
                            }}
                          />
                        }
                      >
                        <Text bold style={{ textTransform: "capitalize" }}>
                          "Initiated"
                        </Text>
                        <br />
                        <Text style={{ fontSize: 12 }}>
                          {dayjs(selected?.order_created).format(
                            "DD MMM, YYYY h:mm A"
                          )}
                        </Text>
                        <br />
                      </Timeline.Item>
                    )}
                  </Timeline>
                ),
                fullWidth: true,
              },
            ]}
          />
        </Sidebar>
      )}

      <CardContainer hoverable={false}>
        <BreadCrumb title="Business Card" />
        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          rowClickHandler={(row) => {
            setSelected(row);
          }}
          data={claimedUsersList}
          total={total}
          searchFor="Name, Mobile"
          functionsHandler={getCardClaimedUsers}
        />
      </CardContainer>
    </>
  );
}
