import {
  AppstoreOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  FileSyncOutlined,
  HomeOutlined,
  LikeOutlined,
  PieChartOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  SettingOutlined,
  StarOutlined,
  TrophyOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import React from "react";

import { ReactComponent as BOOKINGS } from "../assets/bookings.svg";
import { ReactComponent as COMPANIES } from "../assets/company.svg";
import CUBE from "../assets/cube-logo.svg";
import { ReactComponent as CURRICULUM } from "../assets/curriculum.svg";
import { ReactComponent as OFFERINGS } from "../assets/offerings.svg";
import { ReactComponent as PRICES } from "../assets/prices.svg";
import configData from "../config.json";
import AccountDeletion from "../screens/Account deletion";
import AdminType from "../screens/AdminType";
import Analytic from "../screens/Analytic";
import AppReports from "../screens/AppReports";
import Booking from "../screens/Booking";
import Business from "../screens/Business";
import businessCard from "../screens/Bussiness Card";
import Centre from "../screens/Centre";
import Challenge from "../screens/Challenge/Challenge";
import Company from "../screens/Company";
import CoursePrice from "../screens/CoursePrices";
import CubeClubWarranty from "../screens/Cube club";
import Invoice from "../screens/Cube club/Invoice";
import Products from "../screens/Cube club/Products";
import HomeGym from "../screens/Cube club/homeGym";
import Leads from "../screens/CubeClubLeads/Leads";
import Curriculum from "../screens/Curriculum";
import {
  default as Experties,
  default as Expertise,
} from "../screens/Expertise";
import Experts from "../screens/Experts";
import Brand from "../screens/Influencer";
import MarketPlaceReviews from "../screens/Market Place Reviews/marketPlaceReviews";
import Offerings from "../screens/Offerings";
import ProfileStatus from "../screens/ProfileStatus";
import RentalHGS from "../screens/Rental HGS";
import Reviews from "../screens/Reviews";
import ShopifyDiscounts from "../screens/ShopifyDiscounts";
import Metrics from "../screens/TV";
import Users from "../screens/Users/Users";
import Verification from "../screens/Verification";

const allRoutes = [
  {
    title: "Experts",
    key: "experts",
    component: Experts,
    icon: <COMPANIES />,
  },
  {
    child: 1,
    title: "Admin-Type",
    key: "admintype",
    component: AdminType,
    icon: <UserSwitchOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Fitness Library",
    key: "expertise",
    component: Expertise,
    icon: <COMPANIES />,
  },
  {
    title: "Home",
    key: "analytics",
    component: Analytic,
    icon: <HomeOutlined />,
    default: true,
  },

  {
    title: "Analytics",
    key: "users",
    component: Users,
    icon: <BarChartOutlined />,
    default: true,
    children: [
      {
        label: "  All Users",
        key: "users",
        component: Users,

        default: true,
      },
    ],
  },

  {
    title: "Cube Club",
    key: "cubeclubgrp",
    component: CubeClubWarranty,
    icon: (
      <img
        src={CUBE}
        style={{
          width: 22,
          marginLeft: -4,
        }}
      />
    ),

    default: true,
    children: [
      {
        title: "Web Warranty",

        label: "  Web Warranty",
        key: "cubeclub",
        component: CubeClubWarranty,
        default: true,
      },
      {
        title: "Products",

        label: "  Products",
        key: "cubeclubProducts",
        component: Products,
        default: true,
      },
      {
        title: "App Warranty Approval",

        label: "  App Warranty Approval",
        key: "invoice",
        component: Invoice,
        default: true,
      },
      {
        title: "Home Gym Setup",
        label: "  Home Gym Setup",
        key: "homeGym",
        component: HomeGym,
        default: true,
      },
      {
        title: "Cube Club Leads",
        label: "  Cube Club Leads",
        key: "leads",
        component: Leads,
        default: true,
      },
      {
        title: "Rental HGS",
        label: "  Rental HGS",
        key: "rentalHgs",
        component: RentalHGS,
        default: true,
      },
      {
        title: "Shopify Discounts",
        label: "  Shopify Discounts",
        key: "discounts",
        component: ShopifyDiscounts,
        default: true,
      },
    ],
  },
  {
    title: "Settings",
    key: "settings",

    icon: <SettingOutlined />,
    default: true,
    children: [
      {
        title: "Admin-Type",

        label: "  Admin-Type",
        key: "admintype",
        component: AdminType,
        default: true,
      },
      {
        title: "Fitness Library",

        label: "  Fitness Library",
        key: "expertise",
        component: Experties,
      },
    ],
  },
  {
    title: "Customer Delight",
    key: "customerDelight",

    icon: <LikeOutlined />,
    default: true,
    children: [
      {
        title: "Account deletion",

        label: "  Account deletion",
        key: "accountDeletion",
        component: AccountDeletion,
      },
      {
        title: "App reports",
        label: "  App reports",
        key: "appReports",
        component: AppReports,
      },
    ],
  },
  {
    title: "Experts Business",
    key: "expertsbusiness",

    icon: <AppstoreOutlined />,
    default: true,
    children: [
      {
        title: "Services created",

        label: "  Services created",
        key: "business",
        component: Business,
      },
      {
        title: "Bookings received",

        label: "  Bookings received",
        key: "bookings",
        component: Booking,
      },
    ],
  },
  {
    title: "Operations",
    key: "operations",

    icon: <FileSyncOutlined />,
    default: true,
    children: [
      {
        title: "Verification",

        label: "  Verification",
        key: "verification",
        component: Verification,
      },
      {
        title: "Profile status",

        label: "  Profile status",
        key: "profilestatus",
        component: ProfileStatus,
      },
    ],
  },

  {
    child: 1,
    title: "Bookings",
    key: "bookings",
    component: Booking,
    icon: <BOOKINGS />,
  },
  {
    title: "Course Prices",
    key: "course-prices",
    component: CoursePrice,
    icon: <PRICES />,
  },
  {
    title: "Curriculum",
    key: "curriculum",
    component: Curriculum,
    icon: <CURRICULUM />,
  },
  {
    title: "Offerings",
    key: "offerings",
    component: Offerings,
    icon: <OFFERINGS />,
  },
  {
    title: "Reviews",
    key: "reviews",
    component: Reviews,
    icon: <StarOutlined />,
    default: true,
  },
  {
    title: "Company",
    key: "company",
    component: Company,
    icon: <COMPANIES />,
  },
  {
    title: "Centre",
    key: "centre",
    component: Centre,
    icon: <COMPANIES />,
  },
  {
    title: "Brand",
    key: "brand",
    component: Brand,
    icon: <COMPANIES />,
  },

  {
    child: 1,
    title: "Business",
    key: "business",
    component: Business,
    icon: <AppstoreOutlined />,
  },
  {
    child: 1,
    title: "Analytics",
    key: "analytics",
    component: Analytic,
    icon: <PieChartOutlined />,
    default: true,
  },

  {
    child: 1,
    title: "Verification",
    key: "verification",
    component: Verification,
    icon: <SafetyOutlined />,
  },
  // {
  //   title: "Invites",
  //   key: "Invites",
  //   component: Invites,
  //   icon: <MailOutlined />,
  //   default: true,
  // },
  {
    child: 1,
    title: "Profile status",
    key: "profilestatus",
    component: ProfileStatus,
    icon: <UserOutlined />,
  },

  {
    child: 1,
    title: "Account deletion",
    key: "accountDeletion",
    component: AccountDeletion,
    icon: <DeleteOutlined />,
  },
  {
    title: "Digital Profile",
    key: "Business-Cards",
    component: businessCard,
    icon: <CreditCardOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Cube Club",
    key: "cubeclub",
    component: CubeClubWarranty,
    icon: <CUBE />,
    default: true,
  },
  {
    child: 1,
    title: "Cube Club Products",
    key: "cubeclubProducts",
    component: Products,
    icon: <SafetyCertificateOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "App Warranty Approval",
    key: "invoice",
    component: Invoice,
    icon: <SafetyCertificateOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Home Gym Setup",
    key: "homeGym",
    icon: <SafetyCertificateOutlined />,
    component: HomeGym,
    default: true,
  },
  {
    child: 0,
    title: "Dashboard",
    key: "tv_dashboard",
    component: Metrics,
    icon: <AreaChartOutlined />,

    default: true,
  },
  {
    child: 0,
    title: "Market Place Reviews",
    key: "review",
    component: MarketPlaceReviews,
    icon: <StarOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Cube Club Leads",
    key: "leads",
    component: Leads,
    icon: <StarOutlined />,

    default: true,
  },
  {
    child: 0,
    title: "Challenges",
    key: "challenge",
    component: Challenge,
    icon: <TrophyOutlined />,

    default: true,
  },
  {
    child: 1,
    title: "Rental HGS",
    key: "rentalHgs",
    component: RentalHGS,
    icon: <TrophyOutlined />,

    default: true,
  },
  {
    child: 1,
    title: "App reports",
    key: "appReports",
    component: AppReports,
    icon: <TrophyOutlined />,
    default: true,
  },
  {
    child: 1,
    title: "Shopify Discounts",
    key: "discounts",
    component: ShopifyDiscounts,
    icon: <TrophyOutlined />,
    default: true,
  },
];

export const routes = configData.routes.map(
  (r) => (r = { ...r, ...allRoutes.find((a) => a.key === r.key) })
);
