import React, { useState } from "react";

import { Tabs } from "antd";
import Popup from "../../components/Popup";
import TabContainer from "../../components/Tab";
import Text from "../../components/Text";
import PostReports from "../PostReports";
import UserReports from "../UserReports";

export default function AppReports({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [totalPostReports, setTotalPostReports] = useState(8);
  const [totalUserReports, setTotalUserReports] = useState(8);
  const [postReportList, setPostReportList] = useState([]);
  const [userReportList, setUserReportList] = useState([]);
  const [selected, setSelected] = useState({}); 
  const [popup, setPopup] = useState(false);


  return (
    <>
      {popup && (
        <Popup
          saveText="Delete"
          title="User deletion confirmation"
        //   saveHandler={() => deletionHandler(selected.user_id)}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure to delete the profile <b>{selected.first_name}</b> ?
          </Text>
        </Popup>
      )}

      <TabContainer >
        <Tabs.TabPane tab="Reported Users" key="users">
          <UserReports />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Reported Posts" key="posts">
          <PostReports />
        </Tabs.TabPane>
      </TabContainer>
    </>
  );
}
