import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input } from "antd";
import React, { useState } from "react";

import LOGO from "../../assets/Logo_new_collapsed.svg";
import configData from "../../config.json";
import {
  errorMessage,
  getApi,
  successMessage,
} from "../../helpers/ApiFunctions";
import { StoreContext } from "../../helpers/StoreContext";
import "./index.css";

export default function Login({ setToken }) {
  const { setStoreId: setCompanyId, setUser } = React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("password");
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const loginHandler = async (values) => {
    setLoading(true);
    const res = await getApi(values, "loginUser", "user/");
    if (res.data) {
      setUser(res.data);
      const { company_id, role_id, token } = res.data;
      if (
        role_id === 4 ||
        role_id === 1 ||
        role_id === 5 ||
        role_id === 6 ||
        role_id === 7 ||
        role_id === 8
      ) {
        setCompanyId(company_id || 0);
        setToken(token);
        successMessage("Admin Login");
      } else errorMessage("You are not authorized to login");
      setTimeout(() => setLoading(false), 1000);
    } else setLoading(false);
  };

  return (
    <div className="login-wrapper">
      <div className="login-one">
        <div className="login-one-full" />
        <div className="login-one-semi" />
        <div className="login-one-circle" />
      </div>
      <div className="login-two">
        <div
          className="login-logo"
          style={{ backgroundImage: `url(${configData.logo || LOGO})` }}
        />
        <h1 className="login-title">Welcome</h1>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={loginHandler}
          layout="vertical"
          requiredMark={false}
        >
          <Col span={24}>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please enter your username!" },
              ]}
            >
              <Input
                style={{ borderRadius: 6 }}
                size="large"
                prefix={<UserOutlined className="login-input-icon" />}
                placeholder="Username"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input
                style={{ borderRadius: 6 }}
                size="large"
                type={type}
                placeholder="Password"
                prefix={<LockOutlined className="login-input-icon" />}
                suffix={
                  type === "password" ? (
                    <EyeInvisibleOutlined
                      onClick={handleToggle}
                      className="login-input-icon"
                      size={16}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={handleToggle}
                      className="login-input-icon"
                      size={16}
                    />
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                size="large"
                loading={loading}
                type="primary"
                htmlType="submit"
                className="login-btn"
              >
                LOGIN
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
}
