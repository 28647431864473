import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";
import "./index.css";

export default function Leads({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [chipId, setChipId] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [certificateList, setCertificateList] = useState([]);
  const [selected, setSelected] = useState({});
  const [url, setUrl] = useState("");
  const [popup, setPopup] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certApproval, setCertApproval] = useState([]);
  const [certId, setCertId] = useState();
  const [rejection, setRejection] = useState(false);
  const [rejectionPopup, setRejectionPopup] = useState(false);

  console.log("reviews", reviews);
  console.log("total", total);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (t) => t || "-",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (t) => t || "-",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (t) => t || "-",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (t) => DateFormat(t) || "-",
    },
  ];

  const getCcLeads = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "getCubeLeadsData", "dashboard/");
    if (res.data) {
      setReviews(res.data);
      setTotal(res?.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCcLeads();
  }, []);
  return (
    <>
      <CardContainer hoverable={false}>
        <BreadCrumb title="Cube Club Leads" />
        <TableContainer
          searchFor="Name, Mobile"
          chipId={chipId}
          setChipId={setChipId}
          title="Leads"
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          data={reviews}
          total={total}
          functionsHandler={getCcLeads}
          parseButton="export"
          filter="getCubeLeadsData"
          dateRangePicker
        />
      </CardContainer>
    </>
  );
}
