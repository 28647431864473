import React, { useContext } from "react";

import Badge from "../../../components/Badge";
import Sidebar from "../../../components/Sidebar";
import LabelValueContainer from "../../../containers/LabelValue";
import { DateFormat } from "../../../helpers/CommonFunctions";
import { StoreContext } from "../../../helpers/StoreContext";

export default function CoursePriceSidebar({ data = {}, onClose = () => {} }) {
  const { storeId: companyId } = useContext(StoreContext);

  return (
    <>
      <Sidebar title="Course Price Details" visible onClose={onClose}>
        <LabelValueContainer
          data={[
            ...(companyId
              ? []
              : [{ label: "Company Name", value: data.company_name }]),
            { label: "Name", value: data.course_name },
            { label: "Date", value: DateFormat(data.created_at) },
            {
              label: "Session Type",
              value: <Badge sessionType={data.session_type} />,
            },
            { label: "Price", value: `₹${data.price}` },
            { label: "Subject", value: data.subject_name },
            { label: "Grade", value: data.grade_name },
            { label: "Teaching Format", value: data.format_name },
            { label: "No of classes", value: data.number_of_classes },
            { label: "Class per week", value: data.class_per_week },
            { label: "Duration", value: data.duration },
            { label: "Quizzes", value: data.quizzes },
            { label: "Recorded", value: data.is_recorded },
            { label: "App availability", value: data.app_availability },
            { label: "Revision classes", value: data.revision_classes },
            { label: "Coding platform", value: data.coding_platform },
            { label: "Certification", value: data.certification },
            { label: "Finance options", value: data.finance_options },
            { label: "Refund", value: data.refund },
            { label: "USP", value: data.usp, fullWidth: true },
          ]}
        />
      </Sidebar>
    </>
  );
}
