import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";

import { ReactComponent as RESET } from "../../assets/reset-icon.svg";
import { saveApi } from "../../helpers/ApiFunctions";
import "./index.css";

export default function ResetPassword() {
  const [dataBody, setDataBody] = useState({});
  const [password1, setPassword1] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const email = params.get("email");
    if (token && email) setDataBody({ token, email });
    else {
      alert("Invalid Password Reset Token Url !");
      window.open("https://dopamine-webapp.herokuapp.com/", "_self");
    }
  }, []);

  function resetPasswordHandler() {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const res = await saveApi(
          { ...dataBody, ...values },
          "Password Reset Successful",
          "reset-password",
          "dopamine/"
        );
        if (!res)
          alert(
            "Password Reset Token already used, please request another password reset"
          );
        setTimeout(
          () => window.open("https://dopamine-webapp.herokuapp.com/", "_self"),
          600
        );
        setLoading(false);
      })
      .catch((info) => console.log("Validate Failed:", info));
  }

  return (
    <div className="reset-container">
      <RESET className="home__icon" />
      <h2 style={{ margin: "12px 0 4px" }}>Reset Password</h2>
      <span style={{ color: "#777" }}>
        Please enter new password to continue.
      </span>
      <Form form={form} layout="vertical" name="reset">
        <Row style={{ margin: "25px auto" }}>
          <Col xs={24}>
            <Form.Item
              name="password1"
              label="New Password"
              rules={[
                {
                  validator: (_, value) => {
                    if (value.length > 7) {
                      if (
                        /[a-z]/.test(value?.toLowerCase()) &&
                        /[0-9]/.test(value) &&
                        value.length >= 8
                      ) {
                        setPassword1(value);
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Aleast 1 alphabet and number !")
                      );
                    }
                    return Promise.reject(
                      new Error("Minimum length should be 8 !")
                    );
                  },
                },
              ]}
            >
              <Input.Password placeholder="Ex. Abcd@123" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="password2"
              label="Confirm Password"
              rules={[
                {
                  validator: (_, value) => {
                    if (value.length > 7) {
                      if (
                        /[A-Z]/.test(value) &&
                        /[0-9]/.test(value) &&
                        value.length >= 8
                      ) {
                        if (value === password1) return Promise.resolve();
                        return Promise.reject(
                          new Error("Passwords do not match !")
                        );
                      }
                      return Promise.reject(
                        new Error("Aleast 1 Uppercase, lowercase and number !")
                      );
                    }
                    return Promise.reject(
                      new Error("Minimum length should be 8 !")
                    );
                  },
                },
              ]}
            >
              <Input.Password placeholder="Ex. Abcd@123" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Button
              style={{
                width: "100%",
                fontSize: 16,
                fontWeight: 500,
                marginTop: 12,
              }}
              onClick={resetPasswordHandler}
              type="primary"
              loading={loading}
              size="large"
            >
              Reset Password
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
