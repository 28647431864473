import React, { useState } from "react";
import { Progress, Select, Upload } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { InboxOutlined } from "@ant-design/icons";

import Popup from "../Popup";
import {
  errorMessage,
  getApi,
  successMessage,
} from "../../helpers/ApiFunctions";
import { StoreContext } from "../../helpers/StoreContext";

export default function ImportPopup({ type = "", onClose = () => {} }) {
  const { storeId: companyId } = React.useContext(StoreContext);
  const [subjectId, setSubjectId] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const uploadCsvFile = async ({ file }) => {
    setUploading(true);
    setProgress(10);
    const headers = { "content-type": "multipart/form-data" };
    const fmData = new FormData();
    fmData.append("type", type === "price" ? "price" : "comparator");
    fmData.append("api_name", "uploadinventory");
    fmData.append("inventory", file);
    const res = await getApi(fmData, "uploadinventory", "dopamine/", headers);
    if (res && res.success) {
      setTimeout(() => setProgress(50), 1000);
      const res2 = await getApi(
        { subject_id: subjectId, company_id: companyId, url: res.data.url },
        type === "price" ? "createprice" : "createcomparator"
      );
      if (res2 && res2.success)
        setTimeout(() => {
          setProgress(90);
          setCsvFile(file);
          setUploading(false);
        }, 2000);
      else {
        setCsvFile(null);
        setProgress(0);
        setUploading(false);
      }
    } else {
      setCsvFile(null);
      setUploading(false);
      setProgress(0);
    }
  };

  const importHandler = () => {
    if (csvFile) {
      setUploading(true);
      setTimeout(() => {
        setUploading(false);
        successMessage(
          `${type === "price" ? "Course Pirce" : "Curriculum"} Imported`
        );
        onClose(true);
      }, 1200);
    } else errorMessage("Please select a file to import first !");
  };

  return (
    <Popup
      width="30%"
      visible
      loading={uploading}
      saveHandler={importHandler}
      cancelHandler={() => onClose(false)}
      title={type === "price" ? "Import Course Prices" : "Import Curriculum"}
      saveText="Import"
    >
      {false && (
        <FormItem label="Select Company">
          <Select
            placeholder="Select Company"
            options={[
              { label: "Lido", value: 1 },
              { label: "CampK12", value: 2 },
            ]}
            onChange={(v) => setSubjectId(v)}
            defaultValue={1}
          />
        </FormItem>
      )}
      <FormItem label="Select Subject">
        <Select
          placeholder="Select Subject"
          options={[
            { label: "Coding", value: 1 },
            { label: "Math", value: 2 },
          ]}
          onChange={(v) => setSubjectId(v)}
          defaultValue={1}
        />
      </FormItem>
      <Upload.Dragger
        accept=".xlsx"
        listType="picture"
        height={csvFile ? 0 : 100}
        maxCount={1}
        customRequest={uploadCsvFile}
        onRemove={() => setCsvFile(null)}
        fileList={csvFile ? [csvFile] : []}
        multiple={false}
      >
        {!csvFile && (
          <div style={{ padding: "0 15px" }}>
            <InboxOutlined style={{ fontSize: 22 }} />
            <p>
              Click or drag <span style={{ color: "green" }}>CSV</span> file to
              this area to import {type === "price" && "course "}
              {type}
            </p>
          </div>
        )}
      </Upload.Dragger>
      {progress > 0 && progress !== 90 && <Progress percent={progress} />}
    </Popup>
  );
}
