import React, { useEffect, useState } from "react";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi, saveApi } from "../../helpers/ApiFunctions";
import { DateFormat } from "../../helpers/CommonFunctions";

import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import BreadCrumb from "../../components/Breadcrumb";
import { ImageUpload } from "../../components/ImageUpload";
import Popup from "../../components/Popup";
import Sidebar from "../../components/Sidebar";
import Text from "../../components/Text";
import LabelValueContainer from "../../containers/LabelValue";

import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Input, Row, Switch } from "antd";
import "./index.css";

export default function Admin({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState({});
  const [popup, setPopup] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [adminName, setAdminName] = useState("");
  const [adminDescription, setAdminDescription] = useState("");
  const [activeImage, setActiveImage] = useState(null);
  const [inactiveImage, setInactiveImage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");
  const [inactiveIcon, setInactiveIcon] = useState("");

  const adminTypeId = "1";
  const menu = (
    <Menu
      items={[
        {
          label: (
            <span onClick={() => setIsModalVisible(true)}>Edit Admin Type</span>
          ),
          key: "0",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "Type",
      dataIndex: "admin_type_name",
      key: "admin_type_name",
      sorter: true,
      align: "center",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Description",
      align: "center",
      dataIndex: "admin_type_description",
      key: "admin_type_description",
    },

    {
      title: "Image",
      align: "center",
      render: (text) => (
        <Avatar
          icon={<UserOutlined />}
          size={30}
          alt=""
          src={text?.is_active ? text?.active_icon : text?.inactive_icon}
          style={{
            background: text?.is_active ? "#FF4A01" : "rgb(240, 240, 240)",
            padding: "8px",
          }}
        />
      ),
    },

    {
      title: "Edit",
      align: "center",
      render: (text) => (
        <div style={{ textAlign: "center" }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div
              onClick={(e) => {
                e.preventDefault();
                setSelected(text);
              }}
            >
              <Space>
                <MoreOutlined style={{ fontSize: 20 }} />
              </Space>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const [form] = Form.useForm();

  useEffect(() => getUsersList(), []);

  useEffect(() => {
    if (Object.keys(selected)?.length) {
      setAdminName(selected?.category_name);
      setAdminDescription(selected?.category_description);
      setActiveIcon(selected?.active_icon);
      setInactiveIcon(selected?.inactive_icon);
    } else {
      setAdminName("");
      setAdminDescription("");
      setActiveIcon("");
      setInactiveIcon("");
    }
  }, [selected]);

  const getUsersList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload, type: "admin" },
      "getAdminTypes",
      "user/"
    );

    if (res.data) {
      setUserList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  const uploadImageHandler = async () => {
    setLoading(true);
    if (activeImage !== null || inactiveImage !== null) {
      var formdata = new FormData();
      formdata.append("api_name", "uploadCategoryImage");
      formdata.append("key", "JWT");
      formdata.append("secret", "DOPAMINE");
      formdata.append("category", "admin");
      if (activeImage !== null) {
        formdata.append("active", activeImage, activeImage?.name);
      }
      if (inactiveImage !== null) {
        formdata.append("inactive", inactiveImage, inactiveImage?.name);
      }
      if (Object.keys(selected)?.length !== 0) {
        formdata.append("admin_type_id", selected?.admin_type_id);
      }

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        " https://dopamine-fit-core.herokuapp.com/api/v1/upload/uploadAdminImage",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            const active = res.data.filter((item) => item.is_active === 1);
            const inactive = res.data.filter((item) => item.is_active !== 1);
            if (Object.keys(selected)?.length !== 0) {
              updateAdmin({
                name: adminName,
                description: adminDescription,
                admin_type_id: selected?.admin_type_id,
                active_image_id: active[0]?.file_id || selected?.active_file_id,
                inactive_image_id:
                  inactive[0]?.file_id || selected?.inactive_file_id,
              });
            } else {
              createAdmin({}, active[0]?.file_id, inactive[0]?.file_id);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } else {
      if (Object.keys(selected)?.length !== 0) {
        updateAdmin({
          name: adminName,
          description: adminDescription,
          admin_type_id: selected?.admin_type_id,
        });
      } else {
        createAdmin();
      }
    }
  };
  const updateAdmin = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(payload, "updateAdmin", "dashboard/");

    if (res.data) {
      setIsModalVisible(false);
      getUsersList();
      setSelected({});
      setActiveImage(null);
      setInactiveImage(null);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (Object.keys(selected).length) {
      setAdminName(selected?.admin_type_name);
      setAdminDescription(selected?.admin_type_description);
    } else {
      setAdminName("");
      setAdminDescription("");
    }
  }, [selected]);

  const createAdmin = async (payload = {}, active = 0, inactive = 0) => {
    setLoading(true);
    const res = await getApi(
      {
        ...payload,
        name: adminName,
        description: adminDescription,
        admin_type_id: adminTypeId,
        active_image_id: active,
        inactive_image_id: inactive,
      },
      "createAdmin",
      "dashboard/"
    );
    if (res.data) {
      getUsersList();
      setIsModalVisible(false);
      setAdminName("");
      setAdminDescription("");
      setTotal(res.meta?.total);
      setSelected({});
      setActiveImage(null);
      setInactiveImage(null);
    }
    setLoading(false);
  };

  const updateStatusHandler = async () => {
    setSelected({ ...selected, loading: true });
    let is_active = 1;
    let msg = "Admin Type Activated";
    if (selected.is_active) {
      is_active = 0;
      msg = "Admin Type Deactivated";
    }
    const res = await saveApi(
      { admin_type_id: selected.admin_type_id, is_active: is_active },
      msg,
      "deleteAdmin",
      "dashboard/"
    );
    if (res) {
      setUserList(
        userList.map((user) => {
          if (user.admin_type_id === selected.admin_type_id) {
            return (user = { ...user, is_active: is_active });
          }
          return user;
        })
      );
    }
    setSelected({});
    setPopup(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const adminType = (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Admin Type" />
      <div className="modal">
        <Popup
          className="Modal"
          visible={isModalVisible}
          saveText={
            Object.keys(selected).length
              ? "Update Admin Type"
              : "Create Admin Type"
          }
          title={
            Object.keys(selected).length
              ? "Update Admin Type"
              : "Create Admin Type"
          }
          saveHandler={uploadImageHandler}
          cancelHandler={() => {
            setSelected({});
            setIsModalVisible(false);
          }}
          loading={selected.loading || loading}
          visibleonCancel={handleCancel}
        >
          <div className="container">
            <div className="left">
              <div className="upload">
                <div className="upload1">
                  <div style={{ marginTop: 8 }}>InActive Icon</div>

                  <ImageUpload
                    imageFile={activeImage}
                    setImageFile={setActiveImage}
                    selected={selected}
                    setSelected={setSelected}
                    selectedIcon={activeIcon}
                  />
                </div>
                <div className="upload2">
                  <div style={{ marginTop: 8 }}>Active Icon</div>
                  <ImageUpload
                    imageFile={inactiveImage}
                    setImageFile={setInactiveImage}
                    selected={selected}
                    setSelected={setSelected}
                    selectedIcon={inactiveIcon}
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <Input
                style={{ borderRadius: 6, marginBottom: 20 }}
                size="large"
                placeholder="Admin Type"
                required
                value={adminName}
                onChange={(e) => setAdminName(e.target.value)}
              />
              <Input
                style={{ borderRadius: 6 }}
                size="large"
                placeholder="Description"
                value={adminDescription}
                onChange={(e) => setAdminDescription(e.target.value)}
              />
            </div>
          </div>
        </Popup>
      </div>
      <TableContainer
        loading={loading}
        columns={
          write === 0
            ? columns
            : [
                ...columns,
                {
                  title: "Is Active",
                  dataIndex: "is_active",
                  key: "is_active",
                  align: "center",
                  render: (text, object) => (
                    <Switch
                      size="small"
                      checked={text ? true : false}
                      onChange={(c) => {
                        setSelected(object);
                        setPopup(true);
                      }}
                    />
                  ),
                },
                {
                  title: "Created On",
                  align: "center",
                  dataIndex: "created_at",
                  key: "created_at",
                  sorter: true,
                  render: (text) => DateFormat(text),
                },
              ]
        }
        data={userList}
        total={total}
        searchFor=""
        functionsHandler={getUsersList}
      />
    </CardContainer>
  );

  return (
    <>
      {popup && (
        <Popup
          saveText={selected.is_active ? "Yes, deactivate" : "Yes, activate"}
          title="Update Admin Type Status"
          saveHandler={updateStatusHandler}
          cancelHandler={() => {
            setSelected({});
            setPopup(false);
          }}
          loading={selected.loading}
          visible
        >
          <Text bold style={{ fontSize: 18 }}>
            Are you sure to{" "}
            <b>{selected.is_active ? "Deactivated" : "active"} </b>
            the Admin Type <b>{selected.admin_type_name}</b>?
          </Text>
        </Popup>
      )}
      {bankDetails.bank_id && (
        <Popup
          title="Bank Details"
          loading={bankDetails.loading}
          saveHandler={() => setBankDetails({})}
          cancelHandler={() => setBankDetails({})}
          saveText="Okay"
          cancelText="Close"
          visible
        >
          <Form
            requiredMark={false}
            form={form}
            layout="vertical"
            name="company"
            labelCol={{ style: { marginLeft: 12 } }}
          >
            <Row gutter={[16, 16]} style={{ position: "relative" }}>
              <div style={{ position: "absolute", right: 10, top: -25 }}></div>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Account no"
                  initialValue={selected.account_no}
                  name="account_no"
                  key="account_no"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Account type"
                  initialValue={selected.account_type}
                  name="account_type"
                  key="account_type"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Bank name"
                  initialValue={selected.bank_name}
                  name="bank_name"
                  key="bank_name"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Branch"
                  initialValue={selected.branch}
                  name="branch"
                  key="branch"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="IFSC code"
                  initialValue={selected.ifsc_code}
                  name="ifsc_code"
                  key="ifsc_code"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="MICR"
                  initialValue={selected.micr}
                  name="micr"
                  key="micr"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    readOnly
                    bordered={false}
                    size="large"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Popup>
      )}
      {selected.expert_id && !popup && (
        <Sidebar
          loading={selected.loading}
          title="Expert Details"
          onClose={() => setSelected({})}
          visible
        >
          <LabelValueContainer
            data={[
              {
                label: " ",
                value: (
                  <Row>
                    <Col span={8}>
                      <Avatar
                        icon={<UserOutlined />}
                        size={100}
                        alt=""
                        src={selected.user_profile_image}
                      />
                    </Col>
                    <Col span={16}>
                      <Text type="label" style={{ fontSize: 11 }}>
                        Name
                      </Text>
                      <Text>{selected.first_name}</Text>
                      <br style={{ marginBottom: "1rem" }} />
                      <Text type="label" style={{ fontSize: 11 }}>
                        Joining Date
                      </Text>
                      <Text>{DateFormat(selected.created_at)}</Text>
                    </Col>
                  </Row>
                ),
                custom: true,
                fullWidth: true,
              },
              { label: "Username", value: selected.username },
              { label: "Mobile Number", value: selected.mobile },
              { label: "Email", value: selected.email },
              { label: "Type", value: selected.admin_type_name },
              { label: "Gender", value: selected.gender },
              { label: "Date of Birth", value: selected.dob },
              {
                label: "Bank Details",
                value: selected.bank_id ? (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => setBankDetails(selected)}
                  >
                    View Bank Details
                  </Button>
                ) : (
                  <Text>Bank details not added yet.</Text>
                ),
                custom: true,
                fullWidth: true,
              },
            ]}
          />
        </Sidebar>
      )}

      {adminType}
    </>
  );
}
