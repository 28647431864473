import {
  FullscreenOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import { Col, Row, Select, Skeleton, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import brandsMetrics from "../../assets/Brand-icon.svg";
import centreMetrics from "../../assets/centre-icon.svg";
import customerMetrics from "../../assets/customer-icon.svg";
import expertsMetrics from "../../assets/expert-icon.svg";
import Total from "../../assets/logo-icon.svg";
import PLUS from "../../assets/plusNew.svg";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi } from "../../helpers/ApiFunctions";
import Globe from "./Globe";
import MapChart from "./MapChart";
import "./index.css";

const Analytic = ({ write = 0 }) => {
  const [analytics, setAnalytics] = useState([]);
  const [analyticsDetail, setAnalyticsDetail] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingAnalyticsDetail, setLoadingAnalyticsDetail] = useState(false);
  const [total, setTotal] = useState(8);
  const [totalCounts, setTotalCounts] = useState([]);
  const [filtered, setFiltered] = useState("all");
  const theme = localStorage.getItem("theme");
  const [usersCount, setUsersCount] = useState({ Total: 0 });
  const [categories, setCategories] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [demographicData, setDemographicData] = useState([]);
  const [segment, setSegments] = useState([]);
  const [detailGlobe, setDetailGlobe] = useState(false);

  const [index, setIndex] = useState(0);

  const metrixIcons = [
    Total,

    brandsMetrics,
    centreMetrics,
    customerMetrics,
    expertsMetrics,
  ];

  //inserting icons along with userCounts
  Object.keys(usersCount).reduce((acc, key, i) => {
    acc[key] = [usersCount[key], metrixIcons[i]];
    return acc;
  }, {});

  const totalCountData = Object.keys(totalCounts)
    ?.filter(
      (data) =>
        data !== "profile_shared" &&
        data !== "people_invited" &&
        data !== "people_added" &&
        data !== "channels"
    )
    ?.map((data) => ({
      type: data.replace("_", " "),
      value:
        typeof totalCounts[data] === "number"
          ? totalCounts[data]
          : Object.keys(totalCounts[data]).reduce(
              (acc, curr) => acc + totalCounts[data][curr],
              0
            ),
    }));

  const getUsersCount = async (payload = {}) => {
    setLoading(true);
    const res = await getApi({ ...payload }, "getUserCounts", "dashboard/");

    if (res.success) {
      console.log("res", res);
      setUsersCount(res.data[0]?.metrics);
      setCategories(Object.keys(res.data[0]?.metrics));
    }
    setLoading(false);
  };

  const getUserDemographic = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getUserDemographics",
      "dashboard/"
    );

    if (res.success) {
      setDemographicData(res?.data);
    }
    setLoading(false);
  };
  useEffect(() => (getUsersCount(), getUserDemographic()), []);

  const columns = [
    Table.EXPAND_COLUMN,

    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      align: "center",
      render: (text, object) => <Text>{text}</Text>,
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      render: (text, object) => <Text>{text}</Text>,
    },
  ];

  const column = [
    {
      title: "Steps",
      dataIndex: "type",
      key: "type",
      align: "centre",
      render: (text, object) => (
        <p
          style={{
            margin: 0,
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          {text ? text : "0"}
        </p>
      ),
    },
    {
      title: "Count",
      dataIndex: "value",
      key: "type",
      align: "centre",
      render: (text, object) => (
        <p
          style={{
            margin: 0,
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          {text ? text : "0"}
        </p>
      ),
    },
  ];

  useEffect(() => {
    getAnalytics();
    getTotalCounts();
    const theme = localStorage.getItem("theme");
    setIsDarkMode(theme === "dark" ? true : false);
  }, []);

  const getAnalytics = async (payload = {}) => {
    setLoadingAnalytics(true);
    const res = await getApi({ ...payload }, "userAnalytics", "dashboard/");
    if (res.success) {
      setAnalytics(res.data);
      setTotal(res.meta.total);
    }
    setLoadingAnalytics(false);
  };

  const getTotalCounts = async (payload = {}) => {
    setLoadingSteps(true);
    const res = await getApi(
      { ...payload, analytics: 1 },
      "getUserCounts",
      "dashboard/"
    );

    if (res.success) {
      setTotalCounts(res.data[0]);
      // let val = Object.keys(res.data[0])?.filter((data) => data === "result");

      setSegments(
        Object.keys(res.data[0]?.result?.metrics)?.map((data, i) => ({
          type: data?.split("_").join(" "),
          value: res.data[0]?.result?.metrics[data],
        }))
      );
    }
    setLoadingSteps(false);
  };

  const dropdownOptions = [
    { label: "All", value: "all" },
    ...Object.keys(totalCounts)
      ?.filter(
        (data) =>
          data !== "profile_shared" &&
          data !== "people_invited" &&
          data !== "people_added" &&
          data !== "channels_created" &&
          data !== "result"
      )

      ?.map((data) => ({
        label: data.replace("_", " "),
        value: data,
      })),
  ];

  const darkMode = [
    "#FF6100",
    "#FF8B29",
    "#FFA559",
    "#FFC18C",
    "#FFD2AC",
    "#FFE6C7",
    "#FF8E49",
    "#EAB392",
    "#321300",
    "#D4BBAB",
    "#F0D6C6",
    // "#B94600",

    // Add more mappings for other data points
  ];
  const lightMode = [
    "#2B2B2B",
    "#FFDF00",
    "#E6C900",
    "#C8AF00",
    "#9D8900",
    "#893400",
    "#B94600",
    "#FF6100",
    "#FF8B29",
    "#FFC18C",
    "#FFD2AC",
    "#FFE6C7",
  ];

  const DemoPie = React.memo(() => {
    const data =
      filtered === "all"
        ? totalCountData
        : Object.keys(totalCounts)
            ?.filter(
              (data) =>
                data !== "profile_shared" &&
                data !== "people_invited" &&
                data !== "people_added"
            )
            ?.filter((data) => data === filtered)
            ?.map((data) =>
              typeof totalCounts[data] === "number"
                ? [
                    {
                      type: data.replace("_", " "),
                      value: totalCounts[data],
                    },
                  ]
                : Object.keys(totalCounts[data]).map((nestedData) => ({
                    type: nestedData.replace("_", " "),
                    value: totalCounts[data][nestedData],
                  }))
            )[0];

    const config = {
      data,
      appendPadding: 10,
      angleField: "value",
      colorField: "type",
      label: false,

      radius: 1,
      innerRadius: 0.6,

      interactions: [
        {
          type: "element-selected",
        },
        {
          type: "element-active",
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    };

    return (
      <Pie
        className="donut-chart"
        {...config}
        color={theme === "dark" ? darkMode : lightMode}
      />
    );
  });

  const detail = (obj, title, border) => {
    return (
      <div
        style={{
          margin: "2%",
        }}
      >
        <h3 style={{ color: "#0B0B0B" }}>{title}</h3>
        {typeof obj === "object"
          ? Object.keys(obj).map((data) => (
              <p
                style={{
                  margin: 0,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                <span
                  style={{
                    marginLeft: 10,
                    fontWeight: 200,
                  }}
                >
                  {obj[data]} -
                </span>{" "}
                {data.toUpperCase()}
              </p>
            ))
          : obj || "-"}
      </div>
    );
  };
  const handleChange = (e) => setFiltered(e);

  const handleClickLeft = useCallback(
    (e) => {
      if (index !== 0) {
        setIndex((prev) => prev - 1);
      }
    },
    [index, setIndex]
  );

  const handleExpand = async (expanded, record, e) => {
    setLoadingAnalyticsDetail(true);

    if (expanded) {
      // Row is being expanded
      setExpandedRowKeys([record.user_id]);

      // Make the API call asynchronously without blocking the UI
      getApi({ user_id: record?.user_id }, "userAnalytics", "dashboard/")
        .then((res) => {
          if (res.success) {
            setLoadingAnalyticsDetail(false);

            setAnalyticsDetail(res?.data[0]);
          } else {
            setLoadingAnalyticsDetail(false);
            setAnalyticsDetail({});
          }
        })
        .catch((error) => {
          // Handle API call errors if needed
          console.error("Error fetching data:", error);
          setAnalyticsDetail({});
        });
    } else {
      // Row is being collapsed
      setExpandedRowKeys([]);
      setAnalyticsDetail({});
    }
  };
  // With this change, the expanded row will open instantly when the user clicks the expand button, and the loading state will be managed independently by the loadingAnalytics and loadingAnalyticsDetail states. The UI won't be blocked while waiting for the API call to complete. The data will be populated in the expanded row once the API call is successful. If there's an error with the API call, the expanded row will display empty data.

  const handleClickRight = useCallback(
    (e) => {
      if (index !== Object.keys(usersCount).length - 1) {
        setIndex((prev) => prev + 1);
      }
    },
    [index, setIndex, usersCount]
  );

  const getDetailGlobe = (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 100000,
        width: "100vw",
        height: "100vh",
        background: "lightGray",
        overflow: "hidden",
      }}
    >
      <Globe data={demographicData} setDetailGlobe={setDetailGlobe} />
    </div>
  );

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <Row gutter={[16, 20]}>
            <Col span={24}>
              <CardContainer
                style={{
                  height: "48.5vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "2%",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      fontSize: 28,
                      fontStyle: "italic",
                      fontWeight: "700",
                      letterspacing: "-0.04em",
                    }}
                  >
                    KPI Data
                  </h2>
                  <Select
                    defaultValue="all"
                    style={{
                      width: "30%",
                    }}
                    onChange={handleChange}
                    options={dropdownOptions}
                  />
                </div>
                <DemoPie />
              </CardContainer>
            </Col>
            <Col span={24}>
              <CardContainer hoverable={false}>
                <TableContainer
                  title={{
                    title: "Segment Counts",
                    style: {
                      fontSize: 28,
                      fontStyle: "italic",
                      fontWeight: "700",
                      letterspacing: "-0.04em",
                    },
                  }}
                  TableSize={"large"}
                  loading={loadingSteps}
                  columns={write === 0 ? column : [...column]}
                  data={segment}
                  total={segment?.length}
                  filter="analytic"
                  functionsHandler={getAnalytics}
                />
              </CardContainer>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Col span={24} style={{ padding: 0, margin: 0 }}>
            <div
              style={{
                display: "flex",
                backgroundColor: "#2B2B2B",
                borderRadius: 6,
                marginBottom: 20,
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="total-container"
                style={{
                  width: "100%",

                  padding: "32px 0px 32px 20px",
                }}
              >
                <div>
                  <h2
                    className="title-italics"
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      fontSize: "28px",
                      margin: 0,
                      fontStyle: "italic",
                    }}
                  >
                    Total Users
                  </h2>
                  {loading ? (
                    <Skeleton.Button
                      style={{ width: "150px", height: "30px" }}
                      shape={"square"}
                    >
                      <h1
                        style={{
                          color: "rgb(255, 74, 1)",
                          fontSize: 100,
                          margin: 0,
                        }}
                      >
                        {Object.keys(usersCount).reduce(
                          (acc, curr) => acc + usersCount[curr],
                          0
                        )}
                      </h1>
                    </Skeleton.Button>
                  ) : (
                    <h1
                      style={{
                        color: "#FFDF00",
                        fontSize: 59,
                        fontWeight: 700,
                        margin: 0,
                      }}
                    >
                      {Object.keys(usersCount).reduce(
                        (acc, curr) => acc + usersCount[curr],
                        0
                      )}
                    </h1>
                  )}
                </div>
              </div>
              <img alt="plus-icon" src={PLUS} />
            </div>
          </Col>
          <Col span={24} style={{ padding: 0, margin: 0 }}>
            <div
              className="signup-tab"
              style={{
                display: "flex",
                borderRadius: 6,
                marginBottom: 20,
                position: "relative",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                className="total-container"
                style={{
                  width: "100%",

                  padding: "32px 0px 32px 20px",
                }}
              >
                <div>
                  <h2
                    className="heading-signup"
                    style={{
                      fontWeight: "700",
                      fontSize: "28px",
                      margin: 0,
                      color: "#0B0B0B",

                      fontStyle: "italic",
                      letterSpacing: "-0.04em",
                      textTransform: "capitalize",
                    }}
                  >
                    {categories[index]} Sign Ups
                  </h2>

                  {loading ? (
                    <Skeleton.Button
                      style={{ width: "150px", height: "30px" }}
                      shape={"square"}
                    >
                      <h1
                        style={{
                          color: "rgb(255, 74, 1)",
                          fontSize: 100,
                          margin: 0,
                        }}
                      >
                        {Object.keys(usersCount).reduce(
                          (acc, curr) => acc + usersCount[curr],
                          0
                        )}
                      </h1>
                    </Skeleton.Button>
                  ) : (
                    <h1
                      style={{
                        color: "#0B0B0B",
                        fontSize: 59,
                        fontWeight: 700,
                        margin: 0,
                      }}
                    >
                      {usersCount[categories[index]]}
                    </h1>
                  )}
                  <div
                    style={{ position: "absolute", top: "80%", left: "80%" }}
                  >
                    {
                      <LeftOutlined
                        style={{
                          marginRight: 20,
                          cursor: index === 0 && "not-allowed",

                          fontSize: 20,
                          color: isDarkMode
                            ? index === 0
                              ? "black"
                              : "gray"
                            : index === 0
                            ? "gray"
                            : "black",
                        }}
                        onClick={handleClickLeft}
                      />
                    }
                    {
                      <RightOutlined
                        style={{
                          cursor:
                            index === Object.keys(usersCount).length - 1 &&
                            "not-allowed",
                          color: isDarkMode
                            ? index === Object.keys(usersCount).length - 1
                              ? "black"
                              : "gray"
                            : index === Object.keys(usersCount).length - 1
                            ? "gray"
                            : "black",
                          fontSize: 20,
                        }}
                        onClick={handleClickRight}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <CardContainer hoverable={false}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: -1,
                }}
              >
                <h2
                  style={{
                    fontSize: 28,
                    fontStyle: "italic",
                    fontWeight: "700",
                    letterspacing: "-0.04em",
                  }}
                >
                  User Demographics{" "}
                </h2>
                <FullscreenOutlined
                  onClick={() => setDetailGlobe(true)}
                  style={{ fontSize: 20 }}
                />
              </div>
              {/* <Globe data={demographicData} setTooltipContent={setContent} /> */}
              <MapChart data={demographicData} />
            </CardContainer>
          </Col>

          {/* <CardContainer hoverable={false}>
            <TableContainer
              title={{
                title: "User details",
                icon: <UsergroupAddOutlined style={{ marginLeft: 10 }} />,
              }}
              rowKey="user_id"
              TableSize={"large"}
              loading={loadingAnalytics}
              className="detail-analytics"
              expandable={{
                expandedRowKeys,
                onExpand: handleExpand,
                expandedRowRender: (e) => {
                  return !loadingAnalyticsDetail ? (
                    <div
                      style={{
                        display: "flex",
                        margin: "2%",
                        flexWrap: "wrap",
                      }}
                    >
                      {Object.keys(analyticsDetail).map((data) =>
                        detail(
                          analyticsDetail[data],
                          data.replace("_", " ").toUpperCase()
                        )
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        margin: "2%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Skeleton
                        active={true}
                        paragraph={1}
                        style={{ width: "100%" }}
                      />
                    </div>
                  );
                },
              }}
              columns={write === 0 ? columns : [...columns]}
              data={analytics}
              total={total}
              filter="analytic"
              searchFor="Name, Mobile"
              functionsHandler={getAnalytics}
            />
        </CardContainer> */}
        </Col>
        {/* <Globe data={demographicData} setTooltipContent={setContent} /> */}
      </Row>
      {detailGlobe && getDetailGlobe}
    </>
  );
};

export default Analytic;
