import React from "react";
import { Col, Row } from "antd";

import Text from "../../components/Text";

export default function LabelValueContainer({ data = [], fullWidth = false }) {
  return (
    <Row>
      {data.map(
        (lv) =>
          lv?.label && (
            <Col
              className="lblc"
              xs={24}
              md={fullWidth || lv.fullWidth ? 24 : 12}
            >
              <Text type="label" style={{ fontSize: 11 }}>
                {lv.label}
              </Text>
              {lv.custom ? lv.value : <Text>{lv.value || "NA"}</Text>}
            </Col>
          )
      )}
    </Row>
  );
}
