import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";
import Tick from "../../assets/tickIcon.svg";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import Text from "../../components/Text";
import { getApi } from "../../helpers/ApiFunctions";
import "./index.css";

export default function ProfileStatus({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [profileStepList, setProfileStepList] = useState([]);
  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      align: "center",

      render: (text, object) => (
        <Text>
          {object?.last_name
            ? object?.first_name + " " + object?.last_name
            : object?.first_name}
        </Text>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",

      render: (t) => (t ? t : "-"),
    },

    {
      title: "Status",
      dataIndex: "profile_percentage",

      key: "profile_percentage",
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "10px",
            alignItems: "center",
          }}
        >
          {text === 100 ? (
            <div
              className="profile-step-container"
              style={{ background: "#DAFFDA" }}
            >
              <div className="profile-step-pill" style={{ color: "#08A908" }}>
                Completed
              </div>
            </div>
          ) : (
            <div
              className="profile-step-container"
              style={{ background: "#FFE2E2" }}
            >
              <div className="profile-step-pill" style={{ color: "#FF0000" }}>
                Incompleted
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Steps completed",
      dataIndex: "profile_percentage",
      align: "center",
      key: "profile_percentage",
      render: (text, object) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            className={
              object?.step_1?.is_complete === 1
                ? "steps-progress-bar-active"
                : "steps-progress-bar-inactive"
            }
          >
            <Tooltip title="Step 1: Complete profile details">
              <img alt="tick-icon" src={Tick} />
            </Tooltip>
          </div>
          <div
            className={
              object?.step_2?.is_complete === 1
                ? "steps-progress-bar-active"
                : "steps-progress-bar-inactive"
            }
          >
            <Tooltip title="Step 2: Invite more experts">
              <img alt="tick-icon" src={Tick} />
            </Tooltip>
          </div>
          <div
            className={
              object?.step_3?.is_complete === 1
                ? "steps-progress-bar-active"
                : "steps-progress-bar-inactive"
            }
          >
            <Tooltip title="Step 3: Share profile link">
              <img alt="tick-icon" src={Tick} />
            </Tooltip>
          </div>
          <div
            className={
              object?.step_4?.is_complete === 1
                ? "steps-progress-bar-active"
                : "steps-progress-bar-inactive"
            }
          >
            <Tooltip title="Step 4: Get verification badge">
              <img alt="tick-icon" src={Tick} />
            </Tooltip>
          </div>
          <div
            className={
              object?.step_5?.is_complete === 1
                ? "steps-progress-bar-active"
                : "steps-progress-bar-inactive"
            }
          >
            <Tooltip title="Step 5: Claim your bussiness card">
              <img alt="tick-icon" src={Tick} />
            </Tooltip>
          </div>
          <div
            style={{
              display: "contents",
              marginLeft: "10px",
              paddingLeft: "10px",
            }}
          >
            {text + "%"}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => getProfileStatusList(), []);

  const getProfileStatusList = async (payload = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...payload },
      "getallUserProfileSteps",
      "dashboard/"
    );
    if (res.data) {
      setProfileStepList(res.data);
      setTotal(res.meta?.total);
    }
    setLoading(false);
  };

  return (
    <>
      <CardContainer hoverable={false}>
        <BreadCrumb title="Profile Status" />

        <TableContainer
          loading={loading}
          columns={write === 0 ? columns : [...columns]}
          data={profileStepList}
          total={total}
          searchFor="Name, Mobile"
          functionsHandler={getProfileStatusList}
        />
      </CardContainer>
    </>
  );
}
