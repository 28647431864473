import React, { createContext, useEffect, useState } from "react";

const StoreContext = createContext();

export const storeData = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value));
};

export const fetchData = (type) => {
  if (localStorage.getItem(type)) return JSON.parse(localStorage.getItem(type));
  return null;
};

const StoreContextProvider = ({ children }) => {
  const [user, setUser] = useState(fetchData("user_data") ?? {});
  const [storeId, setStoreId] = useState(fetchData("company_id") ?? 0);
  const [title, setTitle] = useState("Home");

  useEffect(() => storeData("user_data", user), [user]);
  useEffect(() => storeData("company_id", storeId), [storeId]);

  return (
    <StoreContext.Provider
      value={{ storeId, setStoreId, user, setUser, title, setTitle }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreContextProvider };
