import { Card } from "antd";
import React from "react";

import Text from "../Text";
import "./index.css";

export default function CardContainer({
  title = "",
  loading = false,
  children,
  ...props
}) {
  return (
    <Card
      className="crd"
      title={title && <Text type="title">{title}</Text>}
      loading={loading}
      bordered
      hoverable
      {...props}
    >
      {children}
    </Card>
  );
}
