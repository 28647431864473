import { Button } from "antd";
import React, { useEffect, useState } from "react";

import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import TableContainer from "../../components/Table";
import { getApi } from "../../helpers/ApiFunctions";

import { PlaySquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Popup from "../../components/Popup";
import "./index.css";

const allBookingColumns = [
  {
    title: "Service name",
    dataIndex: "title",
    key: "title",
    align: "center",

    render: (text) =>
      text.length > 15 ? (
        <span>{text.slice(0, 15) + "..."}</span>
      ) : (
        <span>{text}</span>
      ),
  },
  {
    title: "Created by",
    dataIndex: "first_name",
    key: "first_name",
    render: (t) => (t ? t : "-"),
  },
  {
    title: "Category",
    align: "center",
    dataIndex: "name",
    key: "name",
    render: (t) =>
      t ? (
        <div className="category-container" style={{ alignItems: "center" }}>
          <div className="category-data">{t}</div>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Session Type",
    dataIndex: "session_type",
    align: "center",
    key: "session_type",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (t) => (t ? t : "-"),
  },
  {
    title: "Level",
    dataIndex: "level",
    align: "center",
    key: "level",
    render: (t) => (t ? t : "-"),
  },
  {
    title: "Amount",
    dataIndex: "rate",
    align: "center",
    key: "rate",
    sorter: true,
    render: (text, object) => (text ? `₹ ` + text : "-"),
  },
  {
    title: "Created on",
    dataIndex: "created_at",
    key: "created_at",
    align: "center",
    sorter: true,
    render: (text) => dayjs(text).format("DD MMM, YYYY h:mm A"),
  },
];

const pre_record_table_columns = [
  {
    title: "Video Title",
    dataIndex: "service_video_title",
    key: "service_video_title",
    align: "center",
  },
  {
    title: "Vedio URL",
    dataIndex: "url",
    key: "url",
    align: "center",
  },
  {
    title: "Created At",
    dataIndex: "video_created_at",
    key: "video_created_at",
    align: "center",
  },
];

export default function Business({ write = 0 }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(8);
  const [list, setList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [selected, setSelected] = useState(0);
  const [popup, setPopup] = useState(false);
  const [preRecordData, setPreRecordData] = useState([]);

  useEffect(() => getServicesList(), []);

  const getServicesList = async (payload = {}) => {
    setLoading(true);

    const res = await getApi({ ...payload }, "getAllServices", "dashboard/");

    if (res.data) {
      setList(res.data);
      setTotal(res.meta?.total);
      setLoading(false);
    } else setLoading(false);
  };

  const servicesCreated = (
    <>
      <CardContainer bordered={false} hoverable={false}>
        <BreadCrumb title="Services Created" />
        <TableContainer
          loading={loading}
          total={total}
          columns={[...allBookingColumns]}
          data={list}
          dateRangePicker
          addHandler={() => setPopup(true)}
          searchFor="Service name, Created by"
          parseButton="export"
          filter="serviceList"
          functionsHandler={getServicesList}
          rowClickHandler={(record) => {
            setSidebar(true);
            setSelected(record);
          }}
          rowKeyName="booking_id"
        />
      </CardContainer>
    </>
  );

  return (
    <>
      {popup && !sidebar && (
        <Popup
          title="Pre Recorded URL"
          cancelHandler={() => {
            setPopup(false);
            setSidebar(false);
            setPreRecordData([]);
          }}
          saveHandler={() => {
            setPopup(false);
            setSidebar(false);
            setPreRecordData();
          }}
          saveText="Okay"
          cancelText="Close"
          loading={preRecordData.loading}
          visible
        >
          <TableContainer
            loading={loading}
            columns={
              write === 0
                ? pre_record_table_columns
                : [
                    ...pre_record_table_columns,
                    {
                      title: "Certificate",
                      dataIndex: "url",
                      key: "url",
                      align: "center",
                      render: (text) => (
                        <>
                          {text.includes(".mp4") && (
                            <Button>
                              <PlaySquareOutlined /> Play
                            </Button>
                          )}
                        </>
                      ),
                    },
                  ]
            }
            data={selected}
            searchFor=""
            dateRangePicker={false}
            exportButton={false}
            functionsHandler={getServicesList}
          />
        </Popup>
      )}

      {servicesCreated}
    </>
  );
}
