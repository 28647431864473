import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Select, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";

import PLACEHOLDER from "../../assets/placeholder-logo.svg";
import BreadCrumb from "../../components/Breadcrumb";
import CardContainer from "../../components/Card";
import Popup from "../../components/Popup";
import Text from "../../components/Text";
import {
  errorMessage,
  formDataApi,
  getApi,
  successMessage,
} from "../../helpers/ApiFunctions";
import { preloadImage } from "../../helpers/CommonFunctions";
import { StoreContext } from "../../helpers/StoreContext";
import CompanyForm from "./CompanyForm";

const subjects = [
  { label: "Coding", value: 1 },
  { label: "Maths", value: 2 },
];

export default function Company() {
  const { storeId: companyId } = React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState({});
  const [editEnabled, setEditEnabled] = useState(false);
  const [subjectOptions, setSubjectOptions] = useState(subjects);

  useEffect(() => {
    getSubjectOptions();
    companyId ? getData() : getList();
  });

  const getList = async () => {
    setLoading(true);
    const res = await getApi({ role_id: 1, size: 100 }, "getcompanyList");
    if (res.data && res.data.length) {
      if (selected.company_id) {
        const selectedCompany =
          res.data.find?.(
            (company) =>
              parseInt(company.company_id) === parseInt(selected.company_id)
          ) || {};
        if (selectedCompany.company_id)
          setSelected({
            ...selectedCompany,
            session_types: selectedCompany?.session_types?.split?.(", "),
          });
        else
          setSelected({
            ...res.data[0],
            session_types: res.data[0].session_types?.split?.(", "),
          });
      } else
        setSelected({
          ...res.data[0],
          session_types: res.data[0].session_types?.split?.(", "),
        });
      setList(res.data);
      res.data.forEach((company) => preloadImage(company.logo));
    } else errorMessage(res.msg);
    setLoading(false);
  };

  const getData = async (company_id = companyId) => {
    setLoading(true);
    const res = await getApi({ company_id }, "getcompanyById");
    if (res.data)
      setSelected({
        ...res.data,
        session_types: res.data.session_types?.split?.(", "),
      });
    else errorMessage(res.msg);
    setLoading(false);
  };

  const saveData = async (values = {}) => {
    setLoading(true);
    const res = await getApi(
      { ...values, session_types: values.session_types.join(", ") },
      selected.company_id ? "editCompanyProfile" : "registerUser"
    );
    if (res?.success) {
      if (!selected.company_id) setSelected(res.data);
      if (values.imageFile) {
        const res2 = await formDataApi([
          { label: "dopamine", value: values.imageFile },
          {
            label: "company_id",
            value: selected.company_id || res.data?.company_id,
          },
        ]);
        if (res2) {
          successMessage(
            "Company Profile " + (selected.company_id ? "Updated" : "Created")
          );
        } else {
          setLoading(false);
          errorMessage("Error while uploading logo");
        }
        getList();
      } else {
        successMessage(
          "Company Profile " + (selected.company_id ? "Updated" : "Created")
        );
        getList();
      }
    } else setLoading(false);
    setEditEnabled(false);
  };

  const deactivateCompany = async () => {
    setSelected({ ...selected, loading: true });
    const res = await getApi(
      { user_id: selected.user_id, is_active: selected.is_active > 0 ? 0 : 1 },
      "deactivateUser",
      "dopamine/"
    );
    if (res) {
      getList();
      successMessage(
        "Company " + (selected.is_active > 0 ? "Deactivated" : "Activated")
      );
    }
    setSelected({ ...selected, changed: false });
  };

  const getSubjectOptions = async () => {
    const res = await getApi(
      { role_id: 1 },
      "getSubjectsCovered",
      "dashboard/"
    );
    if (res?.data) {
      const options = res.data.map((subject) => ({
        label: subject.subject_name,
        value: subject.subject_id,
        key: subject.subject_id,
      }));
      setSubjectOptions(options);
    }
  };

  return (
    <CardContainer hoverable={false}>
      <BreadCrumb title="Company Management" />
      {selected.changed && (
        <Popup
          visible
          loading={selected.loading}
          title="Update Active Status"
          cancelHandler={() => setSelected({ ...selected, changed: false })}
          saveHandler={deactivateCompany}
          saveText={selected.is_active ? "Yes, Deactivate" : "Yes, Activate"}
        >
          <Text bold style={{ fontSize: 18, textTransform: "capitalize" }}>
            Are you sure to {selected.is_active > 0 ? "Deactivate" : "Activate"}{" "}
            the company {selected.name}?
          </Text>
        </Popup>
      )}
      {!companyId && (
        <Row style={{ display: "flex", justifyContent: "flex-end" }} span={24}>
          <Select
            placeholder="Select Company"
            style={{ width: 225, marginTop: 10 }}
            options={list.map((item, index) => ({
              label: item.name,
              value: item.company_id,
              key: item.company_id,
              index,
            }))}
            value={selected.company_id}
            onSelect={(_, vObj) =>
              setSelected({
                ...list[vObj.index],
                session_types: list[vObj.index].session_types?.split?.(", "),
              })
            }
            disabled={editEnabled}
          />
          <Button
            style={{ marginLeft: 20, marginTop: 10 }}
            onClick={() => {
              setSelected({});
              setEditEnabled(true);
            }}
            type="primary"
            loading={loading}
            icon={<PlusOutlined />}
            disabled={editEnabled}
          >
            Add
          </Button>
        </Row>
      )}
      {editEnabled ? (
        <CompanyForm
          list={list}
          loading={loading}
          saveData={saveData}
          selected={selected}
          setSelected={setSelected}
          editEnabled={editEnabled}
          setEditEnabled={setEditEnabled}
          subjectOptions={subjectOptions}
        />
      ) : (
        <Row style={{ width: "100%", marginTop: "2rem" }}>
          <Col span={14}>
            <Text level={4} type="title">
              Basic Details
            </Text>
          </Col>
          <Col style={{ textAlign: "right" }} span={10}>
            {!companyId && (
              <Switch
                style={{
                  marginRight: window.innerWidth > 768 ? 15 : 0,
                  marginBottom: window.innerWidth > 768 ? 0 : 15,
                }}
                loading={loading}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={selected.is_active === 1}
                onChange={() => setSelected({ ...selected, changed: true })}
              />
            )}
            <Button
              size="small"
              onClick={() => setEditEnabled(true)}
              type="primary"
              loading={loading}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Col>
          <Col style={{ marginTop: "1rem" }} span={24}>
            <Spin className="loadr" spinning={loading}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8} lg={4}>
                  <Text type="label">Logo</Text>
                  <div
                    style={{
                      boxShadow: "1px 1px 1px 1px hsl(199deg 76% 90% / 32%)",
                      borderRadius: 8,
                      border: "1px solid #4a217347",
                      overflow: "hidden",
                      width: 100,
                      height: 100,
                    }}
                  >
                    <Image
                      style={{
                        objectFit: "contain",
                        height: 100,
                        width: 100,
                      }}
                      src={selected.logo || PLACEHOLDER}
                      alt="logo"
                    />
                  </div>
                </Col>
                <Col xs={24} md={16} lg={20}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Name</Text>
                      <Text bold>{selected.name || "-"}</Text>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Username</Text>
                      <Text bold>{selected.username || "-"}</Text>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Email</Text>
                      <Text bold>{selected.email || "-"}</Text>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Mobile</Text>
                      <Text bold>{selected.mobile || "-"}</Text>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Text type="label">Subjects Covered</Text>
                      <Text bold>
                        {selected.subjects?.map((s, i) =>
                          i === 0 ? s?.subject_name : " , " + s?.subject_name
                        ) || "-"}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Col>
          <Col style={{ marginTop: "2rem" }} span={24}>
            <Text style={{ marginBottom: "1rem" }} level={4} type="title">
              More Details
            </Text>
            <Spin className="loadr" spinning={loading}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Session Types</Text>
                  <Text bold>
                    {selected.session_types?.length > 1
                      ? selected.session_types?.map?.((c, i) =>
                          i === 0 ? c : ` , ${c}`
                        )
                      : selected.session_types
                      ? selected.session_types?.[0] || "-"
                      : "-"}
                  </Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Countries</Text>
                  <Text bold>
                    {selected.countries?.map((c, i) =>
                      i === 0 ? c : ` , ${c}`
                    ) || "-"}
                  </Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Headquarter</Text>
                  <Text bold>{selected.headquarter || "-"}</Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Founder</Text>
                  <Text bold>{selected.founder || "-"}</Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Founded In</Text>
                  <Text bold>{selected.founded_in || "-"}</Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Student Count</Text>
                  <Text bold>{selected.student_count || "-"}</Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Website Url</Text>
                  <Text bold>{selected.website_url || "-"}</Text>
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Text type="label">Funding</Text>
                  <Text bold>{selected.funding || "-"}</Text>
                </Col>
                <Col xs={24}>
                  <Text type="label">USP</Text>
                  <Text bold>{selected.usp || "-"}</Text>
                </Col>
                <Col xs={24}>
                  <Text type="label">About</Text>
                  <Text bold>{selected.about_us || "-"}</Text>
                </Col>
                <Col xs={24}>
                  <Text type="label">Offerings</Text>
                  <Text bold>{selected.offerings || "-"}</Text>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      )}
    </CardContainer>
  );
}
